const FaultColumn = [
  {
    id: "lightName",
    label: "Sensor ID",
  },
  {
    id: "faultType",
    label: "Fault Type",
  },
  {
    id: "sensorStatus",
    label: "Sensor Status",
  },
  {
    id: "severity",
    label: "Severity",
  },
  {
    id: "reportDateTime",
    label: "Date & Time",
    isHeader: true
  },
  {
    id: "clearFaultStatus",
    label: "Resolved",
  },
];

export default FaultColumn;