import { axiosInstance } from "services/api/axios";
import {
  SET_NOTIFICATION,
  UPDATE_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from "../notification/notificationSlice";

export const initNotification = (userId) => {
  return async (dispatch) => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/eventlog/userFault/user/`
      )
      .then((response) => {
        dispatch(SET_NOTIFICATION(response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateNotification = (faultId) => {
  return async (dispatch) => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/eventlog/userFault/faultRead/${faultId}`
      )
      .then((response) => {
        if (response?.status === 200) dispatch(UPDATE_NOTIFICATION(faultId));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const clearNotification = () => {
  return async (dispatch) => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/eventlog/userFault/markRead`
      )
      .then((response) => {
        if (response?.status === 200) dispatch(CLEAR_NOTIFICATION());
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
