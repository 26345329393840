import React from "react";
import { Button } from "@mui/material";
import Breadcrumb from "./Breadcrumb";

const Header = (props) => {
  return (
    <React.Fragment>
      <div section={props.className} className={props.className}>
        <div className="d-flex flex-row justify-content-between">
          <div className="map-title body-title" data-testid="test-page-title">
            {props.title}
          </div>
          {props.button &&
            (props.role === "ROLE_ADMIN" ||
              props.role === "ROLE_AREA_ADMIN") && (
              <div className="d-flex flex-row gap-3">
                <Button
                  className="bg-dark"
                  variant="contained"
                  size="small"
                  onClick={() =>
                    props.modalDispatch({
                      type: "WISUN_LIGHT_UPGRADE",
                      payload: true,
                    })
                  }
                >
                  <strong>WISUN OTA Upgrade</strong>
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={() =>
                    props.modalDispatch({
                      type: "MSP_LIGHT_UPGRADE",
                      payload: true,
                    })
                  }
                >
                  <strong>MSP OTA Upgrade</strong>
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() =>
                    props.modalDispatch({
                      type: "DELETE_LIGHT_MODAL",
                      payload: true,
                    })
                  }
                >
                  <strong>DELETE</strong>
                </Button>
              </div>
            )}
        </div>
        <Breadcrumb data={props.path} />

        {props.children}
      </div>
    </React.Fragment>
  );
};

Header.defaultProps = {
  button: false,
};

export default Header;
