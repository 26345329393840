import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function InputSlider(props) {
  const handleBlur = () => {
    let currentValue = props.value;
    if (props.value < props.min) {
      currentValue = props.min;
    } else if (props.value > props.max) {
      currentValue = props.max;
    } 
    if (props.onInputChange) {
      props.onInputChange({ target: { value : currentValue } });
    }
  };

  const handleNumericInput = (event) => {
    const regex = /^[0-9]*$/;
    if (!regex.test(event.target.value)) {
      event.preventDefault();
      return;
    }
    if (props.onInputChange) {
      props.onInputChange(event);
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs>
        <Slider
          min={props.min}
          max={props.max}
          step={props.step}
          sx={{color:"#575757"}}
          value={typeof props.value === "number" ? props.value : 0}
          onChange={props.onSliderChange}
          aria-labelledby="input-slider"
        />
      </Grid>
      <Grid item>
        <Input
          value={props.value}
          size="small"
          onChange={handleNumericInput}
          onBlur={handleBlur}
          inputProps={{
            min: props.min,
            max: props.max,
            type: "tel",
            "aria-labelledby": "input-slider",
          }}
        />
      </Grid>
    </Grid>
  );
}


InputSlider.defaultProps = {
  step: 1,
}