import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { Divider } from "rsuite";
import { QuestionCircle } from "react-bootstrap-icons";
import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./FormValidation";
import useAxiosInstance from "hook/useAxiosInstance";

const LightForm = (props) => {
  const axiosInstance = useAxiosInstance();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      lightId: null,
      userId: props.userId,
      sensorId: "SmartLighting-Aztech-",
      gatewayId: "",
      blockId: "",
      floorId: "",
      displayName: "",
      lightSN: "",
      lightMACAddress: "",
      lightIPAddress: "",
      lightingControl: "",
      failSafeDelay: "",
      holdTime: "",
      rampUpTime: "",
      rampDownTime: "",
      dimIntensity: "",
      brightIntensity: "",
      photoSensing: "",
      photoUpperThreshold: "",
      photoLowerThreshold: "",
      motionSensitivity: "",
      installDate: "",
    },
  });

  const [userBlockList, setUserBlockList] = useState([]);
  const [userFloorList, setUserFloorList] = useState([]);
  const [userGatewayList, setUserGatewayList] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();

    axiosInstance
      .get(`${process.env.REACT_APP_API_ADMIN}/api/admin/block/blockAccess`, {
        signal: abortController.signal,
      })
      .then((response) => {
        setUserBlockList(response.data);
        return axiosInstance.get(
          `${process.env.REACT_APP_API_ADMIN}/api/admin/floor/block/${response.data[0].blockId}`,
          { signal: abortController.signal }
        );
      })
      .then((response) => {
        setUserFloorList(response.data);
        return axiosInstance.get(
          `${process.env.REACT_APP_API_ADMIN}/api/admin/gateway/block/${response.data[0].blockId}`,
          { signal: abortController.signal }
        );
      })
      .then((response) => {
        setUserGatewayList(response.data);
      })
      .catch(function(err) {
        toast.error("Failed retrieving gateway data.");
        setUserGatewayList([]);
      });

    return () => {
      abortController.abort();
    };
  }, []);

  const blockChangeHandler = async (e) => {
    setValue("blockId", e.target.value, {
      shouldValidate: true,
    });

    const floorResponse = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ADMIN}/api/admin/floor/block/${e.target.value}`
      )
      .catch((err) => {
        toast.error("Failed retrieving floor(s).");
        setUserFloorList([]);
      });

    if (floorResponse !== null || floorResponse !== undefined) {
      if (floorResponse.status === 200) setUserFloorList(floorResponse.data);
    }

    const gatewayResponse = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ADMIN}/api/admin/gateway/block/${e.target.value}`
      )
      .catch((err) => {
        toast.error("Failed retrieving gateway(s).");
        setUserGatewayList([]);
      });

    if (gatewayResponse !== null && gatewayResponse !== undefined) {
      if (gatewayResponse.status === 200)
        setUserGatewayList(gatewayResponse.data);
    }
  };

  const fillDefaultValueHandler = () => {
    reset({
      sensorId:
        getValues("sensorId") === ""
          ? "SmartLighting-Aztech-"
          : getValues("sensorId"),
      gatewayId: getValues("gatewayId"),
      blockId: getValues("blockId"),
      floorId: getValues("floorId"),
      displayName: getValues("displayName"),
      lightSN: getValues("lightSN"),
      lightMACAddress: getValues("lightMACAddress"),
      lightIPAddress: getValues("lightIPAddress"),
      lightingControl: "Auto",
      failSafeDelay: 0,
      holdTime: 15,
      rampUpTime: 1,
      rampDownTime: 1,
      photoSensing: "Disable",
      photoUpperThreshold: 400,
      photoLowerThreshold: 200,
      installDate: currentDateTimeString(),
      motionSensitivity: "3",
      dimIntensity: 20,
      brightIntensity: 80,
    });
  };

  const currentDateTimeString = () => {
    let now = new Date();
    return (
      now.getFullYear() +
      "-" +
      (now.getMonth() + 1 < 10
        ? "0" + (now.getMonth() + 1)
        : now.getMonth() + 1) +
      "-" +
      (now.getDate() < 10 ? "0" + now.getDate() : now.getDate())
    );
  };

  return (
    <Form
      id="lightForm"
      className="form-margin-width"
      onSubmit={handleSubmit(props.onSubmit)}
    >
      <Row className="mb-2">
        <h6 style={{ textDecoration: "underline" }}>Properties</h6>
        <br />
        <br />

        <Form.Group
          as={Col}
          controlId="formGridSensorId"
          className="form-input-width"
        >
          <Form.Label>Sensor ID</Form.Label>
          <Controller
            control={control}
            name="sensorId"
            placeholder="Enter Sensor ID"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                disabled
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.sensorId}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.sensorId?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridLightSN">
          <Form.Label>Light SN</Form.Label>
          <Controller
            control={control}
            name="lightSN"
            placeholder="Enter Light Serial No."
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.lightSN}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.lightSN?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridDisplayName">
          <Form.Label>Display Name</Form.Label>
          <Controller
            control={control}
            name="displayName"
            placeholder="Enter Light Display Name"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.displayName}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.displayName?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-2">
        <Form.Group as={Col} controlId="formGridLightIPAddress">
          <Form.Label>Light IP Address</Form.Label>
          <Controller
            control={control}
            name="lightIPAddress"
            placeholder="Enter Light IP Address"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.lightIPAddress}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.lightIPAddress?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridMacAddress">
          <Form.Label>Light MAC Address</Form.Label>
          <Controller
            control={control}
            name="lightMACAddress"
            placeholder="Enter Light MAC Address"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onFocus={() => {
                  setValue("sensorId", "SmartLighting-Aztech-");
                }}
                onBlur={(e) => {
                  if (
                    e.target.value !== "" &&
                    e.target.value !== null &&
                    getValues("gatewayId") !== "" &&
                    getValues("gatewayId") !== null
                  ) {
                    setValue(
                      "sensorId",
                      getValues("sensorId") +
                        value +
                        "-" +
                        document.getElementById("formGridGatewayId")
                          .selectedOptions[0].innerHTML
                    );
                  } else if (e.target.value !== "" && e.target.value !== null) {
                    setValue("sensorId", getValues("sensorId") + value);
                  } else {
                    setValue("sensorId", "SmartLighting-Aztech-");
                  }
                }}
                onChange={onChange}
                isInvalid={errors.lightMACAddress}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.lightMACAddress?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Col></Col>
      </Row>

      <Row className="mb-2">
        <Form.Group as={Col} controlId="formGridBlockId">
          <Form.Label>Block Id</Form.Label>
          <Controller
            control={control}
            name="blockId"
            placeholder="Select Block"
            defaultValue=""
            render={({ field: { value, name } }) => (
              <Form.Select
                name={name}
                className="form-input-width"
                value={value ? value : ""}
                onChange={blockChangeHandler}
                isInvalid={errors.blockId}
              >
                <option value="">-Select Block-</option>
                {userBlockList.map((block) => {
                  return (
                    <option
                      key={block.blockId}
                      id={block.blockId}
                      value={block.blockId}
                    >
                      {block.blockName}
                    </option>
                  );
                })}
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.blockId?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridFloorId">
          <Form.Label>Floor Id</Form.Label>
          <Controller
            control={control}
            name="floorId"
            placeholder="Select Floor"
            defaultValue=""
            render={({ field: { value, name } }) => (
              <Form.Select
                name={name}
                className="form-input-width"
                value={value ? value : ""}
                onChange={(e) => {
                  setValue("floorId", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                isInvalid={errors.floorId}
              >
                <option value="">-Select Floor-</option>
                {userFloorList.map((floor) => {
                  return (
                    <option
                      key={floor.floorId}
                      id={floor.floorId}
                      value={floor.floorId}
                    >
                      {floor.floorName}
                    </option>
                  );
                })}
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.floorId?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridGatewayId">
          <Form.Label>Gateway Id</Form.Label>
          <Controller
            control={control}
            name="gatewayId"
            placeholder="Select Gateway"
            defaultValue=""
            render={({ field: { value, name } }) => (
              <Form.Select
                name={name}
                className="form-input-width"
                value={value ? value : ""}
                onChange={(e) => {
                  setValue("gatewayId", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                onFocus={() => {
                  setValue("sensorId", "SmartLighting-Aztech-");
                }}
                onBlur={(e) => {
                  if (
                    document.getElementById("formGridMacAddress").value !==
                      null &&
                    document.getElementById("formGridMacAddress").value !==
                      "" &&
                    e.target.value !== null &&
                    e.target.value !== ""
                  )
                    setValue(
                      "sensorId",
                      getValues("sensorId") +
                        document.getElementById("formGridMacAddress").value +
                        "-" +
                        e.target.selectedOptions[0].innerHTML
                    );
                }}
                isInvalid={errors.gatewayId}
              >
                <option value="">-Select Gateway-</option>
                {userGatewayList.map((gateway) => {
                  return (
                    <option
                      key={gateway.gatewayId}
                      id={gateway.gatewayId}
                      value={gateway.gatewayId}
                    >
                      {gateway.gatewayMACAddress}
                    </option>
                  );
                })}
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.gatewayId?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Divider />

      <Row className="mb-2">
        <div className="d-flex flex-row">
          <h6 style={{ textDecoration: "underline" }}>
            Configuration
            <Tooltip
              className="custom-tooltip mx-2"
              title="Smartlight configuration can only be changed under 'Configuration' tab."
              placement="right"
              style={{ justifyContent: "center" }}
            >
              <QuestionCircle />
            </Tooltip>
          </h6>
        </div>
        <br />
        <br />
        <Form.Group as={Col} controlId="formGridBlockId">
          <Form.Label>Lighting Control</Form.Label>
          <Controller
            control={control}
            name="lightingControl"
            placeholder="Enter Light Control"
            render={({ field: { name, value } }) => (
              <Form.Select
                name={name}
                className="form-input-width"
                value={value ? value : ""}
                onChange={(e) => {
                  setValue("lightingControl", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                isInvalid={errors.lightingControl}
              >
                <option value="On">On</option>
                <option value="Off">Off</option>
                <option value="Auto">Auto</option>
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.lightingControl?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridFailSafeDelay">
          <Form.Label>Fail Safe Delay (s)</Form.Label>
          <Controller
            control={control}
            name="failSafeDelay"
            placeholder="Enter Fail Safe Delay"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.failSafeDelay}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.failSafeDelay?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridRampUpTime">
          <Form.Label>Ramp Up Time (s)</Form.Label>
          <Controller
            control={control}
            name="rampUpTime"
            placeholder="Enter Ramp Up Time"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.rampUpTime}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.rampUpTime?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridRampDownTime">
          <Form.Label>Ramp Down Time (s)</Form.Label>
          <Controller
            control={control}
            name="rampDownTime"
            placeholder="Enter Ramp Down Time"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.rampDownTime}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.rampDownTime?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} controlId="formGridBlockId">
          <Form.Label>Photo Sensing</Form.Label>
          <Controller
            control={control}
            name="photoSensing"
            placeholder="Enter Photo Sensing"
            render={({ field: { name, value } }) => (
              <Form.Select
                name={name}
                className="form-input-width"
                value={value ? value : ""}
                onChange={(e) => {
                  setValue("photoSensing", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                isInvalid={errors.photoSensing}
              >
                <option value="Enable">Enable</option>
                <option value="Disable">Disable</option>
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.photoSensing?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridBlockId">
          <Form.Label>Photo Upper Threshold (lux)</Form.Label>
          <Controller
            control={control}
            name="photoUpperThreshold"
            placeholder="Enter Photo Upper Threshold"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.photoUpperThreshold}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.photoUpperThreshold?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridBlockId">
          <Form.Label>Photo Lower Threshold (lux)</Form.Label>
          <Controller
            control={control}
            name="photoLowerThreshold"
            placeholder="Enter Photo Lower Threshold"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.photoLowerThreshold}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.photoLowerThreshold?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridInstallDate">
          <Form.Label>Install Date</Form.Label>
          <Controller
            control={control}
            name="installDate"
            placeholder="Enter Install Date"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="date"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.installDate}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.installDate?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} controlId="formGridMotionSensitivity">
          <Form.Label>Motion Sensitivity</Form.Label>
          <Controller
            control={control}
            name="motionSensitivity"
            placeholder="Enter Motion Sensitivity"
            render={({ field: { name, value } }) => (
              <Form.Select
                name={name}
                className="form-input-width"
                value={value ? value : ""}
                onChange={(e) => {
                  setValue("motionSensitivity", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                isInvalid={errors.motionSensitivity}
              >
                <option value="1">High</option>
                <option value="2">Medium-High</option>
                <option value="3">Medium</option>
                <option value="4">Medium-Low</option>
                <option value="5">Low</option>
              </Form.Select>
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.motionSensitivity?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridBlockId">
          <Form.Label>Hold Time (s)</Form.Label>
          <Controller
            control={control}
            name="holdTime"
            placeholder="Enter Hold Time"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.holdTime}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.holdTime?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridDimIntensity">
          <Form.Label>Dim Intensity (%)</Form.Label>
          <Controller
            control={control}
            name="dimIntensity"
            placeholder="Enter Dim Intensity"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.dimIntensity}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.dimIntensity?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridBrightIntensity">
          <Form.Label>Bright Intensity (%)</Form.Label>
          <Controller
            control={control}
            name="brightIntensity"
            placeholder="Enter Bright Intensity"
            render={({ field: { onChange, onBlur, value } }) => (
              <Form.Control
                type="text"
                className="form-input-width"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={errors.brightIntensity}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {errors.brightIntensity?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <div
        className="d-flex flex-row justify-content-between"
        style={{ marginTop: "3rem" }}
      >
        <div>
          <Button
            className="mx-2"
            variant="dark"
            type="button"
            onClick={fillDefaultValueHandler}
          >
            <span>Default</span>
          </Button>
          <Button
            className="mx-2"
            variant="light"
            onClick={() =>
              reset({
                lightId: null,
                userId: props.userId,
                sensorId: "",
                gatewayId: "",
                blockId: "",
                floorId: "",
                displayName: "",
                lightSN: "",
                lightMACAddress: "",
                lightIPAddress: "",
                lightingControl: "",
                failSafeDelay: "",
                holdTime: "",
                rampUpTime: "",
                rampDownTime: "",
                dimIntensity: "",
                brightIntensity: "",
                photoSensing: "",
                photoUpperThreshold: "",
                photoLowerThreshold: "",
                motionSensitivity: "",
                installDate: "",
              })
            }
          >
            Clear
          </Button>
        </div>
        <div>
          <Controller
            control={control}
            render={({ field: formState }) => (
              <Button
                variant="primary"
                type="submit"
                className="mx-2"
                disabled={formState.isSubmitting}
              >
                {formState.isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1" />
                )}
                <strong>
                  {!formState.isSubmitting ? " CREATE" : " LOADING"}
                </strong>
              </Button>
            )}
          />
          <Button className="mx-2" variant="light" onClick={props.closeModal}>
            <span>Cancel</span>
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default LightForm;
