import React from 'react';
import { Link } from "react-router-dom";
import "./Breadcrumb.css";

const Breadcrumb = (props) => {
    return(
        <React.Fragment>
            <div className="breadcrumb-custom">
                <Link to="/map">Dashboard</Link> {props.data && props.data.map((path, idx) => {
                    return <span key={idx}> &gt; {path}</span>
                })}
            </div>
        </React.Fragment>
    );
}

export default Breadcrumb;