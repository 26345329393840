import React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CircularProgress } from "@mui/material";

const Selector = (props) => {
  return (
    <React.Fragment>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label" size="small">
          {props.label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={props.label}
          size={props.size}
          value={props.selected}
          onChange={props.onChange}
          disabled={props.data?.length === 1 ? true : false}
        >
          {(props.label === "Light" || props.label === "Floor") &&
            (props.showAllOptions || false) && (
              <MenuItem densevalue={0}>{`All ${props.label}s`}</MenuItem>
            )}
          {props.data?.map((item, idx) => {
            return (
              <MenuItem
                key={idx}
                dense
                value={
                  props.label === "Community"
                    ? item.communityId
                    : props.label === "Block"
                    ? item.blockId
                    : props.label === "Floor"
                    ? item.floorId
                    : item.lightId
                }
              >
                {props.loading ? (
                  <CircularProgress size={20} />
                ) : item ? (
                  props.label === "Community" ? (
                    item.communityName
                  ) : props.label === "Block" ? (
                    item.blockName
                  ) : props.label === "Floor" ? (
                    item.floorName
                  ) : (
                    item.displayName
                  )
                ) : (
                  "No data found."
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default Selector;
