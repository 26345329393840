import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Building } from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { Card, Spinner, Placeholder } from "react-bootstrap";

import Header from "../Shared/Header";
import Dropdown from "Component/Shared/Dropdown";
import useAxiosInstance from "hook/useAxiosInstance";
import { communityChangeHandler } from "state/entity/entityActions";

import "./CommunityDashboard.css";

const path = ["Choose An Area"];

const CommunityDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const token = useSelector((state) => state.auth.token);
  const [isLoading, setIsLoading] = useState(false);
  const [communityList, setCommunityList] = useState([]);
  const [activeCommunityInfoList, setActiveComunityInfoList] = useState([]); // use to display community info

  // render on page load to retrieve community list
  useEffect(() => {
    setIsLoading(true);
    const getCommunityList = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_ADMIN}/api/admin/community/getCommunityByUserBlock`
        );
        setCommunityList(response.data);
        setIsLoading(false);
      } catch {
        setCommunityList([]);
        setIsLoading(false);
      }
    };

    const getActiveCommunityInfoList = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_ADMIN}/api/admin/community/getActiveCommunityInfoByUserBlock`
        );

        if (response?.status === 200) {
          setActiveComunityInfoList(response.data);
          setIsLoading(false);
        }
      } catch {
        setActiveComunityInfoList([]);
        setIsLoading(false);
      }
    };

    if (token) {
      getCommunityList();
      getActiveCommunityInfoList();
    }
  }, [token, axiosInstance, dispatch]);

  const changeHandler = (event) => {
    const selectedCommunity = communityList.find(
      (community) => community.communityId === event.target.value
    );

    // save user session of selected community
    sessionStorage.setItem(
      "selectedCommunity",
      JSON.stringify(selectedCommunity)
    );
    dispatch(communityChangeHandler(selectedCommunity?.communityId));

    navigate("/dashboard", { replace: true });
  };

  const selectCommunityCardHandler = (comm) => {
    const selectedCommunity = activeCommunityInfoList.find(
      (community) => community?.communityId === comm?.communityId
    );

    // save user session of selected community
    sessionStorage.setItem(
      "selectedCommunity",
      JSON.stringify(selectedCommunity)
    );

    dispatch(communityChangeHandler(selectedCommunity?.communityId));
    navigate("/dashboard", { replace: true });
  };

  return (
    <React.Fragment>
      <Header title="Dashboard" path={path} className="map">
        {isLoading ? (
          <Spinner animation="border" size="sm" className="mx-4" />
        ) : communityList.length === 0 ? (
          <div className="d-flex flex-row justify-content-center align-content-center">
            <h2 style={{ color: "#c3c3c5" }}>No data found.</h2>
          </div>
        ) : (
          <div className="map-choose d-flex flex-row my-4">
            <Dropdown
              id="communityId"
              width="50%"
              value="communityName"
              label="Community"
              onChange={changeHandler}
              data={communityList}
            />
          </div>
        )}

        <div className="community-card_container">
          {!isLoading &&
            activeCommunityInfoList &&
            activeCommunityInfoList.map((comm, idx) => {
              return (
                <Card
                  key={idx}
                  bg="secondary"
                  text="white"
                  className="commmunity-card mx-2 mb-3"
                  onClick={() => {
                    selectCommunityCardHandler(comm);
                  }}
                >
                  <Card.Body>
                    <Card.Title className="dashboard-card-title">
                      <Building /> <span>{comm.communityName}</span>
                    </Card.Title>
                    <div className="mt-2">
                      <span>{comm.numBlocks} Blocks</span>
                      <br />
                      <span>{comm.numLights} Lights</span>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <span>Click to view dashboard</span>
                  </Card.Footer>
                </Card>
              );
            })}
          {isLoading && (
            <>
              <Placeholder as={Card.Title} animation="glow" className="w-100">
                <Placeholder xs={8} />
                <Placeholder xs={8} />
                <Placeholder xs={10} />
                <Placeholder xs={10} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
              </Placeholder>
            </>
          )}
        </div>
      </Header>
    </React.Fragment>
  );
};

export default CommunityDashboard;
