import { useState, useEffect, useMemo, useCallback } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EnhancedTableHead from "./TableHeader";

import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import ModalOverlay from "../Shared/ModalOverlay";
import { CircularProgress } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({
  values: {
    xxs: 330,
    xs: 400,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1600,
  },
});

const theme = createTheme({
  typography: {
    allVariants: {
      fontSize: "0.875rem",
      [breakpoints.down("md")]: {
        fontSize: "0.875rem",
        lineHeight: 1.2,
      },
      [breakpoints.down("sm")]: {
        fontSize: "0.825rem",
        lineHeight: 1.2,
      },
      [breakpoints.down("xs")]: {
        fontSize: "0.75rem",
        lineHeight: 1.2,
      },
      [breakpoints.down("xxs")]: {
        fontSize: "3vw",
        lineHeight: 1.2,
      },
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          [breakpoints.down("md")]: {
            padding: "4px 14px",
          },
          [breakpoints.down("sm")]: {
            padding: "4px 10px",
          },
          [breakpoints.down("xs")]: {
            padding: "2px 8px",
          },
          [breakpoints.down("xxs")]: {
            padding: "2px 4px",
          },
        },
      },
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTable = ({
  className,
  data,
  headCells,
  rowCells: EventRow,
  onRowClick,
  loading: tableLoading,
  children,
}) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdDate");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [result, setResult] = useState([]);

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  }, []);

  const handleChangeDense = useCallback((event) => {
    setDense(event.target.checked);
  }, []);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(
        search === "" ? data : result,
        getComparator(order, orderBy)
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [data, order, orderBy, page, rowsPerPage, search, result]
  );

  useEffect(() => {
    setLoading(true);

    const id = setTimeout(() => {
      const visibleData = [];
      if (search !== "") {
        data.forEach((status) => {
          for (const [_, value] of Object.entries(status)) {
            if (value && value !== "")
              if (
                value.toString().toLowerCase().includes(search.toLowerCase())
              ) {
                visibleData.push(status);
                break;
              }
          }
        });
      }
      setResult(visibleData);
      setLoading(false);
    }, 300);

    return () => {
      clearTimeout(id);
    };
  }, [search, data]);

  const [expand, setExpand] = useState(true);
  useEffect(() => {
    function handleWindowResize() {
      const innerWidth = window.innerWidth;
      innerWidth <= 768 ? setExpand(false) : setExpand(true);
    }
    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const toggleSettings = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%", height: "100%" }} className={className}>
        {isOpen && (
          <div style={{ height: "100%", backgroundColor: "black" }}>
            <ModalOverlay
              header="Filter Table"
              isOpen={isOpen}
              closeModal={() => setIsOpen(false)}
              clickBgToClose="true"
              hasButton="false"
            >
              <div
                className="d-flex flex-col"
                style={{ flexWrap: "wrap", width: "100%" }}
              >
                {children}
              </div>
            </ModalOverlay>
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="d-flex flex-row">
            <Paper
              className=""
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                marginLeft: ".5rem",
                marginRight: "4rem",
                boxShadow: "none",
                border: "1px solid #C3C3C3",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton type="button" sx={{ p: "2px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          <div className="d-flex float-xl-end">
            {children && !expand && (
              <div>
                <IconButton aria-label="settings" onClick={toggleSettings}>
                  <SettingsIcon />
                </IconButton>
              </div>
            )}

            {((children && expand) || !children) && (
              <div>
                <div className="d-flex flex-row" style={{ width: "100%" }}>
                  {children}
                  {/* <FormControlLabel className="ms-2"
                  control={
                    <CustomSwitch checked={dense} onChange={handleChangeDense} />
                  }
                  label="Compact"
                /> */}
                </div>
              </div>
            )}
          </div>
        </div>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              className="my-2"
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              {!tableLoading && !loading && (
                <TableBody>
                  {visibleRows.map((data, index) => {
                    // if custom row data is used
                    if (EventRow)
                      return (
                        <EventRow
                          key={index}
                          row={data}
                          onRowClick={onRowClick}
                          expand={expand}
                        />
                      );
                    // otherwise render all rows data
                    else
                      return (
                        <TableRow
                          key={index}
                          onClick={onRowClick}
                          data-custom={JSON.stringify(data)}
                          tabIndex={-1}
                          hover
                          sx={{ cursor: "pointer" }}
                        >
                          {headCells.map((column, idx) => {
                            return (
                              <TableCell
                                component="th"
                                scope="row"
                                align={column?.align || "center"}
                                key={idx}
                              >
                                {data[column.id]}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>

            {(tableLoading || loading) && (
              <Box className="d-flex flex-row justify-content-center my-5">
                <CircularProgress />{" "}
              </Box>
            )}
            {!tableLoading && !loading && visibleRows.length === 0 && (
              <Box className="d-flex flex-row justify-content-center my-5">
                <h3 style={{ color: "#c3c3c5" }}>No data found.</h3>
              </Box>
            )}
          </TableContainer>

          {!loading && !tableLoading && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                "& .MuiInputBase-root, & .MuiTablePagination-actions, & .MuiTablePagination-select, & .MuiButtonBase-root, & .MuiToolbar-root, & .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
                  {
                    marginLeft: { xs: "4px", sm: "8px" },
                    marginRight: { xs: "2px", sm: "4px" },
                    padding: { xs: "0", sm: "3px 0 4px" },
                  },
                "& .MuiSvgIcon-root": {
                  fontSize: { xs: "5vw", sm: "1em", md: "1.5em" },
                },
                "& .MuiTablePagination-select": {
                  paddingRight: { xs: "4vw !important", sm: "18px !important" },
                  paddingLeft: { xs: "0 !important", sm: "18px !important" },
                  minWidth: "0 !important",
                },
              }}
            />
          )}
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default EnhancedTable;
