import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "rsuite";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const TimerForm = (props) => {
  const validationSchema = Yup.object().shape({
    profileControl: Yup.string()
      .required()
      .oneOf(
        ["Enable", "Disable"],
        "Please select appropriate Profile Control."
      ),
    repeatAction: Yup.number().required(),
    dayOfWeek: Yup.string()
      .required()
      .oneOf(
        ["Weekday", "Weekend", "Everyday"],
        "Please select either Weekday, Weekend or Everyday only."
      ),
    lightId: Yup.string().required(),
    zoneId: Yup.number().required(),
    startTime: Yup.string().required(),
    endTime: Yup.string().required(),
    action: Yup.string()
      .required()
      .oneOf(
        ["Auto", "Force_On", "Force_Off"],
        "Please select either Auto, Force_On or Force_Off only."
      ),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      profileId: props.selectedProfile ? props.selectedProfile.profileId : 0,
      timerProfileId: props.selectedProfile
        ? props.selectedProfile.timerProfileId
        : 0,
      profileControl: props.selectedProfile
        ? props.selectedProfile.profileControl
        : "Enable",
      repeatAction: props.selectedProfile
        ? props.selectedProfile.repeatAction
        : 0,
      lightId: props.selectedProfile
        ? props.selectedProfile.lightId
        : props.light !== null &&
          props.light !== undefined &&
          props.light.displayName !== undefined
        ? props.light.displayName
        : 0,
      dayOfWeek: props.selectedProfile
        ? props.selectedProfile.dayOfWeek
        : "Everyday",
      zoneId: props.selectedProfile ? props.selectedProfile.zoneId : 0,
      startTime: props.selectedProfile
        ? props.selectedProfile.startTime
        : "00:00",
      endTime: props.selectedProfile ? props.selectedProfile.endTime : "00:00",
      action: props.selectedProfile ? props.selectedProfile.action : "Auto",
      group: "Timer",
    },
  });

  function getDateFromHours(time, typeOfTime) {
    if (time !== undefined && time !== null) {
      if (typeOfTime === "start") {
        time = time.split(":");
        let now = new Date();
        return new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          ...time
        );
      } else if (typeOfTime === "end") {
        time = time.split(":");
        if (time[0] === "00" && time[1] === "00") {
          let now = new Date();
          return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1,
            ...time
          );
        } else {
          let now = new Date();
          return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            ...time
          );
        }
      }
    } else {
      return new Date();
    }
  }

  return (
    <div>
      <h6 className="mb-2">Create Timer Profile</h6>
      <Form
        id="createTimerProfileForm"
        className="form-margin-width"
        onSubmit={handleSubmit(props.onSubmit)}
      >
        <div className="d-flex flex-row mb-2">
          <Form.Group as={Col} controlId="lightId">
            <Form.Label>Light</Form.Label>
            <Controller
              control={control}
              name="lightId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Control
                  type="text"
                  name="lightId"
                  readOnly
                  className="form-input-width"
                  value={value ? value : 0}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={errors.lightId}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lightId?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="zoneId">
            <Form.Label>Zone Id</Form.Label>
            <Controller
              control={control}
              name="zoneId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Control
                  type="text"
                  name="zoneId"
                  className="form-input-width"
                  readOnly
                  value={parseInt(value) ? parseInt(value) : 0}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={errors.zoneId}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.zoneId?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex flex-row mb-2">
          <Form.Group as={Col} controlId="profileControl">
            <Form.Label>Profile Control</Form.Label>
            <Controller
              control={control}
              name="profileControl"
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Select
                  name="profileControl"
                  className="form-input-width"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  isInvalid={errors.profileControl}
                >
                  <option key={0} id={0} value={""}>
                    -Select Profile Control-
                  </option>
                  <option key={1} id={1} value={"Enable"}>
                    Enable
                  </option>
                  <option key={2} id={2} value={"Disable"}>
                    Disable
                  </option>
                </Form.Select>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.profileControl?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="repeatAction">
            <Form.Label>Repeat Action</Form.Label>
            <Controller
              control={control}
              name="repeatAction"
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Control
                  type="text"
                  name="repeatAction"
                  readOnly
                  className="form-input-width"
                  value={value ? value : 0}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={errors.lightId}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.repeatControl?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex flex-row mb-2">
          <Col>
            <div className="form-input-width">
              <Form.Label
                htmlFor="startTime"
                className="label-style-edit-profile"
              >
                Start Time:
              </Form.Label>
              <Controller
                control={control}
                name="startTime"
                render={({ field: { value } }) => (
                  <DatePicker
                    id="startTime"
                    name="startTime"
                    placeholder="Please select Start Time"
                    format="HH:mm"
                    ranges={[]}
                    className="w-100"
                    placement="auto"
                    hideMinutes={(minute) => minute % 30 !== 0}
                    disabledMinutes={(minute) => minute % 30 !== 0}
                    value={getDateFromHours(value, "start")}
                    onChange={(e) => {
                      let timeVal = e.toLocaleTimeString([], {
                        hour12: false,
                        hour: "2-digit",
                        minute: "2-digit",
                      });
                      if (timeVal.split(":")[0] === "24")
                        timeVal = "00:" + timeVal.split(":")[1];
                      setValue("startTime", timeVal, {
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.startTime?.message}
              </Form.Control.Feedback>
            </div>
          </Col>
          <Col>
            <div className="form-input-width">
              <Form.Label
                htmlFor="endTime"
                className="label-style-edit-profile"
              >
                End Time:
              </Form.Label>
              <Controller
                control={control}
                name="endTime"
                render={({ field: { value } }) => (
                  <DatePicker
                    id="endTime"
                    name="endTime"
                    placeholder="Please select End Time"
                    format="HH:mm"
                    ranges={[]}
                    className="w-100"
                    placement="auto"
                    hideMinutes={(minute) => minute % 30 !== 0}
                    disabledMinutes={(minute) => minute % 30 !== 0}
                    value={getDateFromHours(value, "end")}
                    onChange={(e) => {
                      let timeVal = e.toLocaleTimeString([], {
                        hour12: false,
                        hour: "2-digit",
                        minute: "2-digit",
                      });
                      if (timeVal.split(":")[0] === "24")
                        timeVal = "00:" + timeVal.split(":")[1];
                      setValue("endTime", timeVal, {
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.endTime?.message}
              </Form.Control.Feedback>
            </div>
          </Col>
        </div>
        <div className="d-flex flex-row mb-2">
          <Form.Group
            as={Col}
            controlId="createTimerDayOfWeek"
            className="form-input-width"
          >
            <Form.Label>Day of Week</Form.Label>
            <Controller
              control={control}
              name="dayOfWeek"
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Select
                  name="dayOfWeek"
                  className="form-input-width"
                  value={value ? value : ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={errors.dayOfWeek}
                >
                  <option key={0} id={0} value={""}>
                    -Select Day of Week-
                  </option>
                  <option key={1} id={1} value={"Weekday"}>
                    Weekday
                  </option>
                  <option key={2} id={2} value={"Weekend"}>
                    Weekend
                  </option>
                  <option key={3} id={3} value={"Everyday"}>
                    Everyday
                  </option>
                </Form.Select>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.dayOfWeek?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="action" className="form-input-width">
            <Form.Label>Action</Form.Label>
            <Controller
              control={control}
              name="action"
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Select
                  name="action"
                  className="form-input-width"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  isInvalid={errors.action}
                >
                  <option key={0} id={0} value={""}>
                    -Select Action-
                  </option>
                  <option key={1} id={1} value={"Auto"}>
                    Auto
                  </option>
                  <option key={2} id={2} value={"Force_On"}>
                    Force On
                  </option>
                  <option key={3} id={3} value={"Force_Off"}>
                    Force Off
                  </option>
                </Form.Select>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.action?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        <div className="d-flex flex-row mt-4">
          <Col></Col>
          <Col className="d-flex flex-row justify-content-end me-4">
            {props.selectedProfile && (
              <Button
                onClick={props.onDelete}
                variant="danger"
                className="mx-2"
                disabled={props.disableCrud}
              >
                Delete
              </Button>
            )}
            <Controller
              control={control}
              render={({ field: formState }) => (
                <Button
                  type="submit"
                  variant="primary"
                  className="mx-2"
                  disabled={formState.isSubmitting}
                >
                  {formState.isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1" />
                  )}
                  <strong>
                    {!formState.isSubmitting
                      ? props.selectedProfile
                        ? " UPDATE"
                        : " CREATE"
                      : " LOADING"}
                  </strong>
                </Button>
              )}
            />
            <Button className="mx-2" variant="light" onClick={props.closeModal}>
              <span>Cancel</span>
            </Button>
          </Col>
        </div>
      </Form>
    </div>
  );
};

export default TimerForm;
