import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "state/auth/authSlice";
import { axiosInstance } from "../services/api/axios";

const useAxiosInstance = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    // Add a request interceptor
    const requestIntercept = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"])
          config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      },
      (err) => Promise.reject(err)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      undefined,
      (err) => {
        // if (err.response.status === 403) dispatch(logout());
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [token, dispatch]);

  return axiosInstance;
};

export default useAxiosInstance;