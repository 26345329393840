import React, { useState, useEffect } from "react";
import { Button, Modal } from "rsuite";

// Props
// isOpen, header, closeModal, leftBtn, leftBtnAction, leftBtnType
// leftBtn = Button's name
// leftBtnAction = Button's onClick
// leftBtnType = Button's type
// displayUpdate = boolean to render Update button
// clickBgToClose = if true, user can click background to close modal, else not able to

import "./ModalOverlay.css";

const ModalOverlay = (props) => {
  const [displayUpdate, setDisplayUpdate] = useState(true);


  useEffect(() => {
    const canDisplayUpdate = props.displayUpdate;

    if (canDisplayUpdate !== undefined) {
      setDisplayUpdate(canDisplayUpdate);
    }
  }, [props.displayUpdate]);

  return (
    <Modal
      id={props.id}
      backdrop={props.clickBgToClose === undefined? 'static' : props.clickBgToClose}
      keyboard={props.keyboardToggle === undefined? true : props.keyboardToggle}
      open={props.isOpen}
      onClose={props.closeModal}
      size={"md"}
      overflow={false}
      style={{
        width: props.width,
        height: props.height,
        margin: props.margin,
        // overflow: "hidden !important",
      }}
    >
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "bold" }}>{props.header}</Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
      >
        {props.children}
      </Modal.Body>

      {!props.hasButton && (
        <Modal.Footer>
          <div className="align-right">
            {displayUpdate && (
              <Button
                type={props.leftBtnType || "button"}
                onClick={props.leftBtnAction}
                appearance="primary"
                color={props.color}
                style={{ textTransform: "uppercase", fontWeight: "bold" }}
              >
                {props.leftBtn}
              </Button>
            )}
            <Button onClick={props.closeModal} appearance="subtle">
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalOverlay;
