import React, {
  useState,
  useCallback,
  createContext,
  useEffect,
  useRef,
} from "react";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";

export const CollectorSocketContext = createContext();

export const CollectorSocketProvider = ({ url, token, children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const stompClient = useRef(null);
  const subscriptions = useRef([]);

  useEffect(() => {
    const connect = () => {
      return new Promise((resolve, reject) => {
        const client = new SockJS(url, null, { timeout: 15000 });
        stompClient.current = new Client({
          webSocketFactory: () => client,
          connectHeaders: {
            login: token,
            passcode: "",
          },
          // debug: (str) => {
          //   console.log("[LIGHT PROFILE SOCKET]: " + str);
          // },
          // reconnectDelay: 10000,
          // heartbeatIncoming: 5000,
          // heartbeatOutgoing: 5000,
        });

        stompClient.current.onConnect = () => {
          console.log("Successfully connected to : " + url);
          setIsConnected(true);
          resolve();
        };

        stompClient.current.onDisconnect = (error) => {
          setIsConnected(false);
          console.log("ERROR IN LIGHT PROFILE WEBSOCKET: " + error);
        };

        stompClient.current.onStompError = (error) => {
          console.log("Broker reported error: " + error.headers["message"]);
          console.log("Additional details: " + error.body);
          setIsConnected(false);
          reject(error);
        };

        stompClient.current.activate();
      });
    };

    connect();

    return () => {
      if (stompClient.current) stompClient.current.deactivate();
    };
  }, [url]);

  const subscribe = (destination, callback) => {
    if (stompClient.current && stompClient && isConnected) {
      try {
        const subscription = stompClient.current.subscribe(
          destination,
          (message) => callback(message.body)
        );
        subscriptions.current.push(subscription);
        return subscription;
      } catch (err) {
        console.log(err);
      }
    }
  };

  const unsubscribe = (subscription) => {
    if (subscription && stompClient.current) {
      try {
        subscription.unsubscribe();

        const index = subscriptions.current.indexOf(subscription);
        if (index !== -1) subscriptions.current.splice(index, 1);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const cleanup = () => {
    const currSubscriptions = subscriptions.current;

    for (const subscription of currSubscriptions) subscription.unsubscribe();

    subscriptions.current = [];
  };

  const disconnect = useCallback(() => {
    console.log("Calling disconnect function");
    if (stompClient.current) {
      stompClient.current.deactivate();
      console.log("WebSocket connection to: " + url + " disconnected.");
    }
  });

  const contextValue = {
    subscribe,
    unsubscribe,
    isConnected,
    disconnect,
    cleanup,
  };

  return (
    <CollectorSocketContext.Provider value={contextValue}>
      {children}
    </CollectorSocketContext.Provider>
  );
};
