import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "rsuite";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const LuminanceForm = (props) => {
  const validationSchema = Yup.object().shape({
    profileControl: Yup.string()
      .required()
      .oneOf(
        ["Enable", "Disable"],
        "Please select appropriate Profile Control."
      ),
    repeatAction: Yup.number().required(),
    lightId: Yup.string().required(),
    zoneId: Yup.number().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    luminanceLevel: Yup.number()
      .required()
      .min(50, "Minimum Luminance Level is 50.")
      .max(100, "Maximum Luminance is 100%."),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      profileId: props.selectedProfile ? props.selectedProfile.profileId : 0,
      luminanceProfileId: props.selectedProfile
        ? props.selectedProfile.luminanceProfileId
        : 0,
      profileControl: props.selectedProfile
        ? props.selectedProfile.profileControl
        : "Enable",
      repeatAction: props.selectedProfile
        ? props.selectedProfile.repeatAction
        : 0,
      lightId: props.selectedProfile
        ? props.selectedProfile.lightId
        : props.light !== null &&
          props.light !== undefined &&
          props.light.displayName !== undefined
        ? props.light.displayName
        : 0,
      zoneId: props.selectedProfile ? props.selectedProfile.zoneId : 0,
      startDate: props.selectedProfile
        ? props.selectedProfile?.startDate
        : new Date().toLocaleDateString("en-sg").replaceAll("/", "-"),
      endDate: props.selectedProfile
        ? props.selectedProfile?.endDate
        : new Date().toLocaleDateString("en-sg").replaceAll("/", "-"),
      luminanceLevel: props.selectedProfile
        ? props.selectedProfile.luminanceLevel
        : 0,
      group: "Luminance",
    },
  });

  return (
    <div>
      <h6 className="mb-2">Create Luminance Profile</h6>
      <Form
        id="viewLuminanceForm"
        className="form-margin-width"
        onSubmit={handleSubmit(props.onSubmit)}
      >
        <div className="d-flex flex-row mb-2">
          <Form.Group as={Col} controlId="lightId">
            <Form.Label>Light</Form.Label>
            <Controller
              control={control}
              name="lightId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Control
                  type="text"
                  name="lightId"
                  readOnly
                  className="form-input-width"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={errors.lightId}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lightId?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="zoneId">
            <Form.Label>Zone Id</Form.Label>
            <Controller
              control={control}
              name="zoneId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Control
                  type="text"
                  name="zoneId"
                  className="form-input-width"
                  readOnly
                  value={parseInt(value)}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={errors.zoneId}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.zoneId?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex flex-row mb-2">
          <Form.Group as={Col} controlId="profileControl">
            <Form.Label>Profile Control</Form.Label>
            <Controller
              control={control}
              name="profileControl"
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Select
                  name="profileControl"
                  className="form-input-width"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  isInvalid={errors.profileControl}
                >
                  <option key={0} id={0} value={""}>
                    -Select Profile Control-
                  </option>
                  <option key={1} id={1} value={"Enable"}>
                    Enable
                  </option>
                  <option key={2} id={2} value={"Disable"}>
                    Disable
                  </option>
                </Form.Select>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.profileControl?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="repeatAction">
            <Form.Label>Repeat Action</Form.Label>
            <Controller
              control={control}
              name="repeatAction"
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Control
                  type="text"
                  name="repeatAction"
                  className="form-input-width"
                  readOnly
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={errors.repeatAction}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.repeatAction?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        <div className="d-flex flex-row mb-2">
          <Col>
            <div className="form-input-width">
              <Form.Label
                htmlFor="startDate"
                className="label-style-edit-profile"
              >
                Start Date:
              </Form.Label>
              <Controller
                control={control}
                name="startDate"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    name="startDate"
                    placeholder="Please select Start Date"
                    format="dd-MM-yyyy"
                    ranges={[]}
                    className="w-100"
                    placement="auto"
                    value={
                      new Date(
                        parseInt(String(value).split("-")[2]),
                        parseInt(String(value).split("-")[1]) - 1,
                        parseInt(String(value).split("-")[0])
                      )
                    }
                    onChange={(e) => {
                      setValue(
                        "startDate",
                        new Date(e)
                          .toLocaleDateString("en-sg")
                          .replaceAll("/", "-"),
                        {
                          shouldValidate: true,
                        }
                      );
                    }}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.startDate?.message}
              </Form.Control.Feedback>
            </div>
          </Col>
          <Col>
            <div className="form-input-width">
              <Form.Label
                htmlFor="endDate"
                className="label-style-edit-profile"
              >
                End Date:
              </Form.Label>
              <Controller
                control={control}
                name="endDate"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    name="endDate"
                    placeholder="Please select End Date"
                    format="dd-MM-yyyy"
                    ranges={[]}
                    className="w-100"
                    placement="auto"
                    value={
                      new Date(
                        parseInt(String(value).split("-")[2]),
                        parseInt(String(value).split("-")[1]) - 1,
                        parseInt(String(value).split("-")[0])
                      )
                    }
                    onChange={(e) => {
                      setValue(
                        "endDate",
                        new Date(e)
                          .toLocaleDateString("en-sg")
                          .replaceAll("/", "-"),
                        {
                          shouldValidate: true,
                        }
                      );
                    }}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.endDate?.message}
              </Form.Control.Feedback>
            </div>
          </Col>
        </div>
        <div className="d-flex flex-row mb-3">
          <Form.Group as={Col} controlId="luminanceLevel">
            <Form.Label>Luminance Level</Form.Label>
            <Controller
              control={control}
              name="luminanceLevel"
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Control
                  type="number"
                  name="luminanceLevel"
                  className="form-input-width"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={errors.luminanceLevel}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.luminanceLevel?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Col></Col>
        </div>
        <div className="d-flex flex-row">
          <Col></Col>
          <Col className="d-flex flex-row justify-content-end me-4">
            {props.selectedProfile && (
              <Button
                onClick={props.onDelete}
                variant="danger"
                className="mx-2"
                disabled={props.disableCrud}
              >
                Delete
              </Button>
            )}
            <Controller
              control={control}
              render={({ field: formState }) => (
                <Button
                  type="submit"
                  variant="primary"
                  className="mx-2"
                  disabled={formState.isSubmitting}
                >
                  {formState.isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1" />
                  )}
                  <strong>
                    {!formState.isSubmitting
                      ? props.selectedProfile
                        ? " UPDATE"
                        : " CREATE"
                      : " LOADING"}
                  </strong>
                </Button>
              )}
            />
            <Button className="mx-2" variant="light" onClick={props.closeModal}>
              <span>Cancel</span>
            </Button>
          </Col>
        </div>
      </Form>
    </div>
  );
};

export default LuminanceForm;
