import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Card, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { login } from "state/auth/authSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import * as Yup from "yup";
import Logo from "../../images/logo.png";

import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(16, "Password must not exceed 16 characters"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (userId && location.state) navigate(location.state.from.pathname);
    else if (userId) navigate("/map", { replace: true });
  }, [userId, location.state, navigate]);

  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem("session"));
    if (location.state?.message && storedData) {
      toast.error(location.state.message);
      location.state.message = null;
    }
  }, [location.state]);

  const loginHandler = async (data) => {
    try {
      const uninterceptedAxiosInstance = axios.create(); // create new axios without interceptors
      const loginData = {
        username: data.email,
        password: data.password,
      };

      const response = await uninterceptedAxiosInstance.post(
        process.env.REACT_APP_API_USER + "/api/user/login",
        loginData
      );

      if (response?.status === 200) {
        dispatch(login(response.data?.token));
        navigate("/map", { replace: true });
      } else toast.error("Server might be down. Please try again later.");
    } catch (err) {
      if (err.response) {
        toast.error("Incorrect username or password.");
      } else toast.error("Server might be down. Please try again later.");
    }
  };

  return (
    <div className="background-img">
      <div className="login-container">
        <Card className="login-card">
          <Card.Header className="login-card_header">
            <Card.Title>
              <img src={Logo} alt="Aztech Logo" width="120" />
              <h5>Login</h5>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit(loginHandler)}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Username</Form.Label>

                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      type="email"
                      isInvalid={errors.email}
                      placeholder="Enter email"
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      type="password"
                      isInvalid={errors.password}
                      placeholder="Enter password"
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group
                className="login-checkbox mt-3"
                controlId="login-checkbox"
              >
                <Form.Check type="checkbox" label="Remember Me" />
              </Form.Group> */}

              <div className="login-card_bottom-container justify-content-end mt-4">
                {/* <Card.Link href="/forget/password">Forgot Password?</Card.Link> */}
                <Controller
                  control={control}
                  render={({ field: { ref }, formState }) => (
                    <Button
                      type="submit"
                      disabled={formState.isSubmitting}
                      className="btn btn-primary"
                    >
                      {formState.isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1" />
                      )}

                      {!formState.isSubmitting ? " Login" : " Loading"}
                    </Button>
                  )}
                />
              </div>
            </Form>
          </Card.Body>
          <Card.Footer className="login-card_footer">
            <Card.Link href="mailto:aztech.dev@aztech.com">
              Contact Admin
            </Card.Link>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

export default Login;