export const profileReducers = (state, action) => {
    switch (action.type) {
      case "SET_TIMER_PROFILE":
        return { ...state, timer: action.payload };
      case "ADD_TIMER_PROFILE":
        return {
          ...state,
          timer: [...state.timer, action.payload],
          count: state.count + 1,
        };
      case "UPDATE_TIMER_PROFILE":
        return {
          ...state,
          timer: [
            ...state.timer.filter(
              (item) => item.timerProfileId !== action.payload.timerProfileId
            ),
            action.payload,
          ],
        };
      case "ENABLE_TIMER_PROFILE":
        return {
          ...state,
          timer: [
            ...state.timer.map((item) => {
              return { ...item, profileControl: "Enable" };
            }),
          ],
        };
      case "DISABLE_TIMER_PROFILE":
        return {
          ...state,
          timer: [
            ...state.timer.map((item) => {
              return { ...item, profileControl: "Disable" };
            }),
          ],
        };
      case "REMOVE_TIMER_PROFILE":
        return {
          ...state,
          timer: [
            ...state.timer.filter((item) => item.profileId !== action.payload),
          ],
        };
      case "CLEAR_TIMER_PROFILE":
        return { ...state, timer: [] };
      case "SHOW_TIMER_PROFILE":
        return {
          ...state,
          show_timer: true,
          show_daylight: false,
          show_dimming: false,
          show_luminance: false,
        };
  
      case "SET_DAYLIGHT_PROFILE":
        return { ...state, daylight: action.payload };
      case "ADD_DAYLIGHT_PROFILE":
        return {
          ...state,
          daylight: [...state.daylight, action.payload],
          count: state.count + 1,
        };
      case "UPDATE_DAYLIGHT_PROFILE":
        return {
          ...state,
          daylight: [
            ...state.daylight.filter(
              (item) =>
                item.daylightProfileId !== action.payload.daylightProfileId
            ),
            action.payload,
          ],
        };
      case "REMOVE_DAYLIGHT_PROFILE":
        return {
          ...state,
          daylight: [
            ...state.daylight.filter((item) => item.profileId !== action.payload),
          ],
        };
      case "ENABLE_DAYLIGHT_PROFILE":
        return {
          ...state,
          daylight: [
            ...state.daylight.map((item) => {
              return { ...item, profileControl: "Enable" };
            }),
          ],
        };
      case "DISABLE_DAYLIGHT_PROFILE":
        return {
          ...state,
          daylight: [
            ...state.daylight.map((item) => {
              return { ...item, profileControl: "Disable" };
            }),
          ],
        };
      case "CLEAR_DAYLIGHT_PROFILE":
        return { ...state, daylight: [] };
      case "SHOW_DAYLIGHT_PROFILE":
        return {
          ...state,
          show_timer: false,
          show_daylight: true,
          show_dimming: false,
          show_luminance: false,
        };
  
      case "SET_DIMMING_PROFILE":
        return { ...state, dimming: action.payload };
      case "ADD_DIMMING_PROFILE":
        return {
          ...state,
          dimming: [...state.dimming, action.payload],
          count: state.count + 1,
        };
      case "UPDATE_DIMMING_PROFILE":
        return {
          ...state,
          dimming: [
            ...state.dimming.filter(
              (item) => item.dimmingProfileId !== action.payload.dimmingProfileId
            ),
            action.payload,
          ],
        };
      case "REMOVE_DIMMING_PROFILE":
        return {
          ...state,
          dimming: [
            ...state.dimming.filter((item) => item.profileId !== action.payload),
          ],
        };
      case "ENABLE_DIMMING_PROFILE":
        return {
          ...state,
          dimming: [
            ...state.dimming.map((item) => {
              return { ...item, profileControl: "Enable" };
            }),
          ],
        };
      case "DISABLE_DIMMING_PROFILE":
        return {
          ...state,
          dimming: [
            ...state.dimming.map((item) => {
              return { ...item, profileControl: "Disable" };
            }),
          ],
        };
      case "CLEAR_DIMMING_PROFILE":
        return { ...state, dimming: [] };
      case "SHOW_DIMMING_PROFILE":
        return {
          ...state,
          show_timer: false,
          show_daylight: false,
          show_dimming: true,
          show_luminance: false,
        };
  
      case "SET_LUMINANCE_PROFILE":
        return { ...state, luminance: action.payload };
      case "ADD_LUMINANCE_PROFILE":
        return {
          ...state,
          luminance: [...state.luminance, action.payload],
          count: state.count + 1,
        };
      case "UPDATE_LUMINANCE_PROFILE":
        return {
          ...state,
          luminance: [
            ...state.luminance.filter(
              (item) =>
                item.luminanceProfileId !== action.payload.luminanceProfileId
            ),
            action.payload,
          ],
        };
      case "REMOVE_LUMINANCE_PROFILE":
        return {
          ...state,
          luminance: [
            ...state.luminance.filter(
              (item) => item.profileId !== action.payload
            ),
          ],
        };
      case "ENABLE_LUMINANCE_PROFILE":
        return {
          ...state,
          luminance: [
            ...state.luminance.map((item) => {
              return { ...item, profileControl: "Enable" };
            }),
          ],
        };
      case "DISABLE_LUMINANCE_PROFILE":
        return {
          ...state,
          luminance: [
            ...state.luminance.map((item) => {
              return { ...item, profileControl: "Disable" };
            }),
          ],
        };
      case "CLEAR_LUMINANCE_PROFILE":
        return { ...state, luminance: [] };
      case "SHOW_LUMINANCE_PROFILE":
        return {
          ...state,
          show_timer: false,
          show_daylight: false,
          show_dimming: false,
          show_luminance: true,
        };
  
      case "CLEAR_DEFAULT":
        return {
          ...state,
          timer: [],
          daylight: [],
          dimming: [],
          luminance: [],
          show_timer: false,
          show_daylight: false,
          show_dimming: false,
          show_luminance: false,
          toggle: false,
          count: 0,
        };
  
      case "DISABLE_ALL_PROFILES":
        return {
          ...state,
          timer: [
            ...state.timer.map((item) => {
              return { ...item, profileControl: "Disable" };
            }),
          ],
          daylight: [
            ...state.daylight.map((item) => {
              return { ...item, profileControl: "Disable" };
            }),
          ],
          dimming: [
            ...state.dimming.map((item) => {
              return { ...item, profileControl: "Disable" };
            }),
          ],
          luminance: [
            ...state.luminance.map((item) => {
              return { ...item, profileControl: "Disable" };
            }),
          ],
        };
  
      case "HIDE_ALL_PROFILES":
        return {
          ...state,
          show_timer: false,
          show_daylight: false,
          show_dimming: false,
          show_luminance: false,
        };
  
      case "TOGGLE":
        return {
          ...state,
          toggle: !state.toggle,
        };
  
      case "INCREMENT_COUNT":
        return {
          ...state,
          count: state.count + 1,
        };
  
      case "DECREMENT_COUNT":
        return {
          ...state,
          count: state.count - 1,
        };
      default:
        return state;
    }
  };