import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import ModalOverlay from "../Shared/ModalOverlay";
import Header from "../Shared/Header";
import EventColumn from "./EventColumn";
import useEvent from "hook/useEvent";
import EnhancedTable from "Component/Shared/EnhancedTable";
import EventRow from "./EventRow";
import { Button, ButtonGroup, MenuItem, TextField } from "@mui/material";

const path = ["Event Logs"];

const Event = () => {
  const { selectedFloor } = useSelector((state) => state.entity);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [formEventType, setFormEventType] = useState("");
  const [eventDetailModalOpen, setEventDetailModalOpen] = useState(false);
  const [selectedStatusButton, setSelectedStatusButton] = useState("");

  const { events, loading } = useEvent(selectedFloor);
  const options = [...new Set(events.map((event) => event.eventType))];
  const [filteredEventsList, setFilteredEventsList] = useState(events);

  useEffect(() => {
    const filterCriteria = {
      eventStatus: selectedStatusButton,
      eventType: formEventType,
    };

    try {
      setFilteredEventsList(
        events.filter(
          (event) =>
            event.eventStatus.includes(filterCriteria.eventStatus) &&
            event.eventType.includes(filterCriteria.eventType)
        )
      );
    } catch (error) {
      console.error("Error filtering fault list:", error);
    }
  }, [selectedStatusButton, formEventType, events]);

  const handleResolvedButtonClick = (buttonName) => {
    buttonName == selectedStatusButton
      ? setSelectedStatusButton("")
      : setSelectedStatusButton(buttonName);
  };
  const handleEventChange = (e) => {
    e.target.value === ""
      ? setFormEventType("")
      : setFormEventType(e.target.value);
  };

  return (
    <React.Fragment>
      <ModalOverlay
        header="View Event Details"
        size="md"
        isOpen={eventDetailModalOpen}
        closeModal={() => setEventDetailModalOpen(false)}
        displayUpdate={false}
      >
        <div className="d-flex flex-column">
          {selectedEvent &&
            Object.entries(selectedEvent).map(([key, value], idx) => {
              if (
                key !== "senderId" &&
                key !== "eventId" &&
                key !== "floor" &&
                key !== "gateway" &&
                key !== "block" &&
                key !== "commandType"
              ) {
                if (value !== null && key !== "listOfProfileEvent") {
                  key = key.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
                  key = key.charAt(0).toUpperCase() + key.slice(1);
                  return (
                    <div style={{ textAlign: "center !important" }} key={idx}>
                      <Form>
                        <Form.Group
                          controlId="form.Name"
                          className="d-flex flex-row justify-content-between"
                          style={{ paddingTop: "10px" }}
                        >
                          <Form.Label className="w-50 flex-nowrap">
                            {key}:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            readOnly
                            value={value}
                            style={{
                              margin: "auto",
                              color:
                                value == "Success"
                                  ? "green"
                                  : value == "Failed"
                                  ? "red"
                                  : value == "Pending"
                                  ? "orange"
                                  : "black",
                            }}
                          />
                        </Form.Group>
                      </Form>
                    </div>
                  );
                }
              }
            })}
        </div>
      </ModalOverlay>
      <Header title="Event Log" path={path} className="dashboard">
        <div className="dashboard-light-status_container-content">
          <EnhancedTable
            loading={loading}
            rowCells={EventRow}
            headCells={EventColumn}
            data={filteredEventsList}
            onRowClick={(event) => {
              const selectedRow = event.currentTarget;
              const rowData = JSON.parse(
                selectedRow.getAttribute("data-custom")
              );
              setEventDetailModalOpen(true);
              setSelectedEvent(rowData);
            }}
          >
            <div
              className="d-flex me-4 pt-1 w-100"
              style={{ minWidth: "12em" }}
            >
              <TextField
                select
                variant="outlined"
                className="w-100"
                size="small"
                label="Event"
                value={formEventType}
                onChange={handleEventChange}
              >
                <MenuItem value="">--Select Event--</MenuItem>
                {options.map((option, index) => (
                  <MenuItem key={index} value={option} className="px-4">
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="pt-1 d-flex" style={{ width: "100%" }}>
              <ButtonGroup style={{ width: "100%" }}>
                <Button
                  size="small"
                  className="w-100 px-4"
                  style={{ overflow: "hidden" }}
                  onClick={() => handleResolvedButtonClick("Fail")}
                  color={selectedStatusButton === "Fail" ? "error" : "info"}
                  variant={
                    selectedStatusButton === "Fail" ? "contained" : "outlined"
                  }
                >
                  Fail
                </Button>
                <Button
                  size="small"
                  className="w-100 px-4"
                  style={{ overflow: "hidden" }}
                  onClick={() => handleResolvedButtonClick("Pending")}
                  variant={
                    selectedStatusButton === "Pending"
                      ? "contained"
                      : "outlined"
                  }
                  color={
                    selectedStatusButton === "Pending" ? "warning" : "info"
                  }
                >
                  Pending
                </Button>
                <Button
                  size="small"
                  className="px-4"
                  style={{ width: "100%", overflow: "hidden" }}
                  onClick={() => handleResolvedButtonClick("Success")}
                  color={
                    selectedStatusButton === "Success" ? "success" : "info"
                  }
                  variant={
                    selectedStatusButton === "Success"
                      ? "contained"
                      : "outlined"
                  }
                >
                  Success
                </Button>
              </ButtonGroup>
            </div>
          </EnhancedTable>
        </div>
      </Header>
    </React.Fragment>
  );
};

export default Event;
