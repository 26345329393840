import React from "react";
import { Card } from "react-bootstrap";

import "./StatusCard.css";

const StatusCard = (props) => {
  return (
    <React.Fragment>
      <Card
        bg={props.color}
        text="white"
        className={`card-status mb-2 ${props.className}`}
        style={{ height: props.height ? props.height : "", minHeight: "200px" }}
      >
        {props.image && (
          <Card.Img variant="top" src={props.image} alt="Card Image" />
        )}
        <Card.Body id="dashboard-card-body">
          {props.title && (
            <Card.Title as="h6">
              {props.icon} {props.title}
            </Card.Title>
          )}
          {props.children}
        </Card.Body>
        {props.footer && <Card.Footer>{props.footer}</Card.Footer>}
      </Card>
    </React.Fragment>
  );
};

export default StatusCard;
