import { useState, useEffect, useCallback } from "react";
import useAxiosInstance from "./useAxiosInstance";
import { toast } from "react-toastify";

const useFault = (floorId) => {
  const axiosInstance = useAxiosInstance();
  const [faultList, setFaultList] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (floorId) getFaultLog(floorId);
  }, [floorId]);

  useEffect(() => {
    if (faultList?.length > 0) setIsEmpty(false);
    else setIsEmpty(true);
  }, [faultList]);

  const getFaultLog = useCallback(async (floorId) => {
    setIsLoading(true);
    const response = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/eventlog/fault/floor/${floorId}`
      )
      .catch((err) => {
        setFaultList([]);
      });
    if (response?.status === 200 && response?.data.length > 0)
      setFaultList(response.data);
    else setFaultList([]);
    setIsLoading(false);
  }, []);

  const clearFault = useCallback(async (faultId, desc) => {
    setIsLoading(true);
    const response = await axiosInstance
      .post(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/eventlog/fault/clear/${faultId}`,
        {
          description: desc,
        }
      )
      .catch((err) => {
        toast.error("Error clearing fault. Please try again later.");
      });

    if (response?.status === 200) {
      toast.success("Fault successfully cleared!");
      setFaultList((prevState) => [
        response?.data,
        ...prevState.filter((item) => item.faultId !== faultId),
      ]);
    }

    setIsLoading(false);

    return response?.data || {};
  }, []);

  return { faultList, isEmpty, isLoading, clearFault };
};

export default useFault;