import React from "react";
import ModalOverlay from "../Shared/ModalOverlay";

import InnerImageZoom from "react-inner-image-zoom";

const Floorplan = (props) => {
  //download floor plan
  const handleGetFloorPlan = async () => {
    const link = document.createElement("a");
    link.href = props.floorplanUrl;
    link.setAttribute(
      "download",
      "Floorplan.jpg"
      // `Floorplan for ${props.floor.floorName}.jpg` // currently hardcode to jpg
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <React.Fragment>
      <ModalOverlay
        header="View Floor Plan"
        isOpen={props.floorplanOpen}
        closeModal={() => props.closeFloorplan(false)}
        displayUpdate={props.canDownload || false}
        leftBtnAction={handleGetFloorPlan}
        leftBtn="Download"
      >
        <div className="dashboard-light-status_container card-header">
          <InnerImageZoom
            src={props.floorplanUrl}
            style={{ width: "100%", height: "100%" }}
            zoomType="click" //defines what triggers the zoom
            zoomPreload={true}
            zoomScale={1} //zoom level
          />
        </div>
      </ModalOverlay>
    </React.Fragment>
  );
};

export default Floorplan;
