import { configureStore } from "@reduxjs/toolkit";
import { PERSIST, persistStore, persistReducer } from "redux-persist";
import userReducer from "./auth/authSlice";
import entityReducer from "./entity/entitySlice";
import notificationReducer from "./notification/notificationSlice";

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import createExpirationTransform from "redux-persist-transform-expire";
import storage from "redux-persist/lib/storage";

const expireTransform = createExpirationTransform({
  expireKey: "tokenExp",
  defaultState: {},
});

const persistConfig = {
  key: "auth",
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: [expireTransform]
};

const persistedReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    auth: persistedReducer,
    entity: entityReducer,
    notification: notificationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }),
});

export const persistor = persistStore(store);
