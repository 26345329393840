import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import useAxiosInstance from "hook/useAxiosInstance";

const useBlockInfo = (blockId) => {
  const axiosInstance = useAxiosInstance();

  const [lightBlockStatus, setLightBlockStatus] = useState({});
  const [lightMaintenanceCount, setLightMaintenanceCount] = useState(0);
  const [lightConnectionCount, setLightConnectionCount] = useState(0);
  const [lightFaultChart, setLightFaultChart] = useState(0);
  const [mostActiveLightList, setMostActiveLightList] = useState([]);
  const [blockInfo, setBlockInfo] = useState({});
  const [yesterdayEnergyConsumption, setYesterdayEnergyConsumption] = useState(
    {}
  );

  const [mostActiveLightLoading, setMostActiveLightLoading] = useState(false);
  const [networkConnectionLoading, setNetworkConnectionLoading] = useState(
    false
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (blockId) {
      setIsLoading(true);

      Promise.allSettled([
        getLightSummary(blockId),
        getLightMaintenanceCount(blockId),
        getMotionDetectNetworkConnection(blockId),
        getFaultCount(blockId),
        getBlockInfo(blockId),
        getMostActiveLight(blockId),
        getYesterdayEnergyConsumption(blockId),
      ]).then((values) => {
        setIsLoading(false);
      });
    }
  }, [blockId]);

  const getLightSummary = async (blockId) => {
    const controller = new AbortController();
    const lightSummaryResponse = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ANALYTICS}/api/analytic/lightSummary/${blockId}`,
        { signal: controller.signal }
      )
      .catch((error) => {
        toast.error(error?.response.data);
        setLightBlockStatus(null);
      });
    if (lightSummaryResponse?.status === 200)
      setLightBlockStatus(lightSummaryResponse.data);

    return () => {
      toast.error(
        "Error retrieving light summary info. Please try again later."
      );
      setLightBlockStatus(null);
      controller.abort();
    };
  };

  const getLightMaintenanceCount = async (blockId) => {
    const controller = new AbortController();
    const response = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ANALYTICS}/api/analytic/maintenanceLight/${blockId}`
      )
      .catch((error) => {
        if (error?.response !== null) {
          toast.error(error.response.data);
          setLightMaintenanceCount(0);
        }
      });

    if (response?.status === 200) setLightMaintenanceCount(response.data);

    return () => {
      toast.error(
        "Error retrieving light maintenance count. Please try again later."
      );
      setLightMaintenanceCount(0);
      controller.abort();
    };
  };

  const getMotionDetectNetworkConnection = async (blockId) => {
    const controller = new AbortController();
    let motionDetectNetwork = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ANALYTICS}/api/analytic/networkConnection/motionDetect/${blockId}`,
        {
          signal: controller.signal,
        }
      )
      .catch((error) => {
        toast.error(error?.response.data);
        setLightConnectionCount();
        setNetworkConnectionLoading(false);
      });

    if (motionDetectNetwork?.status === 200)
      setLightConnectionCount(motionDetectNetwork?.data);

    setNetworkConnectionLoading(false);

    return () => {
      toast.error(
        "Error retrieving light summary info. Please try again later."
      );
      setNetworkConnectionLoading(false);
      setLightConnectionCount();
      controller.abort();
    };
  };

  const getFaultCount = async (blockId) => {
    const controller = new AbortController();
    let faultCountResponse = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ANALYTICS}/api/analytic/faultCount/${blockId}`,
        { signal: controller.signal }
      )
      .catch((error) => {
        toast.error(error?.response.data);
        setLightFaultChart({});
      });

    if (faultCountResponse?.status === 200) {
      var labels = [];
      var series = [];
      for (let i = 0; i < faultCountResponse.data.length; i++) {
        labels.push(faultCountResponse.data[i].eventType);
        series.push(faultCountResponse.data[i].faultCount);
      }
      setLightFaultChart({
        labels: labels,
        series: series,
      });
    }

    return () => {
      toast.error(
        "Error retrieving light summary info. Please try again later."
      );
      controller.abort();
    };
  };

  const getBlockInfo = async (blockId) => {
    const controller = new AbortController();
    const response = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ANALYTICS}/api/analytic/blockInfo/${blockId}`,
        { signal: controller.signal }
      )
      .catch((error) => {
        if (error?.response !== null) {
          toast.error(error.response.data);
          setBlockInfo(null);
        }
      });

    if (response?.status === 200) setBlockInfo(response.data);
    return () => {
      toast.error("Error retrieving block info. Please try again later.");
      setBlockInfo(null);
      controller.abort();
    };
  };

  const getMostActiveLight = async (blockId) => {
    const controller = new AbortController();
    setMostActiveLightList([]);
    const response = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ANALYTICS}/api/analytic/activelight/${blockId}`,
        { signal: controller.signal }
      )
      .catch((err) => {
        toast.error(
          "Error retrieving Most Active Lights. Please try again later."
        );
        setMostActiveLightList([]);
        setMostActiveLightLoading(false);
      });

    if (response?.status === 200) {
      setMostActiveLightList(response.data.blockMotionCountRankList);
      setMostActiveLightLoading(false);
    }

    return () => {
      setMostActiveLightList([]);
      setMostActiveLightLoading(false);
      controller.abort();
    };
  };

  const getYesterdayEnergyConsumption = async (blockId) => {
    const controller = new AbortController();
    setYesterdayEnergyConsumption({});

    const response = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ANALYTICS}/api/analytic/yesterdayEnergy/${blockId}`,
        { signal: controller.signal }
      )
      .catch((err) => {
        toast.error(
          "Error retrieving Most Active Lights. Please try again later."
        );
        setYesterdayEnergyConsumption({});
      });

    if (response?.status === 200) setYesterdayEnergyConsumption(response?.data);

    return () => {
      setYesterdayEnergyConsumption({});
      controller.abort();
    };
  };

  return {
    lightBlockStatus,
    lightMaintenanceCount,
    lightConnectionCount,
    lightFaultChart,
    blockInfo,
    mostActiveLightList,
    isLoading,
    yesterdayEnergyConsumption,
  };
};

export default useBlockInfo;