const LightColumn = [
    {
      id: "displayName",
      label: "Display Name",
      isHeader: true
    },
    {
      id: "sensorId",
      label: "Sensor ID"
    },
    {
      id: "lightingControl",
      label: "Lighting Control"
    },
    {
      id: "lightStatus",
      label: "Light Status"
    },
    {
      id: "failSafeDelay",
      label: "Fail Safe Delay"
    },
    {
      id: "motionSensitivityStr",
      label: "Motion Sensitivity"
    },
    {
      id: "photoSensing",
      label: "Photo Sensing"
    },
    {
      id: "photoUpperThreshold",
      label: "Photo Upper Threshold (lux)"
    },
    {
      id: "photoLowerThreshold",
      label: "Photo Lower Threshold (lux)"
    },
    {
      id: "dimIntensity",
      label: "Dim Intensity (%)"
    },
    {
      id: "brightIntensity",
      label: "Bright Intensity (%)"
    },
    {
      id: "holdTime",
      label: "Hold Time (s)"
    },
    {
      id: "rampUpTime",
      label: "Ramp Up Time (s)"
    },
    {
      id: "rampDownTime",
      label: "Ramp Down Time (s)"
    },
    {
      id: "testControl",
      label: "Test Control"
    },
    {
      id: "startTime",
      label: "Start Time"
    },
    {
      id: "installDate",
      label: "Install Date"
    },
  ];

  export default LightColumn;