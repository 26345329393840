const timerColumn = [
  {
    key: "index",
    label: "ID",
    flexGrow: 1,
  },
  {
    key: "profileControl",
    label: "Profile Control",
    flexGrow: 2,
  },
  {
    key: "repeatAction",
    label: "Repeat Action",
    flexGrow: 1,
  },
  // {
  //   key: "numberOfProfiles",
  //   label: "Number of Profiles",
  //   flexGrow: 1,
  // },
  {
    key: "zoneId",
    label: "Zone ID",
    flexGrow: 1,
  },
  {
    key: "dayOfWeek",
    label: "Day of Week",
    flexGrow: 2,
  },
  {
    key: "startTime",
    label: "Start Time",
    flexGrow: 2,
  },
  {
    key: "endTime",
    label: "End Time",
    flexGrow: 2,
  },
  {
    key: "action",
    label: "Action",
    flexGrow: 2,
    fixed: "right",
  },
];

const dimmingColumn = [
  {
    key: "index",
    label: "ID",
    flexGrow: 1,
  },
  {
    key: "profileControl",
    label: "Profile Control",
    flexGrow: 2,
  },
  {
    key: "repeatAction",
    label: "Repeat Action",
    flexGrow: 1,
  },
  // {
  //   key: "numberOfProfiles",
  //   label: "Number of Profiles",
  //   flexGrow: 1,
  // },
  {
    key: "zoneId",
    label: "Zone ID",
    flexGrow: 1,
  },
  {
    key: "dayOfWeek",
    label: "Day of Week",
    flexGrow: 2,
  },
  {
    key: "startTime",
    label: "Start Time",
    flexGrow: 2,
  },
  {
    key: "endTime",
    label: "End Time",
    flexGrow: 2,
  },
  {
    key: "dimLevel",
    label: "Dim Level",
    flexGrow: 1,
  },
  {
    key: "brightLevel",
    label: "Bright Level",
    flexGrow: 1,
  },
];

const luminanceColumn = [
  {
    key: "profileControl",
    label: "Profile Control",
    flexGrow: 2,
  },
  {
    key: "luminanceLevel",
    label: "Luminance Level",
    flexGrow: 2,
  },
  {
    key: "startDate",
    label: "Start Date",
    flexGrow: 2,
  },
  {
    key: "endDate",
    label: "End Date",
    flexGrow: 2,
  },
];

const daylightColumn = [
  {
    key: "index",
    label: "ID",
    flexGrow: 1,
  },
  {
    key: "profileControl",
    label: "Profile Control",
    flexGrow: 2,
  },
  {
    key: "repeatAction",
    label: "Repeat Action",
    flexGrow: 1,
  },
  {
    key: "zoneId",
    label: "Zone ID",
    flexGrow: 1,
  },
  {
    key: "startTime",
    label: "Start Time",
    flexGrow: 1,
  },
  {
    key: "endTime",
    label: "End Time",
    flexGrow: 1,
  },
  {
    key: "photoSensor",
    label: "Photo Sensor",
    flexGrow: 1,
  },
  {
    key: "photoSensor2",
    label: "Photo Sensor 2",
    flexGrow: 1,
  },
  {
    key: "photoSensor3",
    label: "Photo Sensor 3",
    flexGrow: 1,
  },
  {
    key: "dimThreshold",
    label: "Dim Threshold",
    flexGrow: 2,
  },
  {
    key: "brightThreshold",
    label: "Bright Threshold",
    flexGrow: 2,
  },
  {
    key: "dimAction",
    label: "Dim Action",
    flexGrow: 2,
  },
  {
    key: "brightAction",
    label: "Bright Action",
    flexGrow: 2,
  },
];

export { timerColumn, dimmingColumn, luminanceColumn, daylightColumn };
