import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import ReadingCard from "Component/Shared/ReadingCard";
import useAxiosInstance from "hook/useAxiosInstance";

import {
  AppIndicator,
  Building,
  Lightbulb,
  Screwdriver,
  Router,
  LightningChargeFill,
} from "react-bootstrap-icons";

import CardBox from "../Shared/CardBox";
import StatusCard from "../Shared/StatusCard";
import Header from "../Shared/Header";
import Dropdown from "Component/Shared/Dropdown";
import Floorplan from "../Shared/Floorplan";
import useFloorPlan from "hook/useFloorPlan";
import useBlockInfo from "../../hook/useBlockInfo";
import useLightInfo from "../../hook/useLightInfo";

import {
  blockChangeHandler,
  floorChangeHandler,
  lightChangeHandler,
} from "state/entity/entityActions";

import "./Dashboard.css";

const Dashboard = () => {
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const [path, setPath] = useState();

  const {
    loading,
    communityList,
    blockList,
    floorList,
    lightList,
    selectedCommunity,
    selectedBlock,
    selectedFloor,
    selectedLight,
  } = useSelector((state) => state.entity);

  const {
    floorPlanUrl: floorPlanUrl,
    modalOpen: modalOpen,
    setModalOpen: setModalOpen,
  } = useFloorPlan(selectedFloor);

  // For configuration result
  const selectedBlockInfo = useBlockInfo(selectedBlock);
  const selectedLightInfo = useLightInfo(selectedLight);

  useEffect(() => {
    const communityName = communityList.find(
      (comm) => comm.communityId === selectedCommunity
    )?.communityName;

    const blockName = blockList.find(
      (block) => block.blockId === selectedBlock
    )?.blockName;

    setPath([communityName, blockName]);
  }, [communityList, blockList]);

  const blockChangeFn = (e) => {
    dispatch(blockChangeHandler(e.target.value));
  };

  const floorChangeFn = (e) => {
    dispatch(floorChangeHandler(e.target.value));
  };

  const getLightCurrentStatus = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/lightCurrentStatus/${selectedLight}`
    );

    if (response?.status === 200 && response?.data) toast.success("Submitted");
  };

  return (
    <React.Fragment>
      {floorPlanUrl && (
        <Floorplan
          floorplanOpen={modalOpen}
          closeFloorplan={setModalOpen}
          floorplanUrl={floorPlanUrl}
        />
      )}

      <Header title="Dashboard" path={path} className="dashboard">
        <Dropdown
          id="blockId"
          width="50%"
          size="small"
          value="blockName"
          label="Block"
          loading={loading}
          selected={selectedBlock}
          onChange={blockChangeFn}
          data={blockList}
        />

        <div className="master-container">
          <div className="status-card_container">
            <div className="col-md-4 status_container lightstatus">
              <StatusCard
                color="primary"
                icon={<Lightbulb />}
                title="Online Status"
                height="100%"
                footer={
                  <Link
                    className="link-style"
                    to={`/manage/light`}
                    state={{
                      currCommunityId: selectedCommunity,
                      currBlockId: selectedBlock,
                    }}
                  >
                    Click to view details
                  </Link>
                }
              >
                {selectedBlockInfo.isLoading ? (
                  <div className="d-flex justify-content-center my-4">
                    <Spinner animation="border" />
                  </div>
                ) : selectedBlockInfo?.lightBlockStatus ? (
                  <div style={{ fontSize: "120%" }}>
                    <ul>
                      <li>
                        {selectedBlockInfo?.lightBlockStatus?.turnedOn} Turned
                        On
                      </li>
                      <li>
                        {selectedBlockInfo?.lightBlockStatus?.turnedOff} Turned
                        Off
                      </li>
                      <li>
                        {selectedBlockInfo?.lightBlockStatus?.turnedAuto} Turned
                        Auto
                      </li>
                    </ul>
                  </div>
                ) : (
                  <span>No light summary info available.</span>
                )}
              </StatusCard>
            </div>
            <div className="col-md-4 status_container faults">
              <StatusCard
                color="warning"
                icon={<Screwdriver />}
                title="Maintenance Alert"
                height="100%"
                footer={
                  <Link
                    className="link-style"
                    to={`/manage/light`}
                    state={{
                      currCommunityId: selectedCommunity,
                      currBlockId: selectedBlock,
                    }}
                  >
                    Click to view details
                  </Link>
                }
              >
                {selectedBlockInfo?.isLoading ? (
                  <div className="d-flex justify-content-center my-4">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <h2 className="mx-2">
                    {selectedBlockInfo?.lightMaintenanceCount}
                  </h2>
                )}
              </StatusCard>
            </div>
            <div className="col-md-4 status_container activelights">
              <StatusCard
                color="danger"
                icon={<Router />}
                title="Network Connections"
                height="100%"
              >
                {selectedBlockInfo?.isLoading ? (
                  <div className="d-flex justify-content-center my-4">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <h2>
                    {selectedBlockInfo?.lightConnectionCount === 0
                      ? "0.00"
                      : selectedBlockInfo?.lightConnectionCount + "%"}
                  </h2>
                )}
              </StatusCard>
            </div>
          </div>

          <div className="status-card_container">
            <div className="col-md-4 status_container energy">
              <StatusCard
                color="dark"
                icon={<LightningChargeFill />}
                height="100%"
                title="Yesterday Energy Usage"
                footer={
                  <Link
                    className="link-style"
                    to={`/data/energy`}
                    state={{
                      tabValue: 1,
                    }}
                  >
                    View more analytics
                  </Link>
                }
              >
                {selectedBlockInfo.isLoading ? (
                  <div className="d-flex justify-content-center my-4">
                    <Spinner animation="border" />
                  </div>
                ) : selectedBlockInfo.blockInfo ? (
                  <div style={{ fontSize: "120%" }}>
                    <ul>
                      <li key={-4}>
                        {`Energy Usage: 
                          ${
                            Math.round(
                              selectedBlockInfo?.yesterdayEnergyConsumption
                                ?.totalEnergyUsage * 100
                            ) /
                              100 ===
                            0
                              ? `0.00`
                              : Math.round(
                                  selectedBlockInfo?.yesterdayEnergyConsumption
                                    ?.totalEnergyUsage * 100
                                ) / 100
                          }`}
                      </li>
                      <li key={-3}>
                        {`Energy Saved: ${
                          Math.round(
                            selectedBlockInfo?.yesterdayEnergyConsumption
                              ?.carbonEmission * 100
                          ) /
                            100 ===
                          0
                            ? `0.00`
                            : Math.round(
                                selectedBlockInfo?.yesterdayEnergyConsumption
                                  ?.carbonEmission * 100
                              ) / 100
                        }`}
                      </li>
                      <li key={-2}>
                        {`Cost Saving: ${
                          Math.round(
                            selectedBlockInfo?.yesterdayEnergyConsumption
                              ?.costSaving * 100
                          ) /
                            100 ===
                          0
                            ? `0.00`
                            : Math.round(
                                selectedBlockInfo?.yesterdayEnergyConsumption
                                  ?.costSaving * 100
                              ) / 100
                        } `}
                      </li>
                      <li key={-1}>
                        {`Carbon Emission: ${
                          Math.round(
                            selectedBlockInfo?.yesterdayEnergyConsumption
                              ?.carbonEmission * 100
                          ) /
                            100 ===
                          0
                            ? `0.00`
                            : Math.round(
                                selectedBlockInfo?.yesterdayEnergyConsumption
                                  ?.carbonEmission * 100
                              ) / 100
                        }`}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <span>No block info available.</span>
                )}
              </StatusCard>
            </div>
            <div className="col-md-4 status_container">
              <StatusCard
                color="success"
                icon={<Building />}
                height="100%"
                title="Block Summary"
                footer={
                  <Link
                    className="link-style"
                    to={`/entity`}
                    state={{
                      tabValue: 1,
                    }}
                  >
                    Click to view details
                  </Link>
                }
              >
                {selectedBlockInfo.isLoading ? (
                  <div className="d-flex justify-content-center my-4">
                    <Spinner animation="border" />
                  </div>
                ) : selectedBlockInfo.blockInfo ? (
                  <div style={{ fontSize: "120%" }}>
                    <ul>
                      <li key={-4}>
                        Postcode: {selectedBlockInfo?.blockInfo?.postcode}
                      </li>
                      <li key={-3}>
                        Number of floors:{" "}
                        {selectedBlockInfo?.blockInfo?.numFloors}
                      </li>
                      <li key={-2}>
                        Number of lights:{" "}
                        {selectedBlockInfo?.blockInfo?.numLights}
                      </li>
                      <li key={-1}>
                        Number of gateways:{" "}
                        {selectedBlockInfo?.blockInfo?.numGateWays}
                      </li>
                      {selectedBlockInfo?.blockInfo?.gateWayMacAdd &&
                        Object.entries(
                          selectedBlockInfo?.blockInfo?.gateWayMacAdd
                        ).map(([_, value], idx) => {
                          if (value) {
                            return (
                              <li style={{ textIndent: "20px" }} key={idx}>
                                {idx + 1 + "- " + value}
                              </li>
                            );
                          } else return null;
                        })}
                    </ul>
                  </div>
                ) : (
                  <span>No block info available.</span>
                )}
              </StatusCard>
            </div>
            <div className="col-md-4 status_container">
              <StatusCard
                color="secondary"
                icon={<AppIndicator />}
                title="Most active light"
                height="100%"
                footer={
                  <Link
                    className="link-style"
                    to={`/data/motion`}
                    state={{
                      currCommunityId: selectedCommunity,
                      currBlockId: selectedBlock,
                    }}
                  >
                    Click to view details
                  </Link>
                }
              >
                {selectedBlockInfo?.isLoading ? (
                  <div className="d-flex justify-content-center my-4 align-middle">
                    <Spinner animation="border" />
                  </div>
                ) : selectedBlockInfo?.mostActiveLightList &&
                  selectedBlockInfo?.mostActiveLightList?.length > 0 ? (
                  <div className="dashboard-card_most-active-lights">
                    {Object.entries(selectedBlockInfo.mostActiveLightList).map(
                      ([_, value], idx) => {
                        if (value) {
                          return (
                            <p key={idx}>
                              {value.lightName +
                                " - " +
                                value.numDetection +
                                " detections " +
                                " --> " +
                                value.reportDateTime}
                            </p>
                          );
                        } else return null;
                      }
                    )}
                  </div>
                ) : selectedBlockInfo?.mostActiveLightList?.length <= 0 ? (
                  <span>No information found.</span>
                ) : (
                  <span>Something went wrong.</span>
                )}
              </StatusCard>
            </div>
          </div>

          <div className="dashboard-light-status_container">
            <CardBox
              className="dashboard-light-status col-md-12"
              title="Light Information"
              icon={<Lightbulb />}
            >
              <div className="dashboard-light-control_body">
                {blockList && floorList && (
                  <div className="my-2 mb-3">
                    <Dropdown
                      id="floorId"
                      width="50%"
                      size="small"
                      value="floorName"
                      label="Floor"
                      loading={loading}
                      selected={selectedFloor}
                      onChange={floorChangeFn}
                      data={floorList}
                    />
                  </div>
                )}

                {blockList && floorList && lightList && (
                  <div className="my-2 mb-4">
                    <Dropdown
                      id="lightId"
                      width="50%"
                      size="small"
                      value="displayName"
                      label="Light"
                      loading={loading}
                      selected={selectedLight}
                      onChange={(e) =>
                        dispatch(lightChangeHandler(e.target.value))
                      }
                      data={lightList}
                    />
                  </div>
                )}

                <div className="small-card_container my-4">
                  <ReadingCard
                    title="Light Status"
                    loading={selectedLightInfo.liveLightStatusLoading}
                    reading={
                      selectedLightInfo.lightInformation?.lightStatus
                        ? selectedLightInfo.lightInformation?.lightStatus
                        : "-"
                    }
                    hasButton
                    onClick={getLightCurrentStatus}
                  />

                  <ReadingCard
                    title="Daylight Reading (lux)"
                    loading={selectedLightInfo.liveLightLuxLoading}
                    reading={
                      selectedLightInfo.lightInformation?.daylightReading
                        ? selectedLightInfo.lightInformation?.daylightReading
                        : "-"
                    }
                  />

                  <ReadingCard
                    title="Motion Reading (count)"
                    loading={selectedLightInfo.liveLightMotionLoading}
                    reading={
                      selectedLightInfo.lightInformation?.motionReading
                        ? selectedLightInfo.lightInformation?.motionReading
                        : "-"
                    }
                  />

                  <ReadingCard
                    title="Power Value (Wh)"
                    loading={selectedLightInfo.liveLightMotionLoading}
                    reading={
                      selectedLightInfo.lightInformation?.powerValue
                        ? selectedLightInfo.lightInformation?.powerValue
                        : "-"
                    }
                  />

                  <ReadingCard
                    title="Light Usage (hour)"
                    loading={selectedLightInfo.liveLightMotionLoading}
                    reading={
                      selectedLightInfo.lightInformation?.lightUsage
                        ? selectedLightInfo.lightInformation?.lightUsage
                        : "-"
                    }
                  />

                  <ReadingCard
                    title="Energy Usage (Wh)"
                    loading={selectedLightInfo.liveLightMotionLoading}
                    reading={
                      selectedLightInfo.lightInformation?.energyReading
                        ? selectedLightInfo.lightInformation?.energyReading
                        : "-"
                    }
                  />
                </div>
              </div>
            </CardBox>
          </div>
        </div>
      </Header>
    </React.Fragment>
  );
};

export default Dashboard;
