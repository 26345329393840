import React from "react";
import Logo from "../../images/AztechWhiteLogo.png";
import "./FullScreenOverlay.css";

const Overlay = ({ open, children, blur }) => {
  return (
    <div
      className={`overlay-wrapper ${blur ? "blur" : ""}`.trim()}
      hidden={!open}
    >
      {children}
    </div>
  );
};

const FullScreenOverlay = ({ open, children }) => {
  return (
    <Overlay open={open} blur={true}>
      <div className="d-flex justify-content-start">
        <img
          src={Logo}
          alt="Aztech Logo"
          width="200"
          style={{ margin: "1.5rem" }}
        />
      </div>
      <div id="fullscreen-overlay">{children}</div>
    </Overlay>
  );
};

export default FullScreenOverlay;