import { axiosInstance } from "services/api/axios";
import {
  SET_LOADING,
  SELECT_BLOCK,
  UPDATE_BLOCK,
  SELECT_COMMUNITY,
  UPDATE_COMMUNITY,
  SELECT_FLOOR,
  UPDATE_FLOOR,
  SELECT_LIGHT,
  UPDATE_LIGHT,
} from "./entitySlice";

export const fetchEntityData = () => {
  return async (dispatch) => {
    dispatch(SET_LOADING(true));

    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ADMIN}/api/admin/community/getCommunityByUserBlock`
      )
      .then((response) => {
        dispatch(UPDATE_COMMUNITY(response?.data));
        return axiosInstance.get(
          `${process.env.REACT_APP_API_ADMIN}/api/admin/block/blockAccess/${response?.data[0].communityId}`
        );
      })
      .then((response) => {
        dispatch(UPDATE_BLOCK(response?.data));
        return axiosInstance.get(
          `${process.env.REACT_APP_API_ADMIN}/api/admin/floor/block/${response?.data[0].blockId}`
        );
      })
      .then((response) => {
        dispatch(
          UPDATE_FLOOR(response?.data.sort((a, b) => a.floorNO - b.floorNO))
        );
        return axiosInstance.get(
          `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/floor/${response?.data[0].floorId}`
        );
      })
      .then((response) => {
        dispatch(UPDATE_LIGHT(response?.data));
      })
      .catch((err) => {});

    dispatch(SET_LOADING(false));
  };
};

export const communityChangeHandler = (id) => {
  return async (dispatch) => {
    dispatch(SET_LOADING(true));
    dispatch(SELECT_COMMUNITY(id));
    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ADMIN}/api/admin/block/blockAccess/${id}`
      )
      .then((response) => {
        dispatch(UPDATE_BLOCK(response?.data));
        return axiosInstance.get(
          `${process.env.REACT_APP_API_ADMIN}/api/admin/floor/block/${response?.data[0].blockId}`
        );
      })
      .then((response) => {
        dispatch(UPDATE_FLOOR(response?.data));
        return axiosInstance.get(
          `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/floor/${response?.data[0].floorId}`
        );
      })
      .then((response) => {
        dispatch(UPDATE_LIGHT(response?.data));
      })
      .catch((err) => {});
      dispatch(SET_LOADING(false));
  };
};

export const blockChangeHandler = (id) => {
  return async (dispatch) => {
    dispatch(SELECT_BLOCK(id));

    await axiosInstance
      .get(`${process.env.REACT_APP_API_ADMIN}/api/admin/floor/block/${id}`)
      .then((response) => {
        dispatch(UPDATE_FLOOR(response?.data));
        return axiosInstance.get(
          `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/floor/${response?.data[0].floorId}`
        );
      })
      .then((response) => {
        dispatch(UPDATE_LIGHT(response.data));
      })
      .catch((err) => {});
  };
};

export const floorChangeHandler = (id) => {
  return async (dispatch) => {
    dispatch(SELECT_FLOOR(id));

    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/floor/${id}`
      )
      .then((response) => {
        dispatch(UPDATE_LIGHT(response?.data));
      })
      .catch((err) => {});
  };
};

export const lightChangeHandler = (lightId) => {
  return async (dispatch) => {
    dispatch(SELECT_LIGHT(lightId));
  };
};

export const selectLightHandler = (lightId) => {
  return async (dispatch) => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/${lightId}`
      )
      .then((response) => {
        dispatch(SELECT_BLOCK(response?.data.blockId));
        floorChangeHandler(response?.data.floorId);
        lightChangeHandler(response?.data.lightId);
      })

      .catch((err) => {
        // console.log(err);
      });
  };
};