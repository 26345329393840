import { createSlice } from "@reduxjs/toolkit";

const entitySlice = createSlice({
  name: "entity",
  initialState: {
    communityList: [],
    selectedCommunity: "",
    blockList: [],
    selectedBlock: "",
    floorList: [],
    selectedFloor: "",
    lightList: [],
    selectedLight: "",
    loading: false,
  },
  reducers: {
    SET_LOADING: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    SELECT_COMMUNITY: (state, action) => {
      return {
        ...state,
        selectedCommunity: action.payload,
      };
    },
    UPDATE_COMMUNITY: (state, action) => {
      return {
        ...state,
        communityList: action.payload,
        selectedCommunity: action.payload[0].communityId,
      };
    },
    SELECT_BLOCK: (state, action) => {
        return {
          ...state,
          selectedBlock: action.payload,
        };
      },
    UPDATE_BLOCK: (state, action) => {
      return {
        ...state,
        blockList: action.payload,
        selectedBlock: action.payload[0].blockId,
      };
    },
    SELECT_FLOOR: (state, action) => {
        return {
          ...state,
          selectedFloor: action.payload,
        };
      },
    UPDATE_FLOOR: (state, action) => {
      return {
        ...state,
        floorList: action.payload,
        selectedFloor: action.payload[0].floorId,
      };
    },
    SELECT_LIGHT: (state, action) => {
        return {
          ...state,
          selectedLight: action.payload,
        };
      },
    UPDATE_LIGHT: (state, action) => {
      return {
        ...state,
        lightList: action.payload,
        selectedLight: action.payload[0].lightId,
      };
    },
  },
});

export const {
  SET_LOADING,
  SELECT_BLOCK,
  UPDATE_BLOCK,
  SELECT_COMMUNITY,
  UPDATE_COMMUNITY,
  SELECT_FLOOR,
  UPDATE_FLOOR,
  SELECT_LIGHT,
  UPDATE_LIGHT,
} = entitySlice.actions;

export const {
  communityList,
  selectedCommunity,
  blockList,
  selectedBlock,
  floorList,
  selectedFloor,
  lightList,
  selectedLight,
  loading
} = (state) => state.entity;

export default entitySlice.reducer;
