const { createSlice } = require("@reduxjs/toolkit");

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notificationList: [],
    selectedNotification: {},
  },
  reducers: {
    SET_NOTIFICATION: (state, action) => {
      return {
        ...state,
        notificationList: action.payload,
      };
    },
    UPDATE_NOTIFICATION: (state, action) => {
      const newFault = state.notificationList.find(
        (fault) => fault.faultId === action.payload
      );

      return {
        ...state,
        notificationList: [
          ...state.notificationList.filter(
            (fault) => fault.faultId !== action.payload
          ),
          { ...newFault, isRead: true },
        ].sort(
          (a, b) => new Date(b.reportDateTime) - new Date(a.reportDateTime)
        ),
        // selectedNotification: newFault,
      };
    },
    SELECT_NOTIFICATION: (state, action) => {
      const newFault = state.notificationList.find(
        (fault) => fault.faultId === action.payload
      );
      return {
        ...state,
        selectedNotification: newFault,
      };
    },
    CLEAR_NOTIFICATION: (state) => {
      return {
        ...state,
        notificationList: [
          ...state.notificationList
            .map((fault) => (fault = { ...fault, isRead: 1 }))
            .sort(
              (a, b) => new Date(b.reportDateTime) - new Date(a.reportDateTime)
            ),
        ],
      };
    },
    CLEAR_SELECTED: (state) => {
      return {
        ...state,
        selectedNotification: {},
      };
    },
  },
});

export const {
  SET_NOTIFICATION,
  UPDATE_NOTIFICATION,
  SELECT_NOTIFICATION,
  CLEAR_NOTIFICATION,
  CLEAR_SELECTED,
} = notificationSlice.actions;

export const {
  notificationList,
  selectedNotification,
} = (state) => state.notification;

export default notificationSlice.reducer;
