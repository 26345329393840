import React from "react";

import ProtectedRoute from "../Shared/ProtectedRoute";
import Dashboard from "../Dashboard/Dashboard";
import CommunityDashboard from "../Dashboard/CommunityDashboard";
import LightStatus from "../LightMgmt/LightStatus";
import PageNotFound from "../Exception/PageNotFound";
import LightInfo from "Component/LightMgmt/LightInfo";
import Fault from "../EventMgmt/Fault";
import Event from "../EventMgmt/Event";

const EditProfile = React.lazy(() => import("../UserMgmt/EditProfile"));
const ForgetPassword = React.lazy(() => import("../Login/ForgetPassword"));
const MotionDataChart = React.lazy(() =>
  import("../DataAnalytics/MotionDataChart")
);
const PhotosensorChart = React.lazy(() =>
  import("../DataAnalytics/PhotosensorChart")
);
const EnergyChart = React.lazy(() => import("../DataAnalytics/EnergyChart"));
const AccessManagement = React.lazy(() =>
  import("../AccessMgmt/AccessManagement")
);
const ManageNewEntity = React.lazy(() =>
  import("../EntityMgmt/ManageNewEntity")
);
const BatchUpload = React.lazy(() => import("../DataMgmt/BatchUpload"));
const FirmwareUpgrade = React.lazy(() =>
  import("../FirmwareUpgrade/FirmwareUpgrade")
);
const Configuration = React.lazy(() =>
  import("../Configuration/Configuration")
);
const UserMgmt = React.lazy(() => import("../UserMgmt/UserMgmt"));

const routes = [
  {
    path: "/map",
    exact: true,
    element: (
      <ProtectedRoute>
        <CommunityDashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard",
    exact: true,
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/manage/light",
    exact: true,
    element: (
      <ProtectedRoute>
        <LightStatus />
      </ProtectedRoute>
    ),
  },
  {
    path: "/manage/light/configuration",
    exact: true,
    element: (
      <ProtectedRoute>
        <LightInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: "/configuration",
    exact: true,
    element: (
      <ProtectedRoute>
        <Configuration />
      </ProtectedRoute>
    ),
  },
  {
    path: "/data/motion",
    exact: true,
    element: (
      <ProtectedRoute>
        <MotionDataChart />
      </ProtectedRoute>
    ),
  },
  {
    path: "/data/photosensor",
    exact: true,
    element: (
      <ProtectedRoute>
        <PhotosensorChart />
      </ProtectedRoute>
    ),
  },
  {
    path: "/data/energy",
    exact: true,
    element: (
      <ProtectedRoute>
        <EnergyChart />
      </ProtectedRoute>
    ),
  },
  {
    path: "/user/edit",
    exact: true,
    element: (
      <ProtectedRoute>
        <EditProfile />
      </ProtectedRoute>
    ),
  },
  {
    path: "/manage/user",
    exact: true,
    element: (
      <ProtectedRoute>
        <UserMgmt />
      </ProtectedRoute>
    ),
  },
  {
    path: "/upload",
    exact: true,
    element: (
      <ProtectedRoute>
        <BatchUpload />
      </ProtectedRoute>
    ),
  },
  {
    path: "/firmware",
    exact: true,
    element: (
      <ProtectedRoute>
        <FirmwareUpgrade />
      </ProtectedRoute>
    ),
  },
  {
    path: "/entity",
    exact: true,
    element: (
      <ProtectedRoute>
        <ManageNewEntity />
      </ProtectedRoute>
    ),
  },
  {
    path: "/block/access",
    exact: true,
    element: (
      <ProtectedRoute>
        <AccessManagement />
      </ProtectedRoute>
    ),
  },
  {
    path: "/logs/fault",
    exact: true,
    element: (
      <ProtectedRoute>
        <Fault />
      </ProtectedRoute>
    ),
  },
  {
    path: "/logs/event",
    exact: true,
    element: (
      <ProtectedRoute>
        <Event />
      </ProtectedRoute>
    ),
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];

export default routes;