import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

const EventRow = ({ row, idx, onRowClick }) => {
  return (
    <TableRow
      key={idx}
      onClick={onRowClick}
      data-custom={JSON.stringify(row)}
      tabIndex={-1}
      hover
      sx={{ cursor: "pointer" }}
    >
      <TableCell component="th" scope="row" align="center">
        {row.createdDate}
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        <div>{`${row.lightName}`}</div>
        <div>{`${row.sensorId}`}</div>
      </TableCell>
      <TableCell align="center">
        <div>{`${row.eventType}`}</div>
      </TableCell>
      <TableCell align="center">
        {row.eventStatus === "Success" ? (
          <CheckCircleIcon color="success" />
        ) : row.eventStatus === "Failed" ? (
          <CancelIcon color="error" />
        ) : (
          <AccessTimeFilledIcon color="action" />
        )}
      </TableCell>
      <TableCell align="center">{row.performedBy}</TableCell>
    </TableRow>
  );
};

export default EventRow;