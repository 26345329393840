import React from "react";
import { Table } from "rsuite";

const CustomTable = (props) => {
  const { HeaderCell, Cell, Column } = Table;

  return (
    <Table
      autoHeight
      wordWrap
      bordered
      minHeight={props.minHeight || 200}
      headerHeight={props.headerHeight || 55}
      data={props.data}
      loading={props.isLoading}
      onRowClick={props.onRowClick || null}
    >
      {props.columns.map((column) => {
        const { key, label, ...rest } = column;
        return (
          <Column {...rest} key={key}>
            <HeaderCell>{label}</HeaderCell>
            <Cell dataKey={key} />
          </Column>
        );
      })}

      {props.children}
    </Table>
  );
};

export default CustomTable;
