import * as React from "react";import { useState, useEffect, useMemo, useCallback } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";

const FaultRow = ({ row, idx, onRowClick, expand }) => {
  //can pass in an expand true/false to hide certain columns. Used for mobile/sx view
  return (
    <TableRow
      key={idx}
      onClick={onRowClick}
      data-custom={JSON.stringify(row)}
      tabIndex={-1}
      hover
      sx={{ cursor: "pointer" }}
    >
      <TableCell component="th" align="center">
        <div>{`${row.lightDisplayName}`}</div>
        <div style={{ display: expand ? 'block' : 'none' }}>{`${row.sensorId}`}</div>
      </TableCell>
      <TableCell align="center">{row.eventType}</TableCell>
      <TableCell align="center">
        <div>{`${row.sensorStatus}`}</div>
      </TableCell>
      <TableCell align="center">{row.severity}</TableCell>
      <TableCell align="center">{row.reportDateTime}</TableCell>
      <TableCell align="center">
        {row.clearFaultStatus === "Pending" ? (
          <Button
            size="small"
            variant="contained"
            color="warning"
            className="mx-2"
            style={{ textTransform: "none" }}
          >
            Pending
          </Button>
        ) : (
          <Button
            size="small"
            variant="contained"
            color="success"
            style={{ textTransform: "none" }}
          >
            Cleared
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default FaultRow;