const EventColumn = [
  {
    id: "createdDate",
    label: "Date Time",
    isHeader: true,
  },
  {
    id: "lightName",
    label: "Sensor ID",
  },
  {
    id: "eventType",
    label: "Event",
  },
  {
    id: "eventStatus",
    label: "Status",
  },
  {
    id: "performedBy",
    label: "User",
  },
];

export default EventColumn;