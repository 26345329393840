import { useState, useEffect, useContext } from "react";
import useAxiosInstance from "hook/useAxiosInstance";

import { toast } from "react-toastify";
import { CollectorSocketContext } from "context/collector-socket-context";
import { useCallback } from "react";

const useLightInfo = (lightId) => {
  const axiosInstance = useAxiosInstance();
  const [isLoading, setLoading] = useState(false);
  const [lightInformation, setLightInformation] = useState();
  const [liveLightLuxLoading, setLiveLightLuxLoading] = useState(false);
  const [liveLightStatusLoading, setLiveLightStatusLoading] = useState(false);
  const [liveLightMotionLoading, setLiveLightMotionLoading] = useState(false);

  const { subscribe, unsubscribe, isConnected } = useContext(
    CollectorSocketContext
  );

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    const getLightInformation = async (lightId) => {
      const response = await axiosInstance
        .get(
          `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/lightInfo/${lightId}`,
          {
            signal: controller.signal,
          }
        )
        .catch((error) => {
          if (error?.response) {
            toast.error(error.response.data);
            setLightInformation(null);
          }
        });

      if (response?.status === 200) setLightInformation(response?.data);
      setLoading(false);
    };

    if (lightId) getLightInformation(lightId);
    return () => {
      setLightInformation(null);
      controller.abort();
    };
  }, [lightId]);

  useEffect(() => {
    if (isConnected && lightId) {
      var statusSubs = subscribe("/status/light", getLightStatusMessage);
      var photoSubs = subscribe("/status/lux", getLuxMessage);
      var motionSubs = subscribe("/status/motion", getMotionMessage);
      var energySubs = subscribe("/status/energy", getLightEnergyReading);
      var powerSubs = subscribe("/status/power", getLightPowerReading);
    }

    return () => {
      unsubscribe(statusSubs);
      unsubscribe(photoSubs);
      unsubscribe(motionSubs);
      unsubscribe(energySubs);
      unsubscribe(powerSubs);
    };
  }, [isConnected, lightId]);

  const getLightStatusMessage = useCallback((data) => {
    const parsedData = JSON.parse(data);
    if (parsedData?.lightId == lightId) {
      setLiveLightStatusLoading(true);
      setLightInformation((prevState) => {
        return {
          ...prevState,
          lightStatus: {
            lightStatus: parsedData?.lightStatus,
            reportDateTime: parsedData?.dateTime,
          },
        };
      });
    }
    setTimeout(() => {
      setLiveLightStatusLoading(false);
    }, [1000]);
  }, [lightId]);

  const getLuxMessage = (data) => {
    const parsedData = JSON.parse(data);
    if (parsedData?.lightId == lightId) {
      setLiveLightLuxLoading(true);
      setLightInformation((prevState) => {
        return {
          ...prevState,
          daylightReading: {
            daylightValue: parsedData?.luxReading,
            reportDateTime: parsedData?.time,
          },
        };
      });
      setTimeout(() => {
        setLiveLightLuxLoading(false);
      }, [2000]);
    }
  };

  const getMotionMessage = (data) => {
    const parsedData = JSON.parse(data);
    if (parsedData?.lightId == lightId) {
      setLiveLightMotionLoading(true);
      setLightInformation((prevState) => {
        return {
          ...prevState,
          motionReading: {
            motionCount: parsedData?.motionCount,
            reportDateTime: parsedData?.time,
          },
        };
      });

      setTimeout(() => {
        setLiveLightMotionLoading(false);
      }, [2000]);
    }
  };

  const getLightEnergyReading = (data) => {
    const parsedData = JSON.parse(data);
    if (parsedData?.lightId == lightId) {
      setLightInformation((prevState) => {
        return {
          ...prevState,
          energyReading: {
            energyUsage: Math.floor(parsedData?.energyReading),
            reportDateTime: parsedData?.time,
          },
        };
      });
    }
  };

  const getLightPowerReading = (data) => {
    const parsedData = JSON.parse(data);
    if (parsedData?.lightId == lightId) {
      setLightInformation((prevState) => {
        return {
          ...prevState,
          powerValue: {
            powerValue: Math.floor(parsedData?.powerValue),
            reportDateTime: parsedData?.time,
          },
        };
      });
    }
  };

  return {
    isLoading,
    lightInformation,
    liveLightStatusLoading,
    liveLightLuxLoading,
    liveLightMotionLoading,
  };
};

export default useLightInfo;
