import React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CircularProgress } from "@mui/material";

const Dropdown = (props) => {
  return (
    <React.Fragment>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label" size="small">
          {props.label}
        </InputLabel>
        <Select
          id="demo-simple-select"
          labelId="demo-simple-select-label"
          style={{ width: `${props.width || "50%"}` }}
          label={props.label}
          size={props.size}
          value={props?.selected || -1}
          onChange={props.onChange}
        >
          <MenuItem dense value={-1}>{`-Select ${props.label}-`}</MenuItem>
          {props?.data?.length > 0 &&
            props.data?.map((item) => {
              return (
                <MenuItem
                  dense
                  key={item[props.id]}
                  id={item[props.id]}
                  value={item[props.id]}
                >
                  {props.loading ? (
                    <CircularProgress size={20} />
                  ) : item ? (
                    item[props.value]
                  ) : (
                    "No data found."
                  )}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default Dropdown;
