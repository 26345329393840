import React, { useEffect, useState, useReducer, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import { CircleFill, QuestionCircle } from "react-bootstrap-icons";
import { validationSchema } from "./FormValidation";
import { Form, Row, Col } from "react-bootstrap";
import {
  Button,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, DatePicker, Toggle } from "rsuite";
import { toast } from "react-toastify";
import "rsuite/dist/rsuite.min.css";

import Header from "Component/Shared/Header";
import CustomTable from "Component/Shared/CustomTable";
import ModalOverlay from "Component/Shared/ModalOverlay";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { luminanceColumn } from "Component/LightMgmt/ProfileColumn";
import ProfileTimeline from "Component/Shared/ProfileTimeline";
import TimerForm from "./ProfileForm/TimerForm";
import LuminanceForm from "./ProfileForm/LuminanceForm";
import DaylightForm from "./ProfileForm/DaylightForm";
import DimmingForm from "./ProfileForm/DimmingForm";
import StatusChecklist from "Component/Shared/StatusChecklist";
import FullScreenOverlay from "Component/Shared/FullScreenOverlay";
import { profileReducers } from "./ProfileReducer";
import useAxiosInstance from "hook/useAxiosInstance";
import { SmartlightSocketContext } from "context/smartlight-socket-context";
import { CollectorSocketContext } from "context/collector-socket-context";
import { fetchEntityData } from "state/entity/entityActions";

import "./LightInfo.css";

import { ExpandMore } from "@mui/icons-material";
import InputSlider from "Component/Shared/SliderWithTextBox";

const modalReducer = (state, action) => {
  switch (action.type) {
    case "CREATE_MODAL":
      return { ...state, create: action.payload };
    case "UPDATE_MODAL":
      return { ...state, update: action.payload };
    case "UPDATE_LIGHT_MODAL":
      return { ...state, update_light: action.payload };
    case "DELETE_LIGHT_MODAL":
      return { ...state, delete_light: action.payload };
    case "DELETE_PROFILE_MODAL":
      return { ...state, delete_profile: action.payload };
    case "ACTIVATE_TEST_MODAL":
      return { ...state, activate_test: action.payload };
    case "APPLY_MODAL":
      return { ...state, apply: action.payload };
    case "CLEAR_MODAL":
      return { ...state, clear: action.payload };
    case "DISABLE_MODAL":
      return { ...state, disable: action.payload };
    case "SELECT_PROFILE":
      return { ...state, selected: action.payload };
    case "CLEAR_PROFILE":
      return { ...state, selected: {} };
    case "UPDATE_TIME_MODAL":
      return { ...state, update_time: action.payload };
    case "MSP_LIGHT_UPGRADE":
      return {...state, MSP_LIGHT_UPGRADE: action.payload};
    case "WISUN_LIGHT_UPGRADE":
      return {...state, WISUN_LIGHT_UPGRADE: action.payload};
    case "CLOSE_MODAL":
      return {
        ...state,
        create: false,
        update: false,
        update_light: false,
        delete: false,
        delete_light: false,
        delete_profile: false,
        clear: false,
        disable: false,
        selected: {},
        update_time: false,
        MSP_LIGHT_UPGRADE: false,
        WISUN_LIGHT_UPGRADE: false,
      };
    default:
      return state;
  }
};

const LightInfo = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const role = useSelector((state) => state.auth.role);
  const userId = useSelector((state) => state.auth.userId);

  const labels = ["Low", "Medium", "High"];
  const [onStartup, setOnStartUp] = useState(true);
  const [testControl, setTestControl] = useState(false);
  const [photoSensing, setPhotoSensing] = useState(false);

  const [path, setPath] = useState();
  const [isConfig, setIsConfig] = useState(false);

  const [userBlockList, setUserBlockList] = useState([]);
  const [userFloorList, setUserFloorList] = useState([]);
  const [userGatewayList, setUserGatewayList] = useState([]);
  const [currentLight, setCurrentLight] = useState({});

  const [initialProfiles, setInitialProfiles] = useState([]);

  const [statusTitle, setStatusTitle] = useState(
    "Awaiting response from server..."
  );
  const [configCompleted, setConfigCompleted] = useState(true);
  // const [configCount, setConfigCount] = useState(0);
  const [failedEvent, setFailedEvent] = useState([]);
  const [configEvent, setConfigEvent] = useState([]);
  const [retryAttempt, setRetryAttempt] = useState(false);
  const [timeoutId, setTimeoutId] = useState(0);

  //For dynamic profile viewing
  const [disableCRUD, setDisableCRUD] = useState(false);
  const [timelineData, setTimelineData] = useState([]);
  const [weekendTimelineData, setWeekendTimelineData] = useState([]);

  const {
    subscribe: responseSubscribe,
    isConnected: responseConnected,
    cleanup: responseCleanup,
  } = useContext(CollectorSocketContext);

  const {
    subscribe: requestSubscribe,
    isConnected: requestConnected,
    cleanup: requestCleanup,
  } = useContext(SmartlightSocketContext);

  const [profileState, profileDispatch] = useReducer(profileReducers, {
    timer: [],
    show_timer: false,
    daylight: [],
    show_daylight: false,
    dimming: [],
    show_dimming: false,
    luminance: [],
    show_luminance: false,
    toggle: false,
    count: 0,
  });

  const [modalState, modalDispatch] = useReducer(modalReducer, {
    create: false,
    update: false,
    update_light: false,
    delete: false,
    delete_light: false,
    clear: false,
    disable: false,
    selected: null,
    update_time: false,
    MSP_LIGHT_UPGRADE: false,
    WISUN_LIGHT_UPGRADE: false,
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      lightId: "",
      userId: "",
      sensorId: "",
      gatewayId: "",
      blockId: "",
      floorId: "",
      displayName: "",
      lightSN: "",
      lightMACAddress: "",
      lightIPAddress: "",
      lightingControl: "",
      failSafeDelay: "",
      holdTime: "",
      rampUpTime: "",
      rampDownTime: "",
      dimIntensity: "",
      brightIntensity: "",
      photoSensing: "",
      photoUpperThreshold: "",
      photoLowerThreshold: "",
      motionSensitivity: "",
      installDate: "",
      lightStatus: "",
      testControl: "",
      startTime: "",
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const abortController = new AbortController();
    if (!location?.state?.lightId) navigate("/manage/light");
    else {
      let light = {};
      axiosInstance
        .get(
          `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/${location.state.lightId}`
        )
        .then((response) => {
          light = response.data;
          reset(response.data);
          setCurrentLight(response.data);
          setTestControl(response.data.testControl === "Enable");
          setPhotoSensing(response.data.photoSensing === "Enable");
          getAllProfilesFromDB(response.data.lightId);
          setPath([
            <Link to="/manage/light">Light Info</Link>,
            response.data.sensorId,
          ]);
          return axiosInstance.get(
            `${process.env.REACT_APP_API_ADMIN}/api/admin/block/blockAccess`,
            { signal: abortController.signal }
          );
        })
        .then((response) => {
          setUserBlockList(response.data);
          setValue("blockId", light.blockId);
          return axiosInstance.get(
            `${process.env.REACT_APP_API_ADMIN}/api/admin/floor/block/${light.blockId}`,
            { signal: abortController.signal }
          );
        })
        .then((response) => {
          setUserFloorList(response.data);
          setValue("floorId", light.floorId);
          return axiosInstance.get(
            `${process.env.REACT_APP_API_ADMIN}/api/admin/gateway/block/${light.blockId}`,
            { signal: abortController.signal }
          );
        })
        .then((response) => {
          setUserGatewayList(response.data);
          setValue("gatewayId", light.gatewayId);
        })
        .catch(function (err) {
          toast.error("Failed retrieving block data.");
          setUserGatewayList([]);
        });
    }
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    // console.log("Failed event: ");
    // console.log(failedEvent);
    // console.log("Config event: ");
    // console.log(configEvent);
    // console.log(
    //   !configEvent.some(
    //     (obj) => obj.commandType !== "Set_Ack" || obj.commandType === "Set"
    //   )
    // );
    // any one failed
    if (failedEvent?.length > 0) {
      setStatusTitle("One or more events failed.");
      setRetryAttempt(true);
      clearTimeout(timeoutId);
      // all success
    } else if (
      failedEvent?.length === 0 &&
      configEvent?.length > 0 &&
      !configEvent.some(
        (obj) => obj.commandType === "Set_Error" || obj.commandType === "Set"
      )
    ) {
      setStatusTitle("Completed");
      setRetryAttempt(false);
      clearTimeout(timeoutId);
    }
  }, [configEvent, failedEvent, timeoutId]);

  const getAllProfilesFromDB = (lightId) => {
    var timelineData = [];

    const getLightingProfiles = async () => {
      let timer = [];
      let daylight = [];
      let dimming = [];
      let luminance = [];

      const response = await axiosInstance
        .get(
          `${process.env.REACT_APP_API_SMARTLIGHT}/api/profile/get/${lightId}`
        )
        .catch((error) => profileDispatch({ type: "CLEAR_DEFAULT" }));
      if (response?.status === 200) {
        let result = response.data;
        for (var i = 0; i < result.length; i++) {
          if (result[i]?.group === "Timer") timer.push(result[i]);
          else if (result[i]?.group === "Daylight") daylight.push(result[i]);
          else if (result[i]?.group === "Dimming") dimming.push(result[i]);
          else luminance.push(result[i]);

          if (result[i]?.group !== "Luminance")
            addTimelineAttributes(result[i], timelineData);
        }
        profileDispatch({
          type: "SET_TIMER_PROFILE",
          payload: timer,
        });

        profileDispatch({
          type: "SET_DAYLIGHT_PROFILE",
          payload: daylight,
        });

        profileDispatch({
          type: "SET_DIMMING_PROFILE",
          payload: dimming,
        });

        profileDispatch({
          type: "SET_LUMINANCE_PROFILE",
          payload: luminance,
        });
      }
    };

    Promise.resolve(getLightingProfiles()).then((values) => {
      setInitialProfiles(timelineData);
      generateTimelineItem(timelineData);
      setDisableCRUD(false);
    });
  };

  const blockChangeHandler = async (e) => {
    setValue("blockId", e.target.value, { shouldValidate: true });

    const floorResponse = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ADMIN}/api/admin/floor/block/${e.target.value}`
      )
      .catch((err) => {
        toast.error("Failed retrieving floor(s).");
        setUserFloorList([]);
      });

    if (floorResponse)
      if (floorResponse.status === 200) setUserFloorList(floorResponse.data);

    const gatewayResponse = await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ADMIN}/api/admin/gateway/block/${e.target.value}`
      )
      .catch((err) => {
        toast.error("Failed retrieving gateway(s).");
        setUserGatewayList([]);
      });

    if (gatewayResponse?.status === 200)
      setUserGatewayList(gatewayResponse.data);

    let block = userBlockList.find((item) => {
      return item.blockId == document.getElementById("blockId").value;
    });
    setValue(
      "sensorId",
      "SmartLighting-Aztech-" +
        block.postcode +
        "-" +
        document.getElementById("lightMACAddress").value,
      {
        shouldValidate: true,
      }
    );
  };

  const generateTimelineItem = (timelineData) => {
    let weekdayTimelineData = [];
    let weekendTimelineData = [];
    let timelineObj = {};

    timelineData.forEach((item, idx) => {
      timelineObj = generateTimelineBody(item, idx + 1);

      if (item.group !== "Daylight" && item.group !== "Luminance") {
        if (item?.dayOfWeek == "Weekday") weekdayTimelineData.push(timelineObj);
        else if (item?.dayOfWeek == "Weekend")
          weekendTimelineData.push(timelineObj);
        else {
          weekdayTimelineData.push(timelineObj);
          weekendTimelineData.push(timelineObj);
        }
      } else {
        weekdayTimelineData.push(timelineObj);
        weekendTimelineData.push(timelineObj);
      }
    });
    setTimelineData(weekdayTimelineData);
    setWeekendTimelineData(weekendTimelineData);
  };

  const generateTimelineBody = (item, id, status) => {
    return {
      id: id,
      group: item.group,
      start:
        item.group !== "Luminance"
          ? Date.parse(getDateFromHours(item.startTime, "start"))
          : Date.parse(convertFromStringToDate(item.startDate)),
      end:
        item.group !== "Luminance"
          ? Date.parse(getDateFromHours(item.endTime, "end"))
          : Date.parse(convertFromStringToDate(item.endDate)),
      itemId: id,
      title:
        item.group === "Dimming"
          ? "Dim: " + item.dimLevel + " | Bright: " + item.brightLevel
          : item.group === "Timer"
          ? "Action: " + item.action
          : item.group === "Luminance"
          ? "Luminance: " + item.luminanceLevel
          : "Dim: " +
            item.dimAction +
            "[" +
            item.dimThreshold +
            "], Bright: " +
            item.brightAction +
            "[" +
            item.brightThreshold +
            "]",
      itemProps: {
        style: {
          color: "white !important",
          background:
            status === "Pending"
              ? "#989898"
              : item.profileControl === "Disable"
              ? "#696969"
              : item.group === "Timer"
              ? "#FFE0AE"
              : item.group === "Daylight"
              ? "#F5631A"
              : item.group === "Dimming"
              ? "#8CA0ED"
              : "#FFA303",
        },
      },
    };
  };

  const addTimelineData = (profile) => {
    let timelineObj = generateTimelineBody(
      profile,
      profile.profileId,
      "Pending"
    );

    if (
      profile.dayOfWeek === "Everyday" ||
      profile.dayOfWeek === "Weekday" ||
      profile.group === "Daylight"
    )
      setTimelineData((prevState) => [...prevState, timelineObj]);

    if (
      profile.dayOfWeek === "Everyday" ||
      profile.dayOfWeek === "Weekend" ||
      profile.group === "Daylight"
    )
      setWeekendTimelineData((prevState) => [...prevState, timelineObj]);
  };

  const updateTimelineData = (profile) => {
    let newProfileTimelineObj = generateTimelineBody(
      profile,
      profile?.profileId,
      "Pending"
    );

    if (profile?.dayOfWeek === "Weekday") {
      setTimelineData((prevState) => [
        ...prevState.filter((item) => item.itemId !== profile.profileId),
        newProfileTimelineObj,
      ]);

      setWeekendTimelineData((prevState) =>
        prevState.filter((item) => item.itemId !== profile.profileId)
      );
    } else if (profile?.dayOfWeek === "Weekend") {
      setTimelineData((prevState) =>
        prevState.filter((item) => item.itemId !== profile.profileId)
      );

      setWeekendTimelineData((prevState) => [
        ...prevState.filter((item) => item.itemId !== profile.profileId),
        newProfileTimelineObj,
      ]);
    } else {
      setTimelineData((prevState) => [
        ...prevState.filter((item) => item.itemId !== profile.profileId),
        newProfileTimelineObj,
      ]);

      setWeekendTimelineData((prevState) => [
        ...prevState.filter((item) => item.itemId !== profile.profileId),
        newProfileTimelineObj,
      ]);
    }
  };

  const disableTimelineData = (group) => {
    setTimelineData((prevState) => [
      ...prevState.map((item) => {
        if (group && group === item.group) {
          return {
            ...item,
            itemProps: {
              style: {
                background: "#696969",
              },
            },
          };
        } else if (!group) {
          return {
            ...item,
            itemProps: {
              style: {
                background: "#696969",
              },
            },
          };
        } else return item;
      }),
    ]);

    setWeekendTimelineData((prevState) => [
      ...prevState.map((item) => {
        if (group && group === item.group) {
          return {
            ...item,
            itemProps: {
              style: {
                background: "#696969",
              },
            },
          };
        } else if (!group) {
          return {
            ...item,
            itemProps: {
              style: {
                background: "#696969",
              },
            },
          };
        } else return item;
      }),
    ]);
  };

  const addTimelineAttributes = (item, res) => {
    if (item.group !== "Luminance") {
      item.start = Date.parse(getDateFromHours(item.startTime, "start"));
      item.end = Date.parse(getDateFromHours(item.endTime, "end"));
    } else {
      item.start = convertFromStringToDateFrontEnd(item.startDate).setHours(
        0,
        0,
        0
      );
      item.end = convertFromStringToDateFrontEnd(item.endDate).setHours(
        23,
        59,
        59
      );
    }
    res.push(item);
  };

  //This method handles the selection of item for both weekday and weekend - through additional parameter - dayOfWeek
  const handleItemSelectForBoth = (itemId, _, time, dayOfWeek) => {
    var item =
      dayOfWeek === "Weekday"
        ? timelineData.find((data) => data.itemId === itemId)
        : weekendTimelineData.find((data) => data.itemId === itemId);

    if (item.group === "Timer") {
      modalDispatch({
        type: "SELECT_PROFILE",
        payload: {
          ...profileState.timer.find((data) => data.profileId === item.itemId),
          group: item.group,
        },
      });

      profileDispatch({ type: "SHOW_TIMER_PROFILE" });
    } else if (item.group === "Luminance") {
      modalDispatch({
        type: "SELECT_PROFILE",
        payload: {
          ...profileState.luminance.find(
            (data) => data.profileId === item.itemId
          ),
          group: item.group,
        },
      });

      profileDispatch({ type: "SHOW_LUMINANCE_PROFILE" });
    } else if (item.group === "Daylight") {
      modalDispatch({
        type: "SELECT_PROFILE",
        payload: {
          ...profileState.daylight.find(
            (data) => data.profileId === item.itemId
          ),
          group: item.group,
        },
      });

      profileDispatch({ type: "SHOW_DAYLIGHT_PROFILE" });
    } else if (item.group === "Dimming") {
      modalDispatch({
        type: "SELECT_PROFILE",
        payload: {
          ...profileState.dimming.find((data) => data.profileId == item.itemId),
          group: item.group,
        },
      });
      profileDispatch({ type: "SHOW_DIMMING_PROFILE" });
    }
    modalDispatch({ type: "UPDATE_MODAL", payload: true });
  };

  const handleLumiTableRowClick = (rowData) => {
    modalDispatch({
      type: "SELECT_PROFILE",
      payload: {
        ...rowData,
        group: "Luminance",
      },
    });
    modalDispatch({ type: "UPDATE_MODAL", payload: true });
    profileDispatch({ type: "SHOW_LUMINANCE_PROFILE" });
  };

  const profileChangeHandler = () => {
    let selectedValue = document.getElementById("selected-profile").value;
    if (selectedValue == 0) {
      toast.error("Please select a light profile type!");
      profileDispatch({ type: "HIDE_ALL_PROFILES", payload: false });
      return;
    }

    if (selectedValue == 1) profileDispatch({ type: "SHOW_TIMER_PROFILE" });
    else if (selectedValue == 2)
      profileDispatch({ type: "SHOW_DAYLIGHT_PROFILE" });
    else if (selectedValue == 3)
      profileDispatch({ type: "SHOW_LUMINANCE_PROFILE" });
    else if (selectedValue == 4)
      profileDispatch({ type: "SHOW_DIMMING_PROFILE" });
  };

  const handleCreateProfile = async (newProfile) => {
    newProfile["lightId"] = currentLight.lightId;
    newProfile["profileId"] = initialProfiles.length + 1;

    if (newProfile?.group === "Timer") {
      if (newProfile?.profileControl === "Enable") {
        profileDispatch({ type: "ENABLE_TIMER_PROFILE" });
        // enableTimelineData();
      } else {
        profileDispatch({ type: "DISABLE_TIMER_PROFILE" });
        disableTimelineData(newProfile?.group);
      }
    } else if (newProfile?.group === "Daylight") {
      if (newProfile?.profileControl === "Enable") {
        profileDispatch({ type: "ENABLE_DAYLIGHT_PROFILE" });
        // enableTimelineData();
      } else {
        profileDispatch({ type: "DISABLE_DAYLIGHT_PROFILE" });
        disableTimelineData(newProfile?.group);
      }
    } else if (newProfile?.group === "Dimming") {
      if (newProfile?.profileControl === "Enable") {
        profileDispatch({ type: "ENABLE_DIMMING_PROFILE" });
        // enableTimelineData();
      } else {
        profileDispatch({ type: "DISABLE_DIMMING_PROFILE" });
        disableTimelineData(newProfile?.group);
      }
    } else {
      if (newProfile?.profileControl === "Enable") {
        profileDispatch({ type: "ENABLE_LUMINANCE_PROFILE" });
        // enableTimelineData();
      } else {
        profileDispatch({ type: "DISABLE_LUMINANCE_PROFILE" });
        disableTimelineData(newProfile?.group);
      }
    }

    let localProfile =
      newProfile.group === "Timer"
        ? [...profileState.timer, newProfile]
        : newProfile.group === "Daylight"
        ? [...profileState.daylight, newProfile]
        : newProfile.group === "Dimming"
        ? [...profileState.dimming, newProfile]
        : [...profileState.luminance, newProfile];

    let profileWrapper = {
      listOfTimerProfiles:
        newProfile.group === "Timer"
          ? localProfile
          : newProfile.group === "Daylight"
          ? profileState.timer
          : [],
      listOfDimmingProfiles: newProfile.group === "Dimming" ? localProfile : [],
      listOfLuminanceProfiles:
        newProfile.group === "Luminance" ? localProfile : [],
      listOfDaylightProfiles:
        newProfile.group === "Daylight"
          ? localProfile
          : newProfile.group === "Timer"
          ? profileState.daylight
          : [],
    };

    const response = await axiosInstance
      .post(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/profile/validate/${
          newProfile.group === "Timer" || newProfile.group === "Daylight"
            ? "daylight/timer"
            : String(newProfile.group).toLowerCase()
        }`,
        profileWrapper
      )
      .catch((error) => {
        modalDispatch({ type: "CLEAR_PROFILE" });
        toast.error(error.response.data);
      });

    if (response?.status === 200 && response?.data === true) {
      modalDispatch({ type: "CREATE_MODAL", payload: false });
      if (newProfile.group === "Timer") {
        profileDispatch({
          type: "ADD_TIMER_PROFILE",
          payload: newProfile,
        });
      } else if (newProfile.group === "Daylight") {
        profileDispatch({
          type: "ADD_DAYLIGHT_PROFILE",
          payload: newProfile,
        });
      } else if (newProfile.group === "Dimming") {
        profileDispatch({
          type: "ADD_DIMMING_PROFILE",
          payload: newProfile,
        });
      } else {
        profileDispatch({
          type: "ADD_LUMINANCE_PROFILE",
          payload: newProfile,
        });
      }
      setInitialProfiles((prevState) => [...prevState, newProfile]);
      if (newProfile?.group !== "Luminance")
        addTimelineData(newProfile, newProfile.group);
      modalDispatch({ type: "CLEAR_PROFILE" });
    }
  };

  const handleUpdateProfile = async (newProfile) => {
    if (newProfile?.group === "Timer") {
      if (newProfile?.profileControl === "Enable") {
        profileDispatch({ type: "ENABLE_TIMER_PROFILE" });
        // enableTimelineData();
      } else {
        profileDispatch({ type: "DISABLE_TIMER_PROFILE" });
        disableTimelineData(newProfile?.group);
      }
    } else if (newProfile?.group === "Daylight") {
      if (newProfile?.profileControl === "Enable") {
        profileDispatch({ type: "ENABLE_DAYLIGHT_PROFILE" });
        // enableTimelineData();
      } else {
        profileDispatch({ type: "DISABLE_DAYLIGHT_PROFILE" });
        disableTimelineData(newProfile?.group);
      }
    } else if (newProfile?.group === "Dimming") {
      if (newProfile?.profileControl === "Enable") {
        profileDispatch({ type: "ENABLE_DIMMING_PROFILE" });
        // enableTimelineData();
      } else {
        profileDispatch({ type: "DISABLE_DIMMING_PROFILE" });
        disableTimelineData(newProfile?.group);
      }
    } else {
      if (newProfile?.profileControl === "Enable") {
        profileDispatch({ type: "ENABLE_LUMINANCE_PROFILE" });
        // enableTimelineData();
      } else {
        profileDispatch({ type: "DISABLE_LUMINANCE_PROFILE" });
        disableTimelineData(newProfile?.group);
      }
    }

    let localProfile =
      newProfile.group === "Timer"
        ? [
            ...profileState.timer.filter((item) => {
              return item.timerProfileId !== newProfile.timerProfileId;
            }),
            newProfile,
          ]
        : newProfile.group === "Dimming"
        ? [
            ...profileState.dimming.filter((item) => {
              return item.dimmingProfileId !== newProfile.dimmingProfileId;
            }),
            newProfile,
          ]
        : newProfile.group === "Daylight"
        ? [
            ...profileState.daylight.filter((item) => {
              return item.daylightProfileId !== newProfile.daylightProfileId;
            }),
            newProfile,
          ]
        : [
            ...profileState.luminance.filter((item) => {
              return item.luminanceProfileId !== newProfile.luminanceProfileId;
            }),
          ];

    let profileWrapper = {
      listOfTimerProfiles:
        newProfile.group === "Timer"
          ? localProfile
          : newProfile.group === "Daylight"
          ? profileState.timer
          : [],
      listOfDimmingProfiles: newProfile.group === "Dimming" ? localProfile : [],
      listOfLuminanceProfiles:
        newProfile.group === "Luminance" ? localProfile : [],
      listOfDaylightProfiles:
        newProfile.group === "Daylight"
          ? localProfile
          : newProfile.group === "Timer"
          ? profileState.daylight
          : [],
    };

    const response = await axiosInstance
      .post(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/profile/validate/${
          newProfile.group === "Timer" || newProfile.group === "Daylight"
            ? "daylight/timer"
            : String(newProfile.group).toLowerCase()
        }`,
        profileWrapper
      )
      .catch((error) => {
        modalDispatch({ type: "CLEAR_PROFILE" });
        // setIsConfig(false);
        toast.error(error.response.data);
      });

    if (response?.status === 200 && response?.data === true) {
      modalDispatch({ type: "UPDATE_MODAL", payload: false });
      if (newProfile.group === "Timer") {
        profileDispatch({
          type: "UPDATE_TIMER_PROFILE",
          payload: newProfile,
        });
      } else if (newProfile.group === "Daylight") {
        profileDispatch({
          type: "UPDATE_DAYLIGHT_PROFILE",
          payload: newProfile,
        });
      } else if (newProfile.group === "Dimming") {
        profileDispatch({
          type: "UPDATE_DIMMING_PROFILE",
          payload: newProfile,
        });
      } else {
        profileDispatch({
          type: "UPDATE_LUMINANCE_PROFILE",
          payload: newProfile,
        });
      }
      if (newProfile?.group !== "Luminance")
        updateTimelineData(newProfile, newProfile.group);

      modalDispatch({ type: "CLEAR_PROFILE" });
    }
  };

  const handleDeleteProfile = async (event) => {
    event.preventDefault();
    let profile = modalState?.selected;

    if (profile.group === "Timer") {
      if (!validateDeleteProfile(profileState.timer, profile.group)) return;
      profileDispatch({
        type: "REMOVE_TIMER_PROFILE",
        payload: profile.profileId,
      });
    } else if (profile.group === "Dimming") {
      if (!validateDeleteProfile(profileState.dimming, profile.group)) return;
      profileDispatch({
        type: "REMOVE_DIMMING_PROFILE",
        payload: profile.profileId,
      });
    } else if (profile.group === "Luminance") {
      if (!validateDeleteProfile(profileState.luminance, profile.group)) return;
      profileDispatch({
        type: "REMOVE_LUMINANCE_PROFILE",
        payload: profile.profileId,
      });
    } else if (profile.group === "Daylight") {
      if (!validateDeleteProfile(profileState.daylight, profile.group)) return;
      profileDispatch({
        type: "REMOVE_DAYLIGHT_PROFILE",
        payload: profile.profileId,
      });
    }

    setTimelineData((prevState) => [
      ...prevState.filter((item) => item.itemId !== profile.profileId),
    ]);
    setWeekendTimelineData((prevState) => [
      ...prevState.filter((item) => item.itemId !== profile.profileId),
    ]);
    setInitialProfiles((prevState) => [
      ...prevState.filter((item) => item.profileId !== profile.profileId),
    ]);
    modalDispatch({ type: "CLOSE_MODAL" });
  };

  const validateDeleteProfile = (profile, group) => {
    if (profile.length === 1) {
      toast.error(
        "You must have at least 1 " +
          group +
          " Profile configured. You may disable the profile if you wish to unmount it."
      );
      modalDispatch({ type: "CLOSE_MODAL" });
      return false;
    }

    return true;
  };

  const lightUpdateHandler = async (data) => {
    const generalConfigObj = {
      lightId: data.lightId,
      motionSensitivityConfiguration: {
        motionSensitivity: data.motionSensitivity,
      },
      photosensorConfiguration: {
        photoSensing: data.photoSensing,
        photoUpperThreshold: data.photoUpperThreshold,
        photoLowerThreshold: data.photoLowerThreshold,
      },
      lightIntensityConfiguration: {
        dimIntensity: data.dimIntensity,
        brightIntensity: data.brightIntensity,
      },
      lightInstallConfiguration: {
        installDate: data.installDate,
      },
      timeDelayConfiguration: {
        holdTime: data.holdTime,
      },
      softDimmingConfiguration: {
        rampUpTime: data.rampUpTime,
        rampDownTime: data.rampDownTime,
      },
      lightTestScheduleConfiguration: {
        testControl: data.testControl,
        startTime: data.startTime,
      },
      lightOverrideConfiguration: {
        lightingControl: data.lightingControl,
        failSafeDelay: data.failSafeDelay,
      },
      smartlightProfileWrapper: {
        listOfTimerProfiles: profileState.timer,
        listOfDaylightProfiles: profileState.daylight,
        listOfDimmingProfiles: profileState.dimming,
        listOfLuminanceProfiles: profileState.luminance,
      },
    };

    if (requestConnected && responseConnected) {
      Promise.all([
        subscribeToConfigStatus(userId),
        subscribeToConfigEvent(userId),
        subscribeToConfigResult(),
      ]).then(() => {
        setIsConfig(true);

        axiosInstance
          .post(
            `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/update`,
            generalConfigObj
          )
          .then((response) => {
            if (response?.status === 200) toast.success("Published config.");

            clearTimeout(timeoutId);
            startConfigTimeoutCountdown();
          })
          .catch((error) => {
            console.log(error);
          });

        modalDispatch({ type: "UPDATE_LIGHT_MODAL", payload: false });
      });
    } else
      toast.error(
        "Failed to establish connection with server. Please try again later."
      );
  };

  const lightDeleteHandler = async () => {
    const response = await axiosInstance
      .delete(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/delete/${currentLight.lightId}`
      )
      .catch(function (error) {
        toast.error(error.response.data);
      });

    if (response?.status === 200) {
      toast.success(
        "Successfully deleted light " +
          currentLight.lightId +
          "(" +
          currentLight.sensorId +
          ")."
      );
      dispatch(fetchEntityData());
      navigate("/manage/light", { replace: true });
    } else toast.error("Failed to delete light.");
  };

  const retryEventHandler = async () => {
    setConfigEvent([]);
    setFailedEvent([]);
    setStatusTitle("");
    setRetryAttempt(false);
    clearTimeout(timeoutId);

    const eventId = configEvent
      ?.filter((item) => !item.commandType || item.commandType !== "Set_Ack")
      .map((item) => item.eventId);

    await axiosInstance
      .post(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/update/retry`,
        eventId
      )
      .then((response) => {
        toast.success("Successfully retry: " + JSON.stringify(response?.data));
      })
      .catch((err) => {});
  };

  const syncTimeHandler = () => {
    if (requestConnected && responseConnected) {
      Promise.all([
        subscribeToConfigStatus(userId),
        subscribeToConfigEvent(userId),
        subscribeToConfigResult(),
      ]).then(() => {
        setIsConfig(true);

        axiosInstance
          .get(
            `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/syncTime/${currentLight.lightId}`
          )
          .then((response) => {
            if (response?.status === 200)
              toast.success("Successfully synchronize time");
          })
          .catch(function (error) {
            toast.error(error.response.data);
          });
        modalDispatch({ type: "UPDATE_TIME_MODAL", payload: false });
      });
    } else
      toast.error(
        "Failed to establish connection with server. Please try again later."
      );
  };

  const mspUpgradeHandler = () => {
    if (requestConnected && responseConnected) {
      Promise.all([
        subscribeToConfigStatus(userId),
        subscribeToConfigEvent(userId),
        subscribeToConfigResult(),
      ]).then(() => {
        setIsConfig(true);

        axiosInstance
          .get(
            `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/msp/ota/upgrade/${currentLight.lightId}`
          )
          .then((response) => {
            if (response?.status === 200)
              toast.success("Successfully synchronize time");
          })
          .catch(function (error) {
            toast.error(error.response.data);
          });
        modalDispatch({ type: "MSP_LIGHT_UPGRADE", payload: false });
      });
    } else
      toast.error(
        "Failed to establish connection with server. Please try again later."
      );
  }

  const wisunUpgradeHandler = () => {
    if (requestConnected && responseConnected) {
      Promise.all([
        subscribeToConfigStatus(userId),
        subscribeToConfigEvent(userId),
        subscribeToConfigResult(),
      ]).then(() => {
        setIsConfig(true);

        axiosInstance
          .get(
            `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/wisun/ota/upgrade/${currentLight.lightId}`
          )
          .then((response) => {
            if (response?.status === 200)
              toast.success("Successfully synchronize time");
          })
          .catch(function (error) {
            toast.error(error.response.data);
          });
        modalDispatch({ type: "WISUN_LIGHT_UPGRADE", payload: false });
      });
    } else
      toast.error(
        "Failed to establish connection with server. Please try again later."
      );
  }

  const activateTestHandler = () => {
    if (requestConnected && responseConnected) {
      Promise.all([
        subscribeToConfigStatus(userId),
        subscribeToConfigEvent(userId),
        subscribeToConfigResult(),
      ]).then(() => {
        setIsConfig(true);

        axiosInstance
          .get(
            `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/activatetest/${currentLight.lightId}`
          )
          .then((response) => {
            toast.success("Successfully activated light test.");
          })
          .catch(function (error) {
            toast.error(error.response.data);
          });
      });
    } else
      toast.error(
        "Failed to establish connection with server. Please try again later."
      );

    modalDispatch({ type: "ACTIVATE_TEST_MODAL", payload: false });
  };

  function getDateFromHours(time, typeOfTime) {
    if (time) {
      if (typeOfTime === "start") {
        time = time.split(":");
        if (time[0] === "24") time[0] = "00";

        let now = new Date();
        return new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          ...time
        );
      } else if (typeOfTime === "end") {
        time = time.split(":");
        if (time[0] === "00" && time[1] === "00") {
          let now = new Date();
          return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1,
            ...time
          );
        } else {
          let now = new Date();
          return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            ...time
          );
        }
      }
    } else return new Date();
  }

  const convertFromStringToDate = (responseDate) => {
    let datePieces = responseDate.split("-");
    return new Date(datePieces[2], datePieces[1], datePieces[0], 0, 0, 0);
  };

  const convertFromStringToDateFrontEnd = (responseDate) => {
    var currDate = {};
    if (responseDate instanceof Date) currDate = responseDate.toISOString();
    else currDate = responseDate;

    let dateComponents = currDate;
    let datePieces = [];
    if (currDate.indexOf("T") !== -1) {
      dateComponents = currDate.split("T");
      datePieces = dateComponents[0].split("-");
      let timePieces = dateComponents[1].split(":");
      return new Date(
        parseInt(datePieces[0]),
        parseInt(datePieces[1]) - 1,
        parseInt(datePieces[2]),
        parseInt(timePieces[0]) + 8,
        0,
        0
      );
    } else {
      datePieces = dateComponents.split("-");
      return new Date(datePieces[2], datePieces[1] - 1, datePieces[0], 8, 0, 0);
    }
  };

  const subscribeToConfigStatus = (userId) => {
    return new Promise((resolve, reject) => {
      try {
        requestSubscribe(`/config/${userId}/status`, (payload) => {
          const parsedPayload = JSON.parse(payload);
          setStatusTitle(parsedPayload?.message);
        });
        resolve(); // Resolve the promise without any value
      } catch (error) {
        reject(error); // Reject the promise with the error
      }
    });
  };

  const subscribeToConfigEvent = (userId) => {
    return new Promise((resolve, reject) => {
      try {
        requestSubscribe(`/config/${userId}/event`, (payload) => {
          const parsedPayload = JSON.parse(payload);
          setConfigEvent((prevState) => [...prevState, parsedPayload]);
        });
        resolve(); // Resolve the promise without any value
      } catch (error) {
        reject(error); // Reject the promise with the error
      }
    });
  };

  const subscribeToConfigResult = () => {
    return new Promise((resolve, reject) => {
      try {
        responseSubscribe(`/config/result`, (payload) => {
          const parsedPayload = JSON.parse(payload);
          setConfigEvent((prevState) =>
            prevState.map((item) => {
              if (item.eventId === parsedPayload?.eventId) {
                return {
                  ...item,
                  commandType: parsedPayload.commandType,
                };
              }
              return item;
            })
          );

          if (parsedPayload.commandType !== "Set_Ack")
            setFailedEvent((prevState) => [...prevState, parsedPayload]);
        });
        resolve(); // Resolve the promise without any value
      } catch (error) {
        reject(error); // Reject the promise with the error
      }
    });
  };

  const startConfigTimeoutCountdown = () => {
    setConfigCompleted(false);

    const id = setTimeout(() => {
      setRetryAttempt(true);
      setConfigEvent((prevState) => [
        ...prevState.map((item) => {
          return item;
        }),
      ]);
      setConfigCompleted(true);
    }, [10000]);

    setTimeoutId(id);
  };

  const configCompletedHandler = () => {
    setIsConfig(false);
    setStatusTitle("");
    setConfigCompleted(true);
    setConfigEvent([]);
    setRetryAttempt(false);
    clearTimeout(timeoutId);
    requestCleanup();
    responseCleanup();
  };


  return (
    <Header
      title="Light Info"
      className="lightinfo"
      role={role}
      path={path}
      button={true}
      modalDispatch={modalDispatch}
    >
      <FullScreenOverlay open={isConfig}>
        <StatusChecklist
          statusTitle={statusTitle}
          // configCount={configCount}
          result={configEvent}
          retry={retryAttempt}
          retryMqtt={retryEventHandler}
          handleConfigCompletion={configCompletedHandler}
        />
      </FullScreenOverlay>
      {currentLight && currentLight.lightId > 0 ? (
        <>
          <div className="lightinfo-title-box">
            <h6 className="lightinfo-title">
              {currentLight.sensorId}
              <span className="mx-2">
                <CircleFill
                  style={
                    currentLight.lightStatus === "Normal"
                      ? { color: "green" }
                      : currentLight.lightStatus === "Off"
                      ? { color: "red" }
                      : { color: "orange" }
                  }
                />
              </span>
            </h6>
            <div className="lightinfo-legendbox">
              <div className="lightinfo-legend">
                <CircleFill
                  className="my-auto mx-2"
                  style={{ color: "green" }}
                />
                <span>Online</span>
              </div>
              <div className="lightinfo-legend">
                <CircleFill className="my-auto mx-2" style={{ color: "red" }} />
                <span>Offline</span>
              </div>
              <div className="lightinfo-legend">
                <CircleFill
                  className="my-auto mx-2"
                  style={{ color: "orange" }}
                />
                <span>Dimming/Flickering</span>
              </div>
            </div>
          </div>
          <div className="my-4 mx-4">
            <Form
              id="lightForm"
              className="form-margin-width"
              onSubmit={handleSubmit(lightUpdateHandler)}
            >
              <div className="d-flex flex-column gap-3 mb-3">
                <Accordion
                  expanded={onStartup}
                  onChange={() => setOnStartUp(!onStartup)}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      bgcolor: "#e9ecef",
                    }}
                  >
                    <h5 className="m-0 text-uppercase">Properties</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row className="mb-2">
                      <Form.Group className="lightinfo-form-group">
                        <div className="form-input-width mx-auto">
                          <Form.Label>Light ID</Form.Label>
                          <Controller
                            control={control}
                            name="lightId"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                type="text"
                                readOnly
                                value={value ? value : ""}
                                onBlur={onBlur}
                                onChange={onChange}
                                isInvalid={errors.lightId}
                              />
                            )}
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.lightId?.message}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="lightinfo-form-group">
                        <div className="form-input-width mx-auto">
                          <Form.Label>Sensor ID</Form.Label>
                          <Controller
                            control={control}
                            name="sensorId"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                type="text"
                                readOnly
                                value={value ? value : ""}
                                onBlur={onBlur}
                                onChange={onChange}
                                isInvalid={errors.sensorId}
                              />
                            )}
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.sensorId?.message}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="lightinfo-form-group">
                        <div className="form-input-width mx-auto">
                          <Form.Label>Light SN</Form.Label>
                          <Controller
                            control={control}
                            name="lightSN"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                type="text"
                                readOnly
                                value={value ? value : ""}
                                onBlur={onBlur}
                                onChange={onChange}
                                isInvalid={errors.lightSN}
                              />
                            )}
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.lightSN?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-2">
                      <Form.Group className="lightinfo-form-group">
                        <div className="form-input-width mx-auto">
                          <Form.Label>Display Name</Form.Label>
                          <Controller
                            control={control}
                            name="displayName"
                            placeholder="Enter Light Display Name"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                type="text"
                                readOnly
                                value={value ? value : ""}
                                onBlur={onBlur}
                                onChange={onChange}
                                isInvalid={errors.displayName}
                              />
                            )}
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.displayName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="lightinfo-form-group">
                        <div className="form-input-width mx-auto">
                          <Form.Label>Light IP Address</Form.Label>
                          <Controller
                            control={control}
                            name="lightIPAddress"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                type="text"
                                readOnly
                                value={value ? value : ""}
                                onBlur={onBlur}
                                onChange={onChange}
                                isInvalid={errors.lightIPAddress}
                              />
                            )}
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.lightIPAddress?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="lightinfo-form-group">
                        <div className="form-input-width mx-auto">
                          <Form.Label>Light MAC Address</Form.Label>
                          <Controller
                            control={control}
                            name="lightMACAddress"
                            placeholder="Enter Light MAC Address"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Form.Control
                                type="text"
                                readOnly
                                value={value ? value : ""}
                                onFocus={() => {
                                  setValue("sensorId", "SmartLighting-Aztech-");
                                }}
                                onBlur={(e) => {
                                  if (
                                    e.target.value !== "" &&
                                    e.target.value !== null &&
                                    getValues("blockId") !== "" &&
                                    getValues("blockId") !== null
                                  ) {
                                    // console.log(document.getElementById("blockId"));
                                    let block = userBlockList.find((item) => {
                                      return (
                                        item.blockId ==
                                        document.getElementById("blockId").value
                                      );
                                    });
                                    setValue(
                                      "sensorId",
                                      getValues("sensorId") +
                                        block.postcode +
                                        "-" +
                                        value
                                    );
                                  } else
                                    setValue(
                                      "sensorId",
                                      "SmartLighting-Aztech-"
                                    );
                                }}
                                onChange={onChange}
                                isInvalid={errors.lightMACAddress}
                              />
                            )}
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.lightMACAddress?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-2">
                      <Form.Group className="lightinfo-form-group">
                        <div className="form-input-width mx-auto">
                          <Form.Label>Block ID</Form.Label>
                          <Controller
                            control={control}
                            name="blockId"
                            placeholder="Select Block"
                            render={({ field: { value, name } }) => (
                              <Form.Select
                                id="blockId"
                                name={name}
                                value={value}
                                onChange={blockChangeHandler}
                                isInvalid={errors.blockId}
                                disabled
                              >
                                {userBlockList?.length > 0 &&
                                  userBlockList.map((block) => {
                                    return (
                                      <option
                                        key={block.blockId}
                                        id={block.blockId}
                                        value={block.blockId}
                                      >
                                        {block.blockName}
                                      </option>
                                    );
                                  })}
                              </Form.Select>
                            )}
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.blockId?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="lightinfo-form-group"
                        controlId="floorId"
                      >
                        <div className="form-input-width mx-auto">
                          <Form.Label>Floor ID</Form.Label>
                          <Controller
                            control={control}
                            name="floorId"
                            placeholder="Select Floor"
                            render={({ field: { value, name } }) => (
                              <Form.Select
                                name={name}
                                value={value}
                                disabled
                                onChange={(e) => {
                                  setValue("floorId", e.target.value, {
                                    shouldValidate: true,
                                  });
                                }}
                                isInvalid={errors.floorId}
                              >
                                {userFloorList?.length > 0 &&
                                  userFloorList.map((floor) => {
                                    return (
                                      <option
                                        key={floor.floorId}
                                        id={floor.floorId}
                                        value={floor.floorId}
                                      >
                                        {floor.floorName}
                                      </option>
                                    );
                                  })}
                              </Form.Select>
                            )}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="lightinfo-form-group"
                        controlId="gatewayId"
                      >
                        <div className="form-input-width mx-auto">
                          <Form.Label>Gateway ID</Form.Label>
                          <Controller
                            control={control}
                            name="gatewayId"
                            render={({ field: { value, name } }) => (
                              <Form.Select
                                name={name}
                                value={value}
                                disabled
                                onChange={(e) => {
                                  setValue("gatewayId", e.target.value, {
                                    shouldValidate: true,
                                  });
                                }}
                                isInvalid={errors.gatewayId}
                              >
                                {userGatewayList?.length > 0 &&
                                  userGatewayList?.map((gateway) => {
                                    return (
                                      <option
                                        key={gateway.gatewayId}
                                        id={gateway.gatewayId}
                                        value={gateway.gatewayId}
                                      >
                                        {gateway.gatewayMACAddress}
                                      </option>
                                    );
                                  })}
                              </Form.Select>
                            )}
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.gatewayId?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                      bgcolor: "#e9ecef",
                    }}
                  >
                    <h5 className="m-0 text-uppercase">Light Configuration</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row className="mb-2">
                      <div className="d-flex justify-content-end pe-4">
                        <a href="/configuration" style={{ paddingTop: "15px" }}>
                          Group configuration?
                        </a>
                      </div>
                    </Row>
                    <div className="col-md-12 d-flex flex-row">
                      <div className="col-md-6">
                        <Row className="mb-2">
                          {/* <Col style={{ margin: "auto" }}>
                      <p style={{ fontSize: "120%" }}>Light Override:</p>
                    </Col> */}
                          <Form.Group
                            className="lightinfo-form-group"
                            controlId="lightingControl"
                          >
                            <div className="form-input-width my-1 mx-auto">
                              <div className="d-flex flex-row gap-1">
                                <Form.Label
                                  onClick={() => navigate("/dashboard")}
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Lighting Control
                                </Form.Label>
                                <Tooltip
                                  className="custom-tooltip"
                                  title="Sensor reading below threshold value turns ON lighting"
                                  placement="right"
                                >
                                  <QuestionCircle />
                                </Tooltip>
                              </div>
                              <Controller
                                control={control}
                                name="lightingControl"
                                placeholder="Enter Light Control"
                                render={({ field: { value } }) => (
                                  <Form.Control
                                    type="text"
                                    value={value}
                                    readOnly
                                    isInvalid={errors.lightingControl}
                                  />
                                  // <Form.Select
                                  //   disabled
                                  //   value={value}
                                  //   onChange={(e) => {
                                  //     setValue(
                                  //       "lightingControl",
                                  //       e.target.value,
                                  //       {
                                  //         shouldValidate: true,
                                  //       }
                                  //     );
                                  //   }}
                                  //   isInvalid={errors.lightingControl}
                                  // >
                                  //   <option value="Force_On">On</option>
                                  //   <option value="Force_Off">Off</option>
                                  //   <option value="Auto">Auto</option>
                                  // </Form.Select>
                                )}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.lightingControl?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="lightinfo-form-group">
                            <div className="form-input-width mx-auto">
                              <div className="d-flex flex-row gap-1">
                                <Form.Label>Ramp Up Time (s)</Form.Label>{" "}
                                <Tooltip
                                  className="custom-tooltip"
                                  title="Ramp up time can take a minimum value of 1 and a maximum value of 3"
                                  placement="right"
                                >
                                  <QuestionCircle />
                                </Tooltip>
                              </div>
                              <Controller
                                control={control}
                                name="rampUpTime"
                                render={({ field: { onChange, value } }) => (
                                  <div className="form-input-width my-1 mx-auto">
                                    <InputSlider
                                      min={1}
                                      max={3}
                                      onSliderChange={(e, newValue) =>
                                        onChange(newValue)
                                      }
                                      value={value}
                                      onInputChange={(e) =>
                                        onChange(Number(e.target.value))
                                      }
                                    />
                                  </div>
                                  // <Form.Control
                                  //   type="number"
                                  //   className="form-input-width my-1 mx-auto"
                                  //   value={value}
                                  //   onBlur={onBlur}
                                  //   onChange={onChange}
                                  //   isInvalid={errors.rampUpTime}
                                  // />
                                )}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.rampUpTime?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-2">
                          {/* <Col style={{ margin: "auto" }}>
                      <p style={{ fontSize: "120%" }}>Soft Dimming:</p>
                    </Col> */}
                          <Form.Group className="lightinfo-form-group">
                            <div className="form-input-width mt-1 mb-4 mx-auto">
                              <div className="d-flex flex-row gap-1">
                                <Form.Label>Fail Safe Delay (s)</Form.Label>
                                <Tooltip
                                  className="custom-tooltip"
                                  title="Sensor reading below threshold value turns ON lighting"
                                  placement="right"
                                >
                                  <QuestionCircle />
                                </Tooltip>
                              </div>
                              <Controller
                                control={control}
                                name="failSafeDelay"
                                render={({
                                  field: { onChange, onBlur, value },
                                }) => (
                                  <Form.Control
                                    type="number"
                                    readOnly
                                    value={value ? value : 0}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    isInvalid={errors.failSafeDelay}
                                  />
                                )}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.failSafeDelay?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="lightinfo-form-group">
                            <div className="form-input-width mx-auto">
                              <div className="d-flex flex-row gap-1">
                                <Form.Label>Ramp Down Time (s)</Form.Label>{" "}
                                <Tooltip
                                  className="custom-tooltip"
                                  title="Ramp down time takes a minimum value of 1 and a maximum value of 3"
                                  placement="right"
                                >
                                  <QuestionCircle />
                                </Tooltip>
                              </div>
                              <Controller
                                control={control}
                                name="rampDownTime"
                                render={({ field: { onChange, value } }) => (
                                  <div className="form-input-width mt-1 mb-4 mx-auto">
                                    <InputSlider
                                      min={1}
                                      max={3}
                                      onSliderChange={(e, newValue) =>
                                        onChange(newValue)
                                      }
                                      value={value}
                                      onInputChange={(e) =>
                                        onChange(Number(e.target.value))
                                      }
                                    />
                                  </div>
                                  // <Form.Control
                                  //   type="number"
                                  //   className="form-input-width my-1 mx-auto"
                                  //   value={value}
                                  //   onBlur={onBlur}
                                  //   onChange={onChange}
                                  //   isInvalid={errors.rampDownTime}
                                  // />
                                )}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.rampDownTime?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Row className="mb-2">
                          {/* <Col style={{ margin: "auto" }}>
                      <p style={{ fontSize: "120%" }}>Light Test:</p>
                    </Col> */}
                          <Form.Group className="lightinfo-form-group">
                            <div className="d-flex flex-row gap-1 form-input-width mx-auto">
                              <Form.Label>Hold Time (s)</Form.Label>{" "}
                              <Tooltip
                                className="custom-tooltip"
                                title="Sensor reading below threshold value turns ON lighting"
                                placement="right"
                              >
                                <QuestionCircle />
                              </Tooltip>
                            </div>
                            <Controller
                              control={control}
                              name="holdTime"
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Form.Control
                                  type="number"
                                  className="form-input-width my-1 mx-auto"
                                  value={value}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  isInvalid={errors.holdTime}
                                />
                              )}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.holdTime?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="lightinfo-form-group">
                            <div className="form-input-width mx-auto">
                              <Form.Label>Install Date</Form.Label>
                              <Controller
                                control={control}
                                name="installDate"
                                render={({ field: { value } }) => (
                                  <DatePicker
                                    readOnly
                                    format="yyyy-MM-dd"
                                    className="w-100"
                                    ranges={[]}
                                    placement="auto"
                                    value={value ? new Date(value) : new Date()}
                                    onChange={(e) => {
                                      setValue(
                                        "installDate",
                                        e.toISOString().split("T")[0],
                                        {
                                          shouldValidate: true,
                                        }
                                      );
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.installDate?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        {/* <Row className="mb-2">
                          {/* <Col style={{ margin: "auto" }}>
                      <p style={{ fontSize: "120%" }}>Install Date:</p>
                    </Col> */}
                        {/* </Row> */}
                      </div>

                      <div className="col-md-6">
                        <Row className="mb-2">
                          {/* <Col style={{ margin: "auto" }}>
                      <p style={{ fontSize: "120%" }}>Light Intensity:</p>
                    </Col> */}
                          <Form.Group className="lightinfo-form-group">
                            <div className="form-input-width my-1 mx-auto">
                              <div className="d-flex flex-row gap-1">
                                <Form.Label>Dim Intensity (%)</Form.Label>
                                <Tooltip
                                  className="custom-tooltip"
                                  title="Dim intensity can take a minimum value of 0 and a maximum value of 50"
                                  placement="right"
                                >
                                  <QuestionCircle />
                                </Tooltip>
                              </div>
                              <Controller
                                control={control}
                                name="dimIntensity"
                                render={({ field: { onChange, value } }) => (
                                  <div className="form-input-width mx-auto">
                                    <InputSlider
                                      min={0}
                                      max={50}
                                      step={10}
                                      onSliderChange={(e, newValue) =>
                                        onChange(newValue)
                                      }
                                      value={value}
                                      onInputChange={(e) => {
                                        onChange(Number(e.target.value));
                                      }}
                                    />
                                  </div>
                                  // <Form.Control
                                  //   type="number"
                                  //   className="my-1"
                                  //   value={value}
                                  //   onBlur={onBlur}
                                  //   onChange={onChange}
                                  //   isInvalid={errors.dimIntensity}
                                  // />
                                )}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.dimIntensity?.message}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Form.Group className="lightinfo-form-group">
                            <div className="form-input-width my-1 mx-auto d-flex flex-row justify-content-between">
                              <Form.Label>Test Control</Form.Label>
                              <Controller
                                control={control}
                                name="testControl"
                                render={({ field: { value } }) => (
                                  <Toggle
                                    checked={value === "Enable"}
                                    onChange={(e) => {
                                      setTestControl(e);
                                      setValue(
                                        "testControl",
                                        e ? "Enable" : "Disable",
                                        {
                                          shouldValidate: true,
                                        }
                                      );
                                    }}
                                    // isInvalid={errors.testControl}
                                  />
                                  // <Form.Select
                                  //   value={value}
                                  //   onChange={(e) => {
                                  //     setValue("testControl", e.target.value, {
                                  //       shouldValidate: true,
                                  //     });
                                  //   }}
                                  //   isInvalid={errors.testControl}
                                  // >
                                  //   <option value="Enable">Enable</option>
                                  //   <option value="Disable">Disable</option>
                                  // </Form.Select>
                                )}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.testControl?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-2">
                          <Form.Group className="lightinfo-form-group">
                            <div className="form-input-width mt-1 mb-4 mx-auto">
                              <div className="d-flex flex-row gap-1">
                                <Form.Label>Bright Intensity (%)</Form.Label>
                                <Tooltip
                                  className="custom-tooltip"
                                  title="Bright intensity can take a minimum value of 50 and a maximum value of 100"
                                  placement="right"
                                >
                                  <QuestionCircle />
                                </Tooltip>
                              </div>
                              <Controller
                                control={control}
                                name="brightIntensity"
                                render={({ field: { onChange, value } }) => (
                                  <div className="form-input-width mx-auto">
                                    <InputSlider
                                      min={50}
                                      max={100}
                                      step={10}
                                      onSliderChange={(e, newValue) =>
                                        onChange(newValue)
                                      }
                                      value={value}
                                      onInputChange={(e) => {
                                        onChange(Number(e.target.value));
                                      }}
                                    />
                                  </div>
                                  // <Form.Control
                                  //   type="number"
                                  //   className="my-1"
                                  //   value={value}
                                  //   onBlur={onBlur}
                                  //   onChange={onChange}
                                  //   isInvalid={errors.brightIntensity}
                                  // />
                                )}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.brightIntensity?.message}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Form.Group className="lightinfo-form-group">
                            <div className="form-input-width mt-1 mb-4 mx-auto ">
                              <Form.Label>Start Time</Form.Label>
                              <Controller
                                control={control}
                                name="startTime"
                                render={({ field: { value } }) => (
                                  <DatePicker
                                    id="startTime"
                                    name="startTime"
                                    placeholder="Please select Start Time"
                                    format="HH:mm"
                                    ranges={[]}
                                    readOnly={!testControl}
                                    className="w-100"
                                    placement="auto"
                                    hideMinutes={(minute) => minute % 30 !== 0}
                                    disabledMinutes={(minute) =>
                                      minute % 30 !== 0
                                    }
                                    value={getDateFromHours(value, "start")}
                                    onChange={(e) => {
                                      let timeVal = e.toLocaleTimeString([], {
                                        hour12: false,
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      });
                                      if (timeVal.split(":")[0] === "24")
                                        timeVal = "00:" + timeVal.split(":")[1];
                                      setValue("startTime", timeVal, {
                                        shouldValidate: true,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.startTime?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                          {/* <Col style={{ margin: "auto" }}>
                      <p style={{ fontSize: "120%" }}>Time Delay:</p>
                                  </Col> */}
                        </Row>
                        <Row className="mb-2">
                          {/* <Col style={{ margin: "auto" }}>
                      <p style={{ fontSize: "120%" }}>Light Status:</p>
                    </Col> */}
                          <Form.Group className="lightinfo-form-group">
                            <div className="form-input-width mx-auto">
                              <Form.Label>Light Status</Form.Label>
                              <Controller
                                control={control}
                                name="lightStatus"
                                render={({
                                  field: { onChange, onBlur, value },
                                }) => (
                                  <Form.Control
                                    type="text"
                                    readOnly
                                    value={value ? value : "N/A"}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    isInvalid={errors.lightStatus}
                                  />
                                )}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.lightStatus?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Col></Col>
                        </Row>
                      </div>
                    </div>
                    <div style={{ height: "10px", width: "100%" }}></div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                      bgcolor: "#e9ecef",
                    }}
                  >
                    <h5 className="m-0 text-uppercase">Sensor Configuration</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="lightinfo-subtitle-buttonbox">
                      {(role === "ROLE_ADMIN" ||
                        role === "ROLE_AREA_ADMIN") && (
                        <Button
                          size="small"
                          color="warning"
                          variant="contained"
                          value="Activate Light Test"
                          className="lightinfo-subtitle-button"
                          onClick={() =>
                            modalDispatch({
                              type: "ACTIVATE_TEST_MODAL",
                              payload: true,
                            })
                          }
                        >
                          <span>
                            <strong>ACTIVATE</strong> Test
                          </span>
                        </Button>
                      )}
                      <Button
                        className="lightinfo-subtitle-button"
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() =>
                          modalDispatch({
                            type: "UPDATE_TIME_MODAL",
                            payload: true,
                          })
                        }
                      >
                        <strong>Sync Time</strong>
                      </Button>
                    </div>
                    <div className="col-md-12 d-flex flex-row">
                      <div className="col-md-6">
                        <Row className="mb-2">
                          {/* <Col style={{ margin: "auto" }}>
                      <p style={{ fontSize: "120%" }}>Photo Sensing:</p>
                    </Col> */}
                          <Form.Group className="lightinfo-form-group">
                            <div className="form-input-width my-1 mx-auto">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex flex-row gap-1">
                                  <Form.Label>Photo Sensing Control</Form.Label>
                                  <Tooltip
                                    className="custom-tooltip"
                                    title="Sensor reading below threshold value turns ON lighting"
                                    placement="right"
                                  >
                                    <QuestionCircle />
                                  </Tooltip>
                                </div>
                                <Controller
                                  control={control}
                                  name="photoSensing"
                                  render={({ field: { value } }) => (
                                    <Toggle
                                      checked={value === "Enable"}
                                      onChange={(e) => {
                                        setPhotoSensing(e);
                                        setValue(
                                          "photoSensing",
                                          e ? "Enable" : "Disable",
                                          {
                                            shouldValidate: true,
                                          }
                                        );
                                      }}
                                      // isInvalid={errors.testControl}
                                    />
                                    // <Form.Select
                                    //   name={name}
                                    //   value={value}
                                    //   onChange={(e) => {
                                    //     setValue("photoSensing", e.target.value, {
                                    //       shouldValidate: true,
                                    //     });
                                    //   }}
                                    //   isInvalid={errors.photoSensing}
                                    // >
                                    //   <option value="Enable">Enable</option>
                                    //   <option value="Disable">Disable</option>
                                    // </Form.Select>
                                  )}
                                />
                              </div>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.testControl?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="lightinfo-form-group">
                            <div className="d-flex flex-row gap-1 form-input-width mx-auto">
                              <Form.Label>
                                Photo Lower Threshold (lux)
                              </Form.Label>
                              <Tooltip
                                className="custom-tooltip"
                                title="Sensor reading below threshold value turns ON lighting"
                                placement="right"
                              >
                                <QuestionCircle />
                              </Tooltip>
                            </div>
                            <Controller
                              control={control}
                              name="photoLowerThreshold"
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Form.Control
                                  type="number"
                                  className="form-input-width my-1 mx-auto"
                                  value={value}
                                  readOnly={!photoSensing}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  isInvalid={errors.photoLowerThreshold}
                                />
                              )}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.photoLowerThreshold?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group className="lightinfo-form-group">
                            <div className="form-input-width my-1 mx-auto">
                              <div className="d-flex flex-row gap-1">
                                <Form.Label>Motion Sensitivity</Form.Label>{" "}
                                <Tooltip
                                  className="custom-tooltip"
                                  title="Sensor reading below threshold value turns ON lighting"
                                  placement="right"
                                >
                                  <QuestionCircle />
                                </Tooltip>
                              </div>
                              <Controller
                                control={control}
                                name="motionSensitivity"
                                render={({ field: { onChange, value } }) => (
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={value}
                                    onChange={onChange}
                                  >
                                    {labels.map((item, index) => {
                                      return (
                                        <FormControlLabel
                                          key={index}
                                          value={index + 1}
                                          control={<Radio size="small" />}
                                          label={
                                            <span style={{ fontSize: "14px" }}>
                                              {item}
                                            </span>
                                          }
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                  // Slider for the low, medium and high
                                  // <Slider
                                  //   value={value}
                                  //   min={0}
                                  //   max={labels.length - 1}
                                  //   tooltip={false}
                                  //   graduated
                                  //   renderMark={(mark) => labels[mark]}
                                  //   onChange={(e) => {
                                  //     setValue("motionSensitivity", e, {
                                  //       shouldValidate: true,
                                  //     });
                                  //   }}
                                  //   isInvalid={errors.motionSensitivity}
                                  // />

                                  // Regular dropdown to select High, medium or low
                                  // <Form.Select
                                  //   name={name}
                                  //   value={value}
                                  //   onChange={(e) => {
                                  //     setValue("motionSensitivity", e.target.value, {
                                  //       shouldValidate: true,
                                  //     });
                                  //   }}
                                  //   isInvalid={errors.motionSensitivity}
                                  // >
                                  //   <option value="3">High</option>
                                  //   <option value="2">Medium</option>
                                  //   <option value="1">Low</option>
                                  // </Form.Select>
                                )}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.motionSensitivity?.message}
                            </Form.Control.Feedback>
                          </Form.Group>

                          {/* <Form.Group className="lightinfo-form-group">
                      <div className="form-input-width my-1">
                        <Form.Label>Start Time</Form.Label>
                        <Controller
                          control={control}
                          name="startTime"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <DatePicker
                              id="startTime"
                              name="startTime"
                              placeholder="Please select Start Time"
                              format="HH:mm"
                              ranges={[]}
                              className="w-100"
                              placement="auto"
                              hideMinutes={(minute) => minute % 30 !== 0}
                              disabledMinutes={(minute) => minute % 30 !== 0}
                              value={
                                value
                                  ? getDateFromHours(value, "start")
                                  : new Date()
                              }
                              onChange={(e) => {
                                let timeVal = e.toLocaleTimeString([], {
                                  hour12: false,
                                  hour: "2-digit",
                                  minute: "2-digit",
                                });
                                if (timeVal.split(":")[0] == "24")
                                  timeVal = "00:" + timeVal.split(":")[1];
                                setValue("startTime", timeVal, {
                                  shouldValidate: true,
                                });
                              }}
                            />
                          )}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.photoSensing?.message}
                      </Form.Control.Feedback>
                    </Form.Group> */}
                          <Col></Col>
                        </Row>
                      </div>
                      <Divider vertical />
                      <div className="col-md-6">
                        <Row>
                          {/* <Col style={{ margin: "auto" }}>
                      <p style={{ fontSize: "120%" }}>Motion Sensitivity:</p>
                    </Col> */}
                          <Form.Group className="lightinfo-form-group">
                            <div className="d-flex flex-row gap-1 form-input-width mx-auto">
                              <Form.Label>
                                Photo Upper Threshold (lux)
                              </Form.Label>{" "}
                              <Tooltip
                                className="custom-tooltip"
                                title="Sensor reading below threshold value turns ON lighting"
                                placement="right"
                              >
                                <QuestionCircle />
                              </Tooltip>
                            </div>
                            <Controller
                              control={control}
                              name="photoUpperThreshold"
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Form.Control
                                  type="number"
                                  className="form-input-width my-1 mx-auto"
                                  value={value}
                                  readOnly={!photoSensing}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  isInvalid={errors.photoUpperThreshold}
                                />
                              )}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.photoUpperThreshold?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Col></Col>
                        </Row>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Form>
            {/* <Divider /> */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                  bgcolor: "#e9ecef",
                }}
              >
                <h5 className="m-0 text-uppercase">Profile Configuration</h5>
              </AccordionSummary>
              <AccordionDetails>
                {timelineData?.length > 0 &&
                  weekendTimelineData?.length > 0 && (
                    <div className="d-flex flex-row justify-content-between mt-4">
                      <div className="d-flex flex-row mx-4">
                        <div className="d-flex flex-row my-auto mx-2">
                          <span>Legends: </span>
                        </div>
                        <div className="d-flex flex-row my-auto mx-2">
                          <CircleFill
                            className="my-auto mx-2"
                            style={{ color: "#FFE0AE" }}
                          />
                          <span>Timer</span>
                        </div>
                        <div className="d-flex flex-row my-auto mx-2">
                          <CircleFill
                            className="my-auto mx-2"
                            style={{ color: "#F5631A" }}
                          />
                          <span>Daylight</span>
                        </div>
                        <div className="d-flex flex-row my-auto mx-2">
                          <CircleFill
                            className="my-auto mx-2"
                            style={{ color: "#8CA0ED" }}
                          />
                          <span>Dimming</span>
                        </div>
                        <div className="d-flex flex-row my-auto mx-2">
                          <CircleFill
                            className="my-auto mx-2"
                            style={{ color: "#696969" }}
                          />
                          <span>Disabled</span>
                        </div>
                      </div>
                    </div>
                  )}

                {timelineData?.length > 0 && weekendTimelineData?.length > 0 ? (
                  <div className=" col-md-12">
                    <div className="d-flex flex-column justify-content-between mb-4">
                      <div className="mb-4">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="my-auto" style={{ fontSize: "120%" }}>
                            Weekday Timeline
                          </div>
                          <div className="my-2">
                            <Button
                              size="small"
                              type="button"
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                currentLight?.lightId === null ||
                                currentLight?.lightId === undefined
                                  ? toast.error("Please select a light.")
                                  : profileDispatch({
                                      type: "HIDE_ALL_PROFILES",
                                    });
                                modalDispatch({
                                  type: "CREATE_MODAL",
                                  payload: true,
                                });
                                modalDispatch({
                                  type: "SELECT_PROFILE",
                                  payload: null,
                                });
                              }}
                              disabled={disableCRUD}
                            >
                              CREATE
                            </Button>

                            {timelineData?.length > 0 &&
                              weekendTimelineData?.length > 0 && (
                                <React.Fragment>
                                  <Button
                                    size="small"
                                    type="button"
                                    color="error"
                                    variant="contained"
                                    onClick={() =>
                                      modalDispatch({
                                        type: "DISABLE_MODAL",
                                        payload: true,
                                      })
                                    }
                                    className="ms-2"
                                    disabled={disableCRUD}
                                  >
                                    Disable
                                  </Button>
                                </React.Fragment>
                              )}
                          </div>
                        </div>
                        {timelineData && timelineData.length > 0 && (
                          <ProfileTimeline
                            type="Weekday"
                            handleClickItem={handleItemSelectForBoth}
                            data={timelineData}
                          />
                        )}

                        {timelineData.length <= 0 && (
                          <div>
                            No weekday profile found. Proceed to create one.
                          </div>
                        )}
                      </div>

                      <div className="mt-4">
                        <div className="mb-2" style={{ fontSize: "120%" }}>
                          Weekend Timeline
                        </div>
                        {weekendTimelineData &&
                          weekendTimelineData.length > 0 && (
                            <ProfileTimeline
                              type="Weekend"
                              handleClickItem={handleItemSelectForBoth}
                              data={weekendTimelineData}
                            />
                          )}

                        {weekendTimelineData.length <= 0 && (
                          <div>
                            No weekend profile found. Proceed to create one.
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="d-flex flex-column mx-2"
                      style={{ marginTop: "50px" }}
                    >
                      <div className="my-4">
                        <h5 className="mb-4">Luminance Profiles</h5>
                        {profileState.luminance &&
                          profileState.luminance.length > 0 && (
                            <CustomTable
                              data={profileState.luminance}
                              columns={luminanceColumn}
                              onRowClick={handleLumiTableRowClick}
                            />
                          )}
                        {profileState.luminance?.length <= 0 && (
                          <div className="my-2">
                            No luminance profiles configured.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="d-flex justify-content-between align-items-center my-2">
                      <p className="text-secondary fs-6 my-auto">
                        No profiles configured. Proceed to create one.
                      </p>
                      <Button
                        size="small"
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          currentLight?.lightId === null ||
                          currentLight?.lightId === undefined
                            ? toast.error("Please select a light.")
                            : profileDispatch({
                                type: "HIDE_ALL_PROFILES",
                              });
                          modalDispatch({
                            type: "CREATE_MODAL",
                            payload: true,
                          });
                          modalDispatch({
                            type: "SELECT_PROFILE",
                            payload: null,
                          });
                        }}
                        disabled={disableCRUD}
                      >
                        CREATE
                      </Button>
                    </div>
                  </React.Fragment>
                )}
                <ModalOverlay
                  id="ViewTimerProfile"
                  header="Timer Profile Details"
                  isOpen={modalState.update}
                  closeModal={() =>
                    modalDispatch({ type: "UPDATE_MODAL", payload: false })
                  }
                  hasButton="false"
                >
                  {profileState.show_timer && (
                    <TimerForm
                      selectedProfile={modalState.selected}
                      disableCRUD={disableCRUD}
                      onSubmit={handleUpdateProfile}
                      onDelete={() =>
                        modalDispatch({
                          type: "DELETE_PROFILE_MODAL",
                          payload: true,
                        })
                      }
                      closeModal={() =>
                        modalDispatch({ type: "UPDATE_MODAL", payload: false })
                      }
                    />
                  )}
                  {profileState.show_daylight && (
                    <DaylightForm
                      selectedProfile={modalState.selected}
                      disableCRUD={disableCRUD}
                      onSubmit={handleUpdateProfile}
                      onDelete={() =>
                        modalDispatch({
                          type: "DELETE_PROFILE_MODAL",
                          payload: true,
                        })
                      }
                      closeModal={() =>
                        modalDispatch({ type: "UPDATE_MODAL", payload: false })
                      }
                    />
                  )}
                  {profileState.show_luminance && (
                    <LuminanceForm
                      selectedProfile={modalState.selected}
                      disableCRUD={disableCRUD}
                      onSubmit={handleUpdateProfile}
                      onDelete={() =>
                        modalDispatch({
                          type: "DELETE_PROFILE_MODAL",
                          payload: true,
                        })
                      }
                      closeModal={() =>
                        modalDispatch({ type: "UPDATE_MODAL", payload: false })
                      }
                    />
                  )}
                  {profileState.show_dimming && (
                    <DimmingForm
                      selectedProfile={modalState.selected}
                      disableCRUD={disableCRUD}
                      onSubmit={handleUpdateProfile}
                      onDelete={() =>
                        modalDispatch({
                          type: "DELETE_PROFILE_MODAL",
                          payload: true,
                        })
                      }
                      closeModal={() =>
                        modalDispatch({ type: "UPDATE_MODAL", payload: false })
                      }
                    />
                  )}
                </ModalOverlay>
                <ModalOverlay
                  header="Confirm Update Light"
                  isOpen={modalState.update_light}
                  closeModal={() =>
                    modalDispatch({
                      type: "UPDATE_LIGHT_MODAL",
                      payload: false,
                    })
                  }
                  hasButton="true"
                  color="blue"
                >
                  <strong
                    style={{
                      fontSize: "14px",
                      textAlign: "center !important",
                      marginBottom: "30px",
                    }}
                  >
                    Are you sure you wish to proceed with the following
                    configurations?
                  </strong>
                  <p className="font-red">
                    [Note] Configuring Light Override will disable all Lighting
                    Profiles.
                  </p>

                  <div className="d-flex flex-row justify-content-start mt-4">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      form="lightForm"
                      className="mx-2"
                    >
                      Confirm
                    </Button>

                    <Button
                      variant="outlined"
                      color="inherit"
                      size="small"
                      className="mx-2"
                      onClick={() =>
                        modalDispatch({
                          type: "UPDATE_LIGHT_MODAL",
                          payload: false,
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </ModalOverlay>

                {/* Delete Light Modal */}
                <ModalOverlay
                  header="Confirm Delete Light"
                  isOpen={modalState.delete_light}
                  closeModal={() =>
                    modalDispatch({
                      type: "DELETE_LIGHT_MODAL",
                      payload: false,
                    })
                  }
                  leftBtnAction={lightDeleteHandler}
                  leftBtn="Delete"
                  color="red"
                >
                  <strong style={{ textAlign: "center !important" }}>
                    Are you sure you wish to delete light{" "}
                    {currentLight.displayName}?
                  </strong>
                </ModalOverlay>
                {/* Update Time Modal*/}
                <ModalOverlay
                  header="Confirm Update Time"
                  isOpen={modalState.update_time}
                  closeModal={() =>
                    modalDispatch({ type: "UPDATE_TIME_MODAL", payload: false })
                  }
                  leftBtnAction={syncTimeHandler}
                  leftBtn="Update"
                  color="blue"
                >
                  <strong style={{ textAlign: "center !important" }}>
                    Are you sure you wish to update time for light{" "}
                    {currentLight.displayName}?
                  </strong>
                </ModalOverlay>
                <ModalOverlay
                  header="Confirm MSP Upgrade"
                  isOpen={modalState.MSP_LIGHT_UPGRADE}
                  closeModal={() =>
                    modalDispatch({ type: "MSP_LIGHT_UPGRADE", payload: false })
                  }
                  leftBtnAction={mspUpgradeHandler}
                  leftBtn="Upgrade"
                  color="blue"
                >
                  <strong style={{ textAlign: "center !important" }}>
                    Are you sure you wish to upgrade the MSP Firmware for light{" "}
                    {currentLight.displayName}?
                  </strong>
                </ModalOverlay>
                <ModalOverlay
                  header="Confirm WISUN Upgrade"
                  isOpen={modalState.WISUN_LIGHT_UPGRADE}
                  closeModal={() =>
                    modalDispatch({ type: "WISUN_LIGHT_UPGRADE", payload: false })
                  }
                  leftBtnAction={wisunUpgradeHandler}
                  leftBtn="Upgrade"
                  color="blue"
                >
                  <strong style={{ textAlign: "center !important" }}>
                    Are you sure you wish to upgrade the WISUN Firmware for light{" "}
                    {currentLight.displayName}?
                  </strong>
                </ModalOverlay>
                {/* Delete Profile Modal */}
                <ModalOverlay
                  header="Confirm Delete Profile"
                  isOpen={modalState.delete_profile}
                  closeModal={() =>
                    modalDispatch({
                      type: "DELETE_PROFILE_MODAL",
                      payload: false,
                    })
                  }
                  leftBtnAction={handleDeleteProfile}
                  leftBtn="Delete"
                  color="red"
                >
                  <strong style={{ textAlign: "center !important" }}>
                    Are you sure you wish to delete this{" "}
                    {modalState.selected ? modalState.selected.group : null}{" "}
                    Profile?
                  </strong>
                </ModalOverlay>
                {/* Confirm Activate Light Test Modal */}
                <ModalOverlay
                  header="Confirm Activate Light Test"
                  isOpen={modalState.activate_test}
                  closeModal={() =>
                    modalDispatch({
                      type: "ACTIVATE_TEST_MODAL",
                      payload: false,
                    })
                  }
                  leftBtnAction={activateTestHandler}
                  leftBtn="Confirm"
                  color="green"
                >
                  <strong style={{ textAlign: "center !important" }}>
                    Do you wish to activate automatic test for{" "}
                    {currentLight.displayName} ?
                  </strong>
                </ModalOverlay>
                {/* Clear Modal */}
                {/* <ModalOverlay
              header="Confirm Clear Profiles"
              isOpen={modalState.clear}
              closeModal={() =>
                modalDispatch({ type: "CLEAR_MODAL", payload: false })
              }
              leftBtnAction={clearProfile}
              leftBtn="Clear"
              color="red"
            >
              <strong style={{ textAlign: "center !important" }}>
                WARNING: Clearing all profiles will initiate the sending of LIVE
                MQTT data to selected light: &nbsp;
                {currentLight !== null &&
                currentLight !== undefined &&
                currentLight.displayName !== undefined
                  ? currentLight.displayName
                  : ""}
              </strong>
            </ModalOverlay> */}
                {/* Disable Modal */}
                <ModalOverlay
                  header="Confirm Disable All Profiles"
                  isOpen={modalState.disable}
                  closeModal={() =>
                    modalDispatch({ type: "DISABLE_MODAL", payload: false })
                  }
                  leftBtnAction={() => {
                    profileDispatch({ type: "DISABLE_ALL_PROFILES" });
                    disableTimelineData();
                    modalDispatch({ type: "DISABLE_MODAL", payload: false });
                  }}
                  leftBtn="Disable"
                  color="yellow"
                >
                  <strong style={{ textAlign: "center !important" }}>
                    WARNING: Disabling all profiles will initiate the sending of
                    LIVE MQTT data to selected light: &nbsp;
                    {currentLight !== null &&
                    currentLight !== undefined &&
                    currentLight.displayName !== undefined
                      ? currentLight.displayName
                      : ""}
                  </strong>
                  <p>
                    Do you wish to disable all profiles for the selected light?
                  </p>
                </ModalOverlay>
                {/* Create Modal */}
                <ModalOverlay
                  header="Create New Profile"
                  isOpen={modalState.create}
                  keyboardToggle={false}
                  closeModal={() => {
                    modalDispatch({ type: "CREATE_MODAL", payload: false });
                  }}
                  color="blue"
                  displayUpdate="false"
                  hasButton="false"
                >
                  <div className="profile-choose my-2">
                    <div className="col-md-2">Choose a Profile:</div>
                    <div className="col-md-10">
                      <select
                        id="selected-profile"
                        onChange={profileChangeHandler}
                      >
                        <option key={0} value={0}>
                          -Select Profile Type-
                        </option>
                        <option key={1} value={1}>
                          Timer Profile
                        </option>
                        {profileState.daylight &&
                        profileState.daylight.length < 1 ? (
                          <option key={2} value={2}>
                            Daylight Profile
                          </option>
                        ) : null}
                        <option key={3} value={3}>
                          Luminance Profile
                        </option>
                        <option key={4} value={4}>
                          Dimming Profile
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="span-text-alert mt-5">
                    <span>
                      [Note] Live MQTT will be sent to smartlight to update
                      profiles by clicking <b>Create</b>
                    </span>
                  </div>
                  <Divider />

                  {profileState.show_timer && (
                    <TimerForm
                      light={currentLight}
                      onSubmit={handleCreateProfile}
                      closeModal={() =>
                        modalDispatch({ type: "CREATE_MODAL", payload: false })
                      }
                    />
                  )}
                  {profileState.show_luminance && (
                    <LuminanceForm
                      light={currentLight}
                      onSubmit={handleCreateProfile}
                      closeModal={() =>
                        modalDispatch({ type: "CREATE_MODAL", payload: false })
                      }
                    />
                  )}
                  {profileState.show_daylight && (
                    <DaylightForm
                      light={currentLight}
                      onSubmit={handleCreateProfile}
                      closeModal={() =>
                        modalDispatch({ type: "CREATE_MODAL", payload: false })
                      }
                    />
                  )}
                  {profileState.show_dimming && (
                    <DimmingForm
                      light={currentLight}
                      onSubmit={handleCreateProfile}
                      closeModal={() =>
                        modalDispatch({ type: "CREATE_MODAL", payload: false })
                      }
                    />
                  )}
                </ModalOverlay>
              </AccordionDetails>
            </Accordion>

            <div className="d-flex flex-row justify-content-center my-4">
              <Button
                variant="contained"
                color="success"
                className="mx-2"
                onClick={() => {
                  if (!isValid)
                    toast.error("Please enter only allowed values.");
                  else {
                    modalDispatch({
                      type: "UPDATE_LIGHT_MODAL",
                      payload: true,
                    });
                  }
                }}
              >
                <strong disabled={!isValid ? true : false}>
                  {isSubmitting ? "LOADING" : "SUBMIT"}
                </strong>
              </Button>
              <Button
                className="mx-2"
                variant="outlined"
                color="inherit"
                onClick={() => {
                  navigate("/manage/light", { replace: true });
                }}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div>
          <span>Selected light are not found. Return to the </span>
          <a href="/manage/light">previous page.</a>
        </div>
      )}
    </Header>
  );
};

export default LightInfo;
