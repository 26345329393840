import { useState, useEffect, useCallback } from "react";
import useAxiosInstance from "hook/useAxiosInstance";

const useFloorPlan = (floorId) => {
  const axiosInstance = useAxiosInstance();
  const [modalOpen, setModalOpen] = useState(false);
  const [floorPlanUrl, setFloorPlanUrl] = useState("");

  const getFloorPlan = useCallback(async (floorId) => {
    try {
      const response = await axiosInstance({
        url: `${process.env.REACT_APP_API_ADMIN}/api/admin/floor/get/floorPlan/${floorId}`,
        method: "GET",
        responseType: "blob", // important
      });

      if (response?.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        setFloorPlanUrl(url);
      } else {
        setFloorPlanUrl("");
        // toast.error("Error retrieving floorplan. Please try again later.");
      }
    } catch (error) {
      setFloorPlanUrl("");
    }
  }, [axiosInstance]);

  useEffect(() => {
    if (floorId) getFloorPlan(floorId);
  }, [floorId, getFloorPlan]);

  return { floorPlanUrl, modalOpen, setModalOpen };
};

export default useFloorPlan;
