import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Divider, Button } from "@mui/material";
import { Loader, TagPicker } from "rsuite";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";

import {
  ArrowClockwise,
  PlusSquare,
  ListColumns,
  FiletypeJpg,
} from "react-bootstrap-icons";
import useAxiosInstance from "hook/useAxiosInstance";

import LightForm from "./LightForm";
import LightColumn from "./LightColumn";

import Header from "../Shared/Header";
import Floorplan from "../Shared/Floorplan";
import ModalOverlay from "../Shared/ModalOverlay";
import EnhancedTable from "Component/Shared/EnhancedTable";

import {
  blockChangeHandler,
  communityChangeHandler,
  fetchEntityData,
  floorChangeHandler,
  lightChangeHandler,
} from "state/entity/entityActions";
import useFloorPlan from "hook/useFloorPlan";
import Selector from "Component/Shared/Selector";
import { UPDATE_LIGHT } from "state/entity/entitySlice";

import "./LightStatus.css";

const path = ["Manage Light"];

const DEFAULT_COLUMN = [
  "displayName",
  "sensorId",
  "lightingControl",
  "lightStatus",
];

const LightStatus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();
  const role = useSelector((state) => state.auth.role);
  const userId = useSelector((state) => state.auth.userId);

  const {
    loading,
    communityList,
    blockList,
    floorList,
    lightList,
    selectedCommunity,
    selectedBlock,
    selectedFloor,
    selectedLight,
  } = useSelector((state) => state.entity);

  const [pageLoading, setPageLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [currLightList, setCurrLightList] = useState(lightList);

  // For modal overlay
  const [modalOpen, setModalOpen] = useState(false);
  const [createLightModalOpen, setCreateLightModalOpen] = useState(false);

  //For floorplan display
  const {
    floorPlanUrl,
    modalOpen: floorPlanModalOpen,
    setModalOpen: setFloorPlanModalOpen,
  } = useFloorPlan(selectedFloor);

  const [lightColumn, setLightColumn] = useState();
  const [columnChange, setColumnChange] = useState(false);
  const [columnKeys, setColumnKeys] = useState(
    LightColumn.map((col) => DEFAULT_COLUMN.includes(col.id) && col.id)
  );

  useEffect(() => {
    setCurrLightList(lightList);
  }, [lightList]);

  useEffect(() => {
    dispatch(fetchEntityData());
  }, []);

  useEffect(() => {
    if (columnKeys) {
      setLightColumn(
        [...LightColumn].filter((col) => columnKeys.includes(col.id))
      );
    }
  }, [columnKeys]);

  const communityChangeFn = (e) => {
    dispatch(communityChangeHandler(e.target.value));
  };

  const blockChangeFn = (e) => {
    dispatch(blockChangeHandler(e.target.value));
  };

  const floorChangeFn = (e) => {
    dispatch(floorChangeHandler(e.target.value));
  };

  const getLightStatusHandler = async (event) => {
    event.preventDefault();
    setPageLoading(true);
    let checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let data = {
      lightId: selectedLight,
      floorId: selectedFloor,
    };
    checkboxes.forEach((checkbox) => (data[checkbox.name] = checkbox.checked));

    try {
      let response = await axiosInstance.post(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/status`,
        data
      );
      // if (response?.status === 200) toast.success("Submitted MQTT.");
      setPageLoading(false);
      setModalOpen(false);
    } catch {
      toast.error("Failed retrieving status.");
      setPageLoading(false);
      setModalOpen(false);
    }
  };

  const refreshLightStatusHandler = async (event) => {
    event.preventDefault();
    setTableLoading(true);

    let response = [];
    try {
      if (selectedLight > 0) {
        response = await axiosInstance.get(
          `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/${selectedLight}`
        );
        setCurrLightList([response.data]);
      } else if (selectedLight <= 0 && selectedFloor <= 0) {
        response = await axiosInstance.get(
          `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/block/${selectedBlock}`
        );
        setCurrLightList(response.data);
      } else {
        response = await axiosInstance.get(
          `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/get/floor/${selectedFloor}`
        );
        setCurrLightList(response.data);
      }
    } catch (err) {
      setCurrLightList([]);
      dispatch(UPDATE_LIGHT([]));
    }
    setTableLoading(false);
  };

  const lightCreateHandler = async (data) => {
    await axiosInstance
      .post(
        `${process.env.REACT_APP_API_SMARTLIGHT}/api/smartlight/create`,
        data
      )
      .then((response) => {
        if (response.status === 201) toast.success("Created light.");
      })
      .catch(function (error) {
        toast.error(error?.response?.data);
        setCreateLightModalOpen(false);
      });

    setCreateLightModalOpen(false);
    refreshLightStatusHandler();
  };

  const openModalHandler = (event) => {
    event.preventDefault();
    let validateCheckBox = false;
    let checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) validateCheckBox = true;
    });

    if (!validateCheckBox) {
      toast.error("You must select light status to continue.");
      setPageLoading(false);
      return;
    } else if (selectedFloor <= 0 || selectedLight < 0) {
      toast.error("You must select floor and/or light to continue.");
      setPageLoading(false);
      return;
    } else setModalOpen(true);
  };

  const checkAllBoxHandler = (event) => {
    event.preventDefault();
    let checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = true;
    });
  };

  return (
    <React.Fragment>
      {pageLoading && (
        <div rows={8} className="spinner-overlay">
          <Loader
            center
            content="Retrieving data..."
            inverse
            backdrop
            size="lg"
          />
        </div>
      )}

      <ModalOverlay
        header="Confirm Submit General Configuration"
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        leftBtnAction={getLightStatusHandler}
        leftBtn="Submit"
        color="blue"
      >
        <div style={{ textAlign: "center !important" }}>
          Retrieving the light status will trigger MQTT messages to the light.
          Do you want to continue?
        </div>
      </ModalOverlay>

      <ModalOverlay
        header="Create Smartlight"
        isOpen={createLightModalOpen}
        closeModal={() => setCreateLightModalOpen(false)}
        hasButton="false"
        width="90%"
        height="90%"
        margin="1% 5% 0 5%"
      >
        <LightForm
          type="create"
          userId={userId}
          closeModal={() => setCreateLightModalOpen(false)}
          onSubmit={lightCreateHandler}
        />
      </ModalOverlay>

      {floorPlanUrl !== "" && (
        <Floorplan
          floorplanOpen={floorPlanModalOpen}
          closeFloorplan={setFloorPlanModalOpen}
          floorplanUrl={floorPlanUrl}
        />
      )}

      <Header title="Manage Light" path={path} className="lightlist">
        <div className="lightlist_container col-md-12">
          <div className="d-flex flex-column col-md-6 pe-4 mb-4">
            <div className="mx-2 my-2">
              <Selector
                size="small"
                label="Community"
                loading={loading}
                selected={selectedCommunity}
                onChange={communityChangeFn}
                data={communityList}
              />
            </div>

            <div className="mx-2 my-2">
              <Selector
                size="small"
                label="Block"
                loading={loading}
                selected={selectedBlock}
                onChange={blockChangeFn}
                data={blockList}
              />
            </div>

            <div className="d-flex flex-row justifycontent-between mx-2 my-2">
              <Selector
                size="small"
                label="Floor"
                loading={loading}
                selected={selectedFloor}
                onChange={floorChangeFn}
                data={floorList}
              />

              {!loading &&
                floorList &&
                floorList.length > 0 &&
                floorPlanUrl && (
                  <FiletypeJpg
                    color="blue"
                    className="m-auto ms-2"
                    style={{
                      fontSize: "200%",
                      cursor: "pointer",
                    }}
                    onClick={() => setFloorPlanModalOpen(true)}
                  />
                )}
            </div>

            <div className="mx-2 my-2">
              <Selector
                size="small"
                label="Light"
                loading={loading}
                showAllOptions={true}
                selected={selectedLight}
                onChange={(e) => dispatch(lightChangeHandler(e.target.value))}
                data={lightList}
              />
            </div>
          </div>
          <div className="lightlist_panel-divider">
            <Divider
              style={{ height: "90%", marginRight: "10px" }}
              orientation="vertical"
            />
          </div>
          <div
            className="lightlist_right-panel col-md-6"
            style={{ padding: "10px", paddingTop: "0px" }}
          >
            <strong>Select configuration/status: </strong>
            <div
              className="lightlist_right-panel-form"
              style={{ padding: "15px" }}
            >
              <Form>
                <Row>
                  <Col style={{ padding: "10px" }}>
                    <Form.Check
                      type="checkbox"
                      name="lightingControl"
                      label="Lighting Control"
                    />
                    <Form.Check
                      type="checkbox"
                      name="lightIntensity"
                      label="Light Intensity"
                    />
                    <Form.Check
                      type="checkbox"
                      name="motionSensitivity"
                      label="Motion Sensitivity"
                    />
                    <Form.Check
                      type="checkbox"
                      name="timerProfile"
                      label="Timer Profile"
                    />
                    <Form.Check
                      type="checkbox"
                      name="luminanceProfile"
                      label="Luminance Profile"
                    />
                  </Col>

                  <Col>
                    <Form.Check
                      type="checkbox"
                      name="photoSensing"
                      label="Photosensor"
                    />
                    <Form.Check
                      type="checkbox"
                      name="softDimming"
                      label="Soft Dimming"
                    />
                    <Form.Check
                      type="checkbox"
                      name="timeDelay"
                      label="Time Delay"
                    />
                    <Form.Check
                      type="checkbox"
                      name="daylightProfile"
                      label="Daylight Profile"
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      name="installDate"
                      label="Install Date"
                    />
                    <Form.Check
                      type="checkbox"
                      name="testSchedule"
                      label="Test Schedule"
                    />
                    <Form.Check
                      type="checkbox"
                      name="lightStatus"
                      label="Light Status"
                    />
                    <Form.Check
                      type="checkbox"
                      name="dimmingProfile"
                      label="Dimming Profile"
                    />
                  </Col>
                </Row>
                <Col className="mt-2">
                  <Button
                    id="submit"
                    size="small"
                    type="submit"
                    color="success"
                    variant="contained"
                    onClick={openModalHandler}
                    style={{
                      marginLeft: "-15px",
                      marginRight: "15px",
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    id="tickall"
                    size="small"
                    type="button"
                    color="info"
                    variant="contained"
                    onClick={checkAllBoxHandler}
                    style={{ marginRight: "15px" }}
                  >
                    Tick All
                  </Button>
                  <Button
                    size="small"
                    type="reset"
                    color="inherit"
                    variant="outlined"
                    style={{ width: "80px", marginRight: "10px" }}
                  >
                    Clear
                  </Button>
                </Col>
              </Form>
            </div>
          </div>
        </div>

        <Divider
          variant="fullWidth"
          style={{ margin: "1rem auto", marginTop: "0" }}
        />

        <h4 className="me-4" style={{ margin: "10px 5px" }}>
          Lighting List
        </h4>

        {columnChange && (
          <div className="w-100 p-2">
            <div className="mb-2" style={{ fontWeight: "bold" }}>
              Column:
            </div>
            <TagPicker
              valueKey="id"
              value={columnKeys}
              labelKey="label"
              data={LightColumn}
              onChange={setColumnKeys}
              cleanable={false}
            />
          </div>
        )}
        <EnhancedTable
          loading={tableLoading}
          headCells={lightColumn ? lightColumn : []}
          data={currLightList}
          onRowClick={(event) => {
            const selectedRow = event.currentTarget;
            const rowData = JSON.parse(selectedRow.getAttribute("data-custom"));
            navigate(`/manage/light/configuration`, {
              replace: true,
              state: rowData,
            });
          }}
        >
          <div className="d-flex flex-row">
            {(role === "ROLE_ADMIN" || role === "ROLE_AREA_ADMIN") && (
              <div className="lighting-list_button-container">
                <Button
                  size="small"
                  type="button"
                  color="success"
                  variant="contained"
                  onClick={() => setCreateLightModalOpen(true)}
                >
                  <PlusSquare className="mx-2" />
                  <span className="button-title">Create</span>
                </Button>
              </div>
            )}

            <div className="lighting-list_button-container">
              <Button
                size="small"
                type="button"
                color="info"
                variant="contained"
                onClick={() =>
                  columnChange ? setColumnChange(false) : setColumnChange(true)
                }
              >
                <ListColumns className="mx-2" />
                <span className="button-title">
                  {columnChange ? "Hide" : "Column"}{" "}
                </span>
              </Button>
            </div>

            <div className="lighting-list_button-container">
              <Button
                size="small"
                type="button"
                color="warning"
                variant="contained"
                onClick={refreshLightStatusHandler}
              >
                <ArrowClockwise className="mx-2" value="Refresh" />
                <span className="button-title">Refresh</span>
              </Button>
            </div>
          </div>
        </EnhancedTable>

        {/* <Table
          autoHeight
          wordWrap
          bordered
          onRowClick={(rowData) => {
            navigate(`/manage/light/configuration`, {
              replace: true,
              state: rowData,
            });
          }}
          headerHeight={55}
          data={
            result?.length > 0
              ? result
              : searchValue === ""
              ? currLightList
              : result
          }
          loading={loading ? loading : isLoading ? isLoading : false}
        >
          {columns.map((column) => {
            const { key, label, ...rest } = column;
            return (
              <Column {...rest} key={key} style={{ cursor: "pointer" }}>
                <HeaderCell>{label}</HeaderCell>
                <Cell dataKey={key} />
              </Column>
            );
          })}
        </Table> */}

        {/* <div style={{ padding: 20, marginBottom: "40px" }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={["total", "-", "limit", "|", "pager"]}
            total={
              result?.length > 0
                ? result?.length
                : searchValue === ""
                ? currLightList?.length
                : result?.length
            }
            limitOptions={[10, 25, 50, 100]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div> */}
      </Header>
    </React.Fragment>
  );
};

export default LightStatus;
