import React from "react";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  TodayMarker,
  DateHeader,
} from "react-calendar-timeline";
import moment from "moment";

const visibleTimeStart = moment()
  .startOf("day")
  .toDate();
const visibleTimeEnd = moment()
  .startOf("day")
  .add(1, "day")
  .toDate();

const defaultTimeStart = moment()
  .startOf("day")
  .toDate();
const defaultTimeEnd = moment()
  .startOf("day")
  .add(1, "day")
  .toDate();

var groups = [
  { id: "Timer", title: "Timer", "stackItems?": "true" },
  { id: "Daylight", title: "Daylight" },
  { id: "Dimming", title: "Dimming" },
  // { id: "Luminance", title: "Luminance" },
];

var keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "group",
  itemTimeStartKey: "start",
  itemTimeEndKey: "end",
  groupLabelKey: "title",
};

const ProfileTimeline = (props) => {
  const handleNewItemOrderWeekday = (itemId, dragTime, newGroupOrder) => {
  };

  const handleItemSelectWeekday = (itemId, e, time) => {
    props.handleClickItem(itemId, e, time, "Weekday");
  };

  const handleItemSelectWeekend = (itemId, e, time) => {
    props.handleClickItem(itemId, e, time, "Weekend");
  };

  return (
    <Timeline
      groups={groups}
      items={props.data}
      keys={keys}
      sidebarContent={<div>Above The Left</div>}
      itemsSorted
      itemTouchSendsClick={false}
      lineHeight={35}
      itemHeightRatio={0.75}
      showCursorLine={true}
      canMove={false}
      canResize={true}
      visibleTimeStart={Date.parse(visibleTimeStart)}
      defaultTimeStart={defaultTimeStart}
      visibleTimeEnd={Date.parse(visibleTimeEnd)}
      defaultTimeEnd={defaultTimeEnd}
      onItemMove={handleNewItemOrderWeekday}
      onItemClick={
        props.type === "Weekend"
          ? handleItemSelectWeekend
          : handleItemSelectWeekday
      }
    >
      <TodayMarker interval={2000}>
        {({ styles, date }) => (
          <div
            style={{
              ...styles,
              backgroundColor: "red",
              zIndex: "100",
            }}
          />
        )}
      </TodayMarker>
      <TimelineHeaders
        className="sticky"
        style={{ backgroundColor: "#015770" }}
      >
        <SidebarHeader
          children={() => {
            return (
              <div
                style={{
                  color: "white",
                  margin: "auto",
                }}
              >
                Profile/Hour
              </div>
            );
          }}
        />
        <DateHeader unit="hour" height={35} />
      </TimelineHeaders>
    </Timeline>
  );
};

export default ProfileTimeline;
