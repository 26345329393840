import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from "rsuite";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import useAxiosInstance from "hook/useAxiosInstance";
import { logout } from "state/auth/authSlice";
import { Justify } from "react-bootstrap-icons";
import Tooltip from "@material-ui/core/Tooltip";

import Badge from "rsuite/Badge";
import NoticeIcon from "@rsuite/icons/Notice";
import ExitIcon from "@rsuite/icons/Exit";
import GearIcon from "@rsuite/icons/Gear";
import IconButton from "rsuite/IconButton";
import FaultNotificationItem from "./FaultNotificationItem";
import Logo from "../../images/AztechWhiteLogo.png";
import {
  clearNotification,
  initNotification,
  updateNotification,
} from "state/notification/notificationActions";
import { CollectorSocketContext } from "context/collector-socket-context";
import { SmartlightSocketContext } from "context/smartlight-socket-context";
import { SELECT_NOTIFICATION } from "state/notification/notificationSlice";

import "./TopNavigation.css";

const TopNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const token = useSelector((state) => state.auth.token);
  const userName = useSelector((state) => state.auth.name);
  const numNotifs = 10;

  const [socketConnected, setSocketConnected] = useState(false);

  const {
    subscribe,
    unsubscribe,
    isConnected: isConnectedCollector,
  } = useContext(CollectorSocketContext);

  const { isConnected: isConnectedSmartlight } = useContext(
    SmartlightSocketContext
  );

  const { notificationList: notifications } = useSelector(
    (state) => state.notification
  );

  useEffect(() => {
    if (isConnectedCollector && isConnectedSmartlight) setSocketConnected(true);
    else setSocketConnected(false);
  }, [isConnectedCollector, isConnectedSmartlight]);

  const getMessage = async (data) => {
    const parsedData = JSON.parse(data);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_ADMIN}/api/admin/blockAccess/verify/access/${parsedData.blockPostal}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          toast.warning(() => (
            <div className="ps-2">
              <h6 className="my-2">{parsedData.faultType}</h6>
              <p>
                <strong>Sensor ID:</strong> {parsedData.sensorId}
              </p>
              <p className="my-2">
                <strong>Description: </strong> {parsedData.description}
              </p>
              <a
                href="/logs/fault"
                className="btn btn-primary btn-sm active"
                role="button"
                aria-pressed="true"
              >
                View Fault
              </a>
            </div>
          ));
        }
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    var notificationSubs;
    if (isConnectedCollector)
      notificationSubs = subscribe("/notification/message", getMessage);

    return () => {
      unsubscribe(notificationSubs);
    };
  }, [isConnectedCollector]);

  useEffect(() => {
    dispatch(initNotification());
  }, []);

  const clickNotificationHandler = async (eventKey) => {
    const faultId = eventKey.faultId;
    let fault = notifications.find((item) => item.faultId === faultId);

    if (fault) {
      dispatch(updateNotification(faultId));
      dispatch(SELECT_NOTIFICATION(faultId));
      navigate("/logs/fault", {
        replace: true,
      });
    } else toast.error("Unable to find fault.");
  };

  return (
    <React.Fragment>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container className="topnav-container">
          <Navbar.Brand>
            <img src={Logo} className="mx-2" alt="Aztech Logo" width="100" />
          </Navbar.Brand>
          <div className="topnav-right-container">
            <Tooltip
              title={`Server Connection - ${
                socketConnected ? `Connected` : `Failed`
              }`}
            >
              <div
                className={`m-auto me-4 neon-point ${
                  socketConnected ? `success` : `error`
                }`}
              />
            </Tooltip>
            <div className="topnav-welcome">Welcome, {userName}</div>
            <div className="topnav-edit-profile">
              <Tooltip title="Edit Profile">
                <IconButton
                  padding="0px"
                  onClick={() => navigate("/user/edit", { replace: true })}
                  icon={<GearIcon color="white" />}
                  appearance="link"
                />
              </Tooltip>
            </div>
            <div className="topnav-notifications">
              <div>
                <Dropdown
                  icon={
                    notifications?.length > 0 &&
                    notifications?.filter((fault) => !fault.isRead).length !==
                      0 ? (
                      <Badge
                        content={
                          notifications?.length > 0 &&
                          notifications.filter((fault) => !fault.isRead)
                            .length > 9
                            ? "10+"
                            : notifications?.length > 0 &&
                              notifications?.filter((fault) => !fault.isRead)
                                .length
                        }
                      >
                        <NoticeIcon color="white" />
                      </Badge>
                    ) : (
                      <NoticeIcon color="white" />
                    )
                  }
                  placement="bottomEnd"
                  appearance="link"
                  noCaret
                >
                  <div className="d-flex flex-row justify-content-between p-2">
                    <h5 className="mx-2">Fault Notification</h5>
                    <a
                      className="my-auto mx-2"
                      onClick={() => dispatch(clearNotification())}
                      style={{ zIndex: "1000" }}
                    >
                      Clear All
                    </a>
                  </div>
                  <div className="notification-container">
                    {notifications?.length > 0 && 
                      notifications.map((item, idx) => {
                        if (!item?.isCleared && (idx + 1) <= numNotifs) {
                          return (
                            <FaultNotificationItem
                              key={idx}
                              item={item}
                              onClick={clickNotificationHandler}
                            />
                          );
                        } else return null;
                      })}

                    {notifications?.length > 0 &&
                    notifications?.filter((fault) => !fault.isCleared)
                      .length === 0 ? (
                      <div style={{ padding: "15px" }}>
                        No new notifications
                      </div>
                    ) : (
                      <Dropdown.Item
                        style={{ padding: "15px" }}
                        onClick={() =>
                          navigate("/logs/fault", { replace: true })
                        }
                      >
                        View more
                      </Dropdown.Item>
                    )}
                  </div>
                </Dropdown>
              </div>
            </div>
            <div className="topnav-logout_icon">
              <Tooltip title="Logout">
                <IconButton
                  onClick={() => {
                    dispatch(logout());
                    navigate("/", { replace: true });
                  }}
                  icon={<ExitIcon color="white" />}
                  appearance="link"
                />
              </Tooltip>
            </div>
          </div>

          <div className="topnav-right-container-mobile">
            <div>
              <Dropdown
                icon={
                  notifications?.length > 0 &&
                  notifications.filter((fault) => !fault.isRead).length !==
                    0 ? (
                    <Badge
                      content={
                        notifications?.length > 0 &&
                        notifications.filter((fault) => !fault.isRead).length >
                          9
                          ? "10+"
                          : notifications?.length > 0 &&
                            notifications.filter((fault) => !fault.isRead)
                              .length
                      }
                    >
                      <NoticeIcon color="white" />
                    </Badge>
                  ) : (
                    <NoticeIcon color="white" />
                  )
                }
                placement="bottomEnd"
                appearance="link"
                noCaret
              >
                <div className="d-flex flex-row justify-content-between">
                  <h5 className="p-2 mx-2">Fault Notification</h5>
                  <a
                    className="my-auto mx-2"
                    onClick={() => dispatch(clearNotification())}
                    style={{ zIndex: "1000" }}
                  >
                    Clear All
                  </a>
                </div>
                <div className="notification-container">
                  {notifications?.length > 0 &&
                    notifications.map((item, idx) => {
                      if (!item?.isCleared) {
                        return (
                          <FaultNotificationItem
                            key={idx}
                            item={item}
                            onClick={clickNotificationHandler}
                          />
                        );
                      } else return null;
                    })}

                  {notifications?.length > 0 &&
                  notifications?.filter((fault) => !fault.isRead).length ===
                    0 ? (
                    <div style={{ padding: "15px" }}>No new notifications</div>
                  ) : (
                    <Dropdown.Item
                      style={{ padding: "15px" }}
                      onClick={() => navigate("/logs/fault", { replace: true })}
                    >
                      Click to see more
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown>
            </div>

            <Dropdown
              icon={<Justify color="white" />}
              placement="bottomEnd"
              appearance="link"
              noCaret
            >
              <Dropdown.Item
                onClick={() => navigate("/user/edit", { replace: true })}
              >
                Edit Profile
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  dispatch(logout());
                  navigate("/", { replace: true });
                }}
              >
                Logout
              </Dropdown.Item>
            </Dropdown>
          </div>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default TopNavigation;
