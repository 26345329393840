import React from "react";
import { Button } from "react-bootstrap";
import { Check, Clock, Lock, X } from "react-bootstrap-icons";

import "./StatusChecklist.css";

const StatusChecklist = ({
  statusTitle,
  // configCount,
  result,
  retry,
  retryMqtt,
  handleConfigCompletion,
}) => {
  return (
    <React.Fragment>
      <h3 className="mx-4 mb-2">
        {statusTitle} 
        {/* {configCount > 0 && " - " + configCount + " remaining"} */}
      </h3>
      {result && result.length > 0 && (
        <div className="d-block mx-2 event-item">
          {result.length > 0 &&
            result.map((item, idx) => {
              return (
                <div key={idx} style={{ lineHeight: "2" }}>
                  {item.commandType === "Set_Ack" ? (
                    <Check
                      color="green"
                      className="mx-2"
                      style={{ fontSize: "150%" }}
                    />
                  ) : item.commandType === "Set_Error" ? (
                    <X
                      color="red"
                      className="mx-2"
                      style={{ fontSize: "150%" }}
                    />
                  ) : item.commandType === "Locked" ? (
                    <Lock
                      color="yellow"
                      className="mx-2"
                      style={{ fontSize: "150%" }}
                    />
                  ) : (
                    <Clock
                      color="grey"
                      className="mx-2"
                      style={{ fontSize: "150%" }}
                    />
                  )}
                  <span>
                    {" "}
                    {item.displayName +
                      " - " +
                      item.eventId +
                      " - " +
                      item.eventType}
                  </span>
                </div>
              );
            })}
        </div>
      )}
      <div className="mx-4">
        {retry && (
          <Button
            variant="warning"
            className="my-2 me-2"
            size="lg"
            onClick={retryMqtt}
          >
            Retry
          </Button>
        )}
        <Button
          variant="secondary"
          size="lg"
          className="my-2 mx-2"
          onClick={handleConfigCompletion}
        >
          Close
        </Button>
      </div>
    </React.Fragment>
  );
};

export default StatusChecklist;