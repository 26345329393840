import Dashboard from "@rsuite/icons/legacy/Dashboard";
import Lightbulb from "@rsuite/icons/legacy/LightbulbO";
import Peoples from "@rsuite/icons/legacy/Peoples";
import Columns from "@rsuite/icons/legacy/Columns";
import BarChart from "@rsuite/icons/legacy/BarChart";
import Trend from "@rsuite/icons/legacy/Trend";
import UserPlus from "@rsuite/icons/legacy/UserPlus";
import UserTimes from "@rsuite/icons/legacy/UserTimes";
import Database from "@rsuite/icons/legacy/Database";
import Plug from "@rsuite/icons/legacy/Plug";
import GearCircle from "@rsuite/icons/legacy/GearCircle";
import FolderFillIcon from "@rsuite/icons/FolderFill";
import TaskIcon from "@rsuite/icons/Task";
import OffIcon from "@rsuite/icons/Off";
import RemindRoundIcon from "@rsuite/icons/RemindRound";

// access dictionary, access overriding applied.
// 1- Admin access ["ROLE_ADMIN"]
// 2- Area Admin access ["ROLE_ADMIN", "ROLE_AREA_ADMIN"]
// 3- Operator access ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR"]
// 4- Viewer access ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"]
const NavData = {
  AdminNav: [
    {
      event: "1",
      trigger: "hover",
      title: "Overview",
      icon: <Columns />,
      placement: "rightStart",
      access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"],
      data: [
        {
          event: "1-1",
          title: "Dashboard",
          icon: <Dashboard />,
          path: "/dashboard",
          access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"],
        },
      ],
    },
    {
      event: "2",
      trigger: "hover",
      title: "Lighting Management",
      icon: <Lightbulb />,
      placement: "rightStart",
      access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"],
      data: [
        {
          event: "2-1",
          title: "Manage Light",
          icon: <Lightbulb />,
          path: "/manage/light",
          access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"],
        },
        {
          event: "2-2",
          title: "Group Configuration",
          icon: <GearCircle />,
          path: "/configuration",
          access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR"],
        },
        // {
        //   event: "2-3",
        //   title: "Manage Profiles",
        //   icon: <Sun />,
        //   path: "/profiles",
        //   access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR"],
        // },
        // {
        //   event: "2-4",
        //   title: "Manage Light Test",
        //   icon: <CodeIcon />,
        //   path: "/manage/light/test",
        //   access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR"],
        // },
      ],
    },
    {
      event: "3",
      trigger: "hover",
      title: "Data Analytics",
      icon: <BarChart />,
      placement: "rightStart",
      access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"],
      data: [
        {
          event: "3-1",
          title: "Motion Detection",
          icon: <Trend />,
          path: "/data/motion",
          access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"],
        },
        // {
        //   event: "3-2",
        //   title: "Photosensor",
        //   icon: <Sun />,
        //   path: "/data/photosensor",
        //   access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"],
        // },
        {
          event: "3-3",
          title: "Energy Consumption",
          icon: <Plug />,
          path: "/data/energy",
          access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"],
        },
      ],
    },
    {
      event: "4",
      trigger: "hover",
      title: "Management Panel",
      icon: <Peoples />,
      placement: "rightStart",
      access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN"],
      data: [
        {
          event: "4-1",
          title: "User Management",
          icon: <UserPlus />,
          path: "/manage/user",
          access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN"],
        },
        {
          event: "4-2",
          title: "Access Management",
          icon: <UserTimes />,
          path: "/block/access",
          access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN"],
        },
        {
          event: "4-3",
          title: "Data Management",
          icon: <Database />,
          path: "/upload",
          access: ["ROLE_ADMIN"],
        },
        // {
        //   event: "4-4",
        //   title: "Firmware Upgrade",
        //   icon: <FileUpload />,
        //   path: "/firmware",
        //   access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN"],
        // },
        {
          event: "4-4",
          title: "Entity Management",
          icon: <FolderFillIcon />,
          path: "/entity",
          access: ["ROLE_ADMIN"],
        },
      ],
    },
    {
      event: "5",
      trigger: "hover",
      title: "Event Logs",
      icon: <TaskIcon />,
      placement: "rightStart",
      access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"],
      data: [
        {
          event: "5-1",
          title: "Events",
          icon: <OffIcon />,
          path: "/logs/event",
          access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"],
        },
        {
          event: "5-2",
          title: "Fault & Alarm",
          icon: <RemindRoundIcon />,
          path: "/logs/fault",
          access: ["ROLE_ADMIN", "ROLE_AREA_ADMIN", "ROLE_OPERATOR", "ROLE_VIEWER"],
        },
      ],
    },
  ],
};

export default NavData;