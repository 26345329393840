import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Container, Header, Content, Footer as FooterContainer } from "rsuite";
import { CircularProgress } from "@mui/material";

import TopNavigation from "Component/Navigation/TopNavigation";
import SideNavigation from "Component/Navigation/SideNavigation";
import Login from "Component/Login/Login";
import ForgetPassword from "Component/Login/ForgetPassword";
import Footer from "Component/Footer/Footer";
import { SmartlightSocketProvider } from "context/smartlight-socket-context";
import { CollectorSocketProvider } from "context/collector-socket-context";
import { useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

const Layout = ({ isLoggedIn, routes }) => {
  const token = useSelector((state) => state.auth.token);

  return (
    <Router>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnHover={true}
        pauseOnFocusLoss={false}
        draggable={true}
        progress={undefined}
        position={toast.POSITION.TOP_RIGHT}
        style={{ zIndex: "10000", marginTop: "3%" }}
      />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forget/password" element={<ForgetPassword />} />
        <Route
          path="/*"
          element={
            isLoggedIn ? (
              <div>
                <CollectorSocketProvider
                  url={`${process.env.REACT_APP_API_COLLECTOR}/ws/collector`}
                  token={token}
                >
                  <SmartlightSocketProvider
                    url={`${process.env.REACT_APP_API_SMARTLIGHT}/ws/smartlight`}
                    token={token}
                  >
                    <Container className="custom-section">
                      <Header>
                        <div>{isLoggedIn && <TopNavigation />}</div>
                      </Header>
                      <Container>
                        {isLoggedIn && <SideNavigation />}
                        <Content className="main-content">
                          <Suspense
                            fallback={
                              <div className="center">
                                <CircularProgress disableShrink />
                              </div>
                            }
                          >
                            {/* <SmartlightSocketProvider
                            url={`${process.env.REACT_APP_API_SMARTLIGHT}/ws/smartlight`}
                            token={token}
                          > */}
                            <Routes>
                              {routes.map((route, index) => (
                                <Route key={index} {...route} />
                              ))}
                              {/* <Route path="*" element={<PageNotFound />} /> */}
                            </Routes>
                            {/* </SmartlightSocketProvider> */}
                          </Suspense>
                          {/* <FooterContainer>
                      <Footer />
                    </FooterContainer> */}
                        </Content>
                      </Container>
                    </Container>
                  </SmartlightSocketProvider>
                </CollectorSocketProvider>
              </div>
            ) : (
              <Login />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default Layout;
