import React from "react";
import { Dropdown } from "rsuite";
import { ExclamationTriangleFill } from "react-bootstrap-icons";

const FaultNotificationItem = ({ item, onClick }) => {
  return (
    <Dropdown.Item
      onSelect={onClick}
      style={{
        border: "1px solid #f5f5f5",
        padding: "18px",
        backgroundColor: item.isRead ? "white" : "#ffcccb",
      }}
      eventKey={item}
    >
      <div className="d-flex flex-row">
        <ExclamationTriangleFill
          className="my-auto p-2"
          color="red"
          size={60}
        />
        <div className="d-flex flex-column mx-4">
          <b
            style={{
              fontSize: "15px",
              textTransform: "underline",
            }}
          >
            Alert for Light {item.lightDisplayName}
          </b>
          <div>{item.blockName + " (" + item.postcode + ") "}</div>
          <div>Light {item.lightDisplayName + " - " + item.eventType}</div>
          <div style={{ color: "dodgerblue" }}>
            {item.reportDateTimeDisplay}
          </div>
        </div>
      </div>
    </Dropdown.Item>
  );
};

export default FaultNotificationItem;
