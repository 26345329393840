import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  sensorId: Yup.string()
    .required("You must enter Light's Sensor ID.")
    .notOneOf(
      ["SmartLighting-Aztech-"],
      "You must fill in complete Light's Sensor ID."
    ),
  lightSN: Yup.number()
    .integer()
    .required("You must enter Light Serial No."),
  displayName: Yup.string().required("You must enter Light's Display Name."),
  lightMACAddress: Yup.string().required("You must enter Light's MAC Address."),
  lightIPAddress: Yup.string().required("You must enter Light's IP Address."),
  blockId: Yup.string()
    .required("You must select block of the light.")
    .notOneOf(["-1"], "You must select block of the light."),
  floorId: Yup.string()
    .required("You must select floor of the light.")
    .notOneOf(["-1"], "You must select floor of the light."),
  gatewayId: Yup.string()
    .required("You must select gateway of the light.")
    .notOneOf(["-1"], "You must select gateway of the light."),
  lightingControl: Yup.string()
    .required("You must select lighting control.")
    .oneOf(
      ["Force_On", "Force_Off", "Auto"],
      "Please select appropriate lighting control."
    ),
  failSafeDelay: Yup.number()
    .integer("Fail safe delay must be between 0 - 3 seconds.")
    .required("You must enter fail safe delay.")
    .min(0, "Minimum fail safe delay allowed is 0 second.")
    .max(3, "Maximum fail safe delay allowed is 3 seconds."),
  holdTime: Yup.number()
    .integer("Hold time must be between 0 - 3600 seconds.")
    .required("You must enter hold time.")
    .min(10, "Minimum hold time allowed is 10 second.")
    .max(3600, "Maximum hold time allowed is 3600 seconds."),
  rampUpTime: Yup.number()
    .integer("Ramp up time must be between 1 - 3 seconds.")
    .required("You must enter ramp up time.")
    .min(1, "Minimum ramp up time allowed is 1 seconds.")
    .max(3, "Maximum ramp up time allowed is 3 seconds."),
  rampDownTime: Yup.number()
    .integer("Ramp down time must be between 1 - 3 seconds.")
    .required("You must enter ramp down time.")
    .min(1, "Minimum ramp down time allowed is 1 seconds.")
    .max(3, "Maximum ramp down time allowed is 3 seconds."),
  photoSensing: Yup.string()
    .required("You must enter photo sensing.")
    .oneOf(["Enable", "Disable"], "Please select appropriate Photo Sensing."),
  photoUpperThreshold: Yup.number()
    .integer("Photosensor Upper Threshold must be between 50 - 1000 lux.")
    .required("You must enter photo upper threshold.")
    .min(50, "Minimum photosensor threshold allowed is 50 lux.")
    .max(1000, "Maximum photosensor threshold allowed is 1000 lux.")
    .moreThan(
      Yup.ref("photoLowerThreshold"),
      "Photosensor Upper Threshold must be more than Lower Threshold."
    ),
  photoLowerThreshold: Yup.number()
    .integer("Photosensor Upper Threshold must be between 50 - 1000 lux.")
    .required("You must enter photo upper threshold.")
    .min(50, "Minimum photosensor threshold allowed is 50 lux.")
    .max(1000, "Maximum photosensor threshold allowed is 1000 lux.")
    .lessThan(
      Yup.ref("photoUpperThreshold"),
      "Photosensor Lower Threshold must be less than Upper Threshold."
    ),
  installDate: Yup.string().required("You must enter install date."),
  motionSensitivity: Yup.string()
    .required("You must enter motion sensitivity.")
    .oneOf(
      ["1", "2", "3", "4", "5"],
      "Please select appropriate motion sensitivity."
    ),
  dimIntensity: Yup.number()
    .integer("Dim intensity must be between 0% - 100%.")
    .required("You must enter dim intensity.")
    .lessThan(
      Yup.ref("brightIntensity"),
      "Dim Intensity must be less than Bright Intensity."
    )
    .min(0, "Minimum percetange is 0%.")
    .max(50, "Maximum percentage is 50%."),
  brightIntensity: Yup.number()
    .required("You must enter bright intensity.")
    .integer("Dim intensity must be between 0% - 100%.")
    .moreThan(
      Yup.ref("dimIntensity"),
      "Bright Intensity must be more than Dim Intensity."
    )
    .min(50, "Minimum percetange is 50%.")
    .max(100, "Maximum percentage is 100%."),
});
