import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown, Sidenav, Sidebar, Nav, Navbar } from "rsuite";

import AngleLeft from "@rsuite/icons/legacy/AngleLeft";
import AngleRight from "@rsuite/icons/legacy/AngleRight";
import NavBarData from "./NavBarData";

import "./SideNavigation.css";

const SideNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = useSelector((state) => state.auth.role);
  const [expand, setExpand] = useState(true);

  useEffect(() => {
    let sidebar = document.querySelector(".sidebarx");
    if (sidebar === null) {
    } else {
      if (!expand) sidebar.style.removeProperty("overflow-y");
      else sidebar.style.overflowY = "scroll";
    }
  }, [expand]);

  useEffect(() => {
    if (window.innerWidth <= 768) setExpand(false);

    // Define a function to handle the resize event
    function handleWindowResize() {
      const innerWidth = window.innerWidth;
      if (innerWidth <= 768) setExpand(false);
      else setExpand(true);
    }

    window.addEventListener("resize", handleWindowResize);

    // if (window.innerWidth <= 460) setExpand(false);
  }, []);

  const NavToggle = ({ expand, onChange }) => {
    return (
      <Navbar appearance="subtle" className="nav-toggle">
        <Nav pullRight>
          <Nav.Item
            onClick={onChange}
            style={{ width: 56, textAlign: "center" }}
          >
            {expand ? <AngleLeft /> : <AngleRight />}
          </Nav.Item>
        </Nav>
      </Navbar>
    );
  };

  return (
    <React.Fragment>
      <Sidebar className="sidebarx" width={expand ? 230 : 50} collapsible>
        <Sidenav
          expanded={expand}
          defaultOpenKeys={["1", "2", "3", "4", "5"]}
          appearance="subtle"
          style={{'&hover' : {color: '#fff'}}}
        >
          <Sidenav.Body>
            <Nav>
              {NavBarData.AdminNav.map((item, idx) => {
                if (item?.access.some((ele) => ele == role)) {
                  return (
                    <Dropdown
                      key={idx}
                      eventKey={item.event}
                      trigger={item.trigger}
                      title={item.title}
                      icon={item.icon}
                      placement={item.placement}
                    >
                      {item.data &&
                        item.data.map((child, idx) => {
                          if (child?.access.some((ele) => ele === role)) {
                            return (
                              <Dropdown.Item
                                key={idx}
                                eventKey={child.event}
                                icon={child.icon}
                                onSelect={() => {
                                  navigate(child.path, { state: location });
                                }}
                              >
                                {child.title}
                              </Dropdown.Item>
                            );
                          } else return null;
                        })}
                    </Dropdown>
                  );
                } else return null;
              })}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
        {window.innerWidth > 460 && (
          <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
        )}
      </Sidebar>
    </React.Fragment>
  );
};

export default SideNavigation;