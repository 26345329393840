import React from "react";
import { Card } from "react-bootstrap";

const CardBox = (props) => {
  return (
    <React.Fragment>
      <Card className={`card ${props.className || null}`} style={props.style} border={props.border}>
        <Card.Header as="h6">
          {props.icon} {props.title}
        </Card.Header>
        <Card.Body>{props.children}</Card.Body>
        {props.footer && <Card.Footer>{props.footer}</Card.Footer>}
      </Card>
    </React.Fragment>
  );
};

export default CardBox;
