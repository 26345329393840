import React, { useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import RefreshIcon from "@mui/icons-material/Refresh";

import "./ReadingCard.css";

const ReadingCard = ({ title, reading, loading, hasButton, onClick }) => {
  const prevProps = useRef();

  useEffect(() => {
    if (prevProps.current !== reading) {
      // console.log(prevProps.current);
      // console.log(reading);
    }

    prevProps.current = reading;
  }, [reading]);

  return (
    <Card
      bg="light"
      className="readingcard mb-3 mx-2"
      style={{ flex: "1 0 30%", minHeight: "150px" }}
    >
      <Card.Body>
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-column w-100">
            <span>{title}</span>
            <div className="d-flex flex-row justify-content-between">
              <h3
                className={
                  loading === true
                    ? "live-text-transition readingcard-body"
                    : "readingcard-body"
                }
              >
                {!reading?.reportDateTime
                  ? "-"
                  : title === "Daylight Reading (lux)"
                  ? reading?.daylightValue
                  : title === "Motion Reading (count)"
                  ? reading?.motionCount
                  : title === "Light Status"
                  ? reading?.lightStatus
                  : title === "Power Value (Wh)"
                  ? reading?.powerValue
                  : title === "Light Usage (hour)"
                  ? reading?.lightUsage
                  : title === "Energy Usage (Wh)"
                  ? reading?.energyUsage
                  : "-"}
              </h3>
              {hasButton && (
                <RefreshIcon onClick={onClick} style={{ cursor: "pointer" }} />
              )}
            </div>
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        Last update: <span>{reading?.reportDateTime || "-"}</span>
      </Card.Footer>
    </Card>
  );
};

export default ReadingCard;