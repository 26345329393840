import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Card, Button, Row, Col, FormControl } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import axios from "axios";

import { toast } from "react-toastify";
import Logo from "../../images/logo.png";
import "./ForgetPassword.css";

// var md5 = require("md5");

const ForgetPassword = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const userId = useSelector((state) => state.auth.userId);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const [otpButtonMessage, setOtpButtonMessage] = useState("");
  const [otpSubmitted, setOtpSubmitted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const validationSchemaOTP = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
  });

  const validationSchemaForm = Yup.object().shape({
    otp: Yup.string().required("Please enter a valid OTP number."),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(16, "Password must not exceed 16 characters"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const {
    control: controlOTP,
    handleSubmit: handleSubmitOTP,
    formState: { errors: otpError },
  } = useForm({
    resolver: yupResolver(validationSchemaOTP),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemaForm),
  });

  useEffect(() => {
    if (isLoggedIn && userId && location.state)
      navigate(location?.state?.from?.pathname);
    else if (isLoggedIn && userId) navigate("/map", { replace: true });
  }, [isLoggedIn, userId, location.state, navigate]);

  const handleOTPRequest = async (data) => {
    setOtpButtonMessage(" Sending...");
    setButtonLoading(true);
    document.getElementById("otp-button").disabled = true;

    const reqBody = btoa(data.email + ": "); // base64 encoding
    const uninterceptedAxiosInstance = axios.create(); // create new axios without interceptors
    const response = await uninterceptedAxiosInstance
      .get(process.env.REACT_APP_API_USER + "/api/user/requestOTP", {
        headers: {
          Authorization: "Basic " + reqBody,
          "Content-Type": "application/json",
        },
      })
      .catch(function(error) {
        toast.error(error.response.data);
        setButtonLoading(false);
        document.getElementById("otp-button").disabled = false;
      });
    setCurrentUser(data.email);

    if (
      response !== null &&
      response !== undefined &&
      response.status === 200
    ) {
      toast.success(response.data);
      setTimeLeft(60);
      setOtpButtonMessage(" Request OTP in ");
      setOtpSubmitted(true);
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(0);
      setButtonLoading(false);
      document.getElementById("otp-button").disabled = false;
    }
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const forgetPasswordHandler = async (data) => {
    // if (otpSubmitted) {
    //   // const reqBody = btoa(currentUser + ":" + md5(data.password)); // base64 encoding
    //   const uninterceptedAxiosInstance = axios.create(); // create new axios without interceptors
    //   const response = await uninterceptedAxiosInstance
    //     .get(
    //       process.env.REACT_APP_API_USER +
    //         "/api/user/change/password/" +
    //         data.otp,
    //       {
    //         headers: {
    //           Authorization: "Basic " + reqBody,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     )
    //     .catch(function(error) {
    //       console.log(error.response.data);
    //       toast.error(error.response.data);
    //     });

    //   if (
    //     response !== null &&
    //     response !== undefined &&
    //     response.status === 200
    //   ) {
    //     toast.success(response.data);
    //     document.getElementById("forgetPasswordForm").reset();

    //     // setTimeout(() => {
    //     navigate("/", { replace: true });
    //     // }, 2500);
    //   }
    // } else {
    //   toast.error(
    //     "You must request an OTP using your email to retrieve your password."
    //   );
    //   setInterval(() => {
    //     setOtpSubmitted(false);
    //   }, 5000);
    // }
  };

  return (
    <React.Fragment>
      {/* <Toast /> */}
      <div className="background-img">
        <div className="login-container">
          <Card className="login-card">
            <Card.Header className="login-card_header">
              <Card.Title>
                <img src={Logo} alt="Aztech Logo" width="120" />
                <h5>LMS v2.0.3 - Forget Password</h5>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form
                onSubmit={handleSubmitOTP(handleOTPRequest)}
                id="getOTPForm"
                className="mb-4"
              >
                <Form.Group>
                  <Form.Label className="ms-auto col">Email</Form.Label>
                  <div className="d-flex flex-row col-md-12 justify-content-between">
                    <Col className="col-md-9">
                      <Controller
                        control={controlOTP}
                        name="email"
                        render={({ field: { onChange, value } }) => (
                          <FormControl
                            placeholder="abc@example.com"
                            aria-label="User Email"
                            aria-describedby="basic-addon2"
                            id="email"
                            isInvalid={otpError.email}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />

                      <Form.Control.Feedback type="invalid">
                        {otpError.email?.message}
                      </Form.Control.Feedback>
                    </Col>
                    <Col
                      className="col-md-2 ms-3"
                      style={{ height: "100%", verticalAlign: "middle" }}
                    >
                      <Controller
                        control={control}
                        render={() => (
                          <Button
                            id="otp-button"
                            className="m-auto px-2"
                            type="submit"
                            size="sm"
                            variant="outline-primary"
                          >
                            {buttonLoading && (
                              <span className="spinner-border spinner-border-sm mr-1" />
                            )}

                            {!buttonLoading
                              ? " Request OTP"
                              : timeLeft === 0
                              ? otpButtonMessage
                              : otpButtonMessage + timeLeft + "s."}
                          </Button>
                        )}
                      />
                    </Col>
                  </div>
                </Form.Group>
              </Form>

              <Form
                onSubmit={handleSubmit(forgetPasswordHandler)}
                id="forgetPasswordForm"
                className="mt-4"
              >
                <Form.Group className="mb-3 ms-auto col" controlId="otp">
                  <Form.Label>One Time Password (OTP)</Form.Label>
                  <Controller
                    control={control}
                    name="otp"
                    render={({ field: { onChange, value } }) => (
                      <Form.Control
                        onChange={onChange}
                        value={value}
                        isInvalid={errors.otp}
                        type="text"
                        placeholder="Enter OTP"
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.otp?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Row>
                  <Form.Group className="mb-2 mx-3 col" controlId="password">
                    <Form.Label>Enter Password</Form.Label>
                    <Controller
                      control={control}
                      name="password"
                      render={({ field: { onChange, value } }) => (
                        <Form.Control
                          onChange={onChange}
                          value={value}
                          isInvalid={errors.password}
                          type="password"
                          placeholder="Enter Password"
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-2 mx-3 col"
                    controlId="confirmPassword"
                  >
                    <Form.Label>Reconfirm New Password</Form.Label>
                    <Controller
                      control={control}
                      name="confirmPassword"
                      render={({ field: { onChange, value } }) => (
                        <Form.Control
                          onChange={onChange}
                          value={value}
                          isInvalid={errors.confirmPassword}
                          type="password"
                          placeholder="Re-enter Password"
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <br />
                <div className="login-card_bottom-container">
                  <Button variant="secondary" type="button" size="sm" href="/">
                    Back
                  </Button>
                  &nbsp;&nbsp;
                  <Button variant="primary" type="submit" size="sm">
                    Confirm
                  </Button>
                </div>
              </Form>
            </Card.Body>
            <Card.Footer className="login-card_footer">
              <Card.Link href="mailto:aztech.dev@aztech.com">
                Unable to change password? Email Aztech
              </Card.Link>
            </Card.Footer>
          </Card>
        </div>
      </div>
      <br />
      <br />
    </React.Fragment>
  );
};

export default ForgetPassword;
