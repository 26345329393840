import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: false,
    userId: null,
    name: null,
    role: null,
    token: null,
    tokenExp: null,
  },
  reducers: {
    login: (state, action) => {
      let decodedToken = jwt_decode(action.payload);
      return {
        ...state,
        userId: decodedToken.userId,
        name: decodedToken.sub,
        role: decodedToken.role[0].authority,
        token: action.payload,
        tokenExp: decodedToken.exp * 1000,
        isLoggedIn: true,
      };
    },
    logout: (state) => {
      return {
        ...state,
        userId: null,
        name: null,
        role: null,
        token: null,
        tokenExp: null,
        isLoggedIn: false,
      };
    },
    updateName: (state, action) => {
      const { userId, sub } = jwt_decode(action.payload);
      return {
        ...state,
        userId: userId,
        name: sub,
      };
    },
  },
});

export const { login, logout, updateName } = authSlice.actions;

export const userId = (state) => state.auth.userId;
export const name = (state) => state.auth.name;
export const token = (state) => state.auth.token;
export const role = (state) => state.auth.role;
export const isLoggedIn = (state) => state.auth.isLoggedIn;

export default authSlice.reducer;