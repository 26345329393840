import { useState, useEffect } from "react";
import useAxiosInstance from "hook/useAxiosInstance";

const useEvent = (floorId) => {
  const axiosInstance = useAxiosInstance();
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if (floorId) await getEventLog(floorId);
    const getEventLog = async (floorId) => {
      setLoading(true);
      const response = await axiosInstance
        .get(
          `${process.env.REACT_APP_API_SMARTLIGHT}/api/eventlog/floor/${floorId}`
        )
        .catch(function(err) {
          setEventList([]);
        });

      if (response?.status === 200 && response?.data?.length > 0)
        setEventList(response.data);
      else setEventList([]);

      setLoading(false);
    };

    if (floorId) getEventLog(floorId);
  }, [floorId, axiosInstance]);

  return { events: eventList, loading };
};

export default useEvent;