import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const token = useSelector((state) => state.auth.token);
  const location = useLocation();

  if (token === null)
    return <Navigate to="/" state={{ from: location }} replace />;

  return children;
};

export default ProtectedRoute;