import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Component/Shared/Layout";

import jwt_decode from "jwt-decode";
import { logout } from "state/auth/authSlice";
import { fetchEntityData } from "state/entity/entityActions";
import routes from "Component/Shared/Routes";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.min.css";
import "react-calendar-timeline/lib/Timeline.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import "./default.css";
import "./App.css";

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (token) {
      dispatch(fetchEntityData());
      let decodedToken = jwt_decode(token);
      if (decodedToken.exp * 1000 < Date.now()) dispatch(logout());
    }
  }, [token, dispatch]);

  return <Layout isLoggedIn={isLoggedIn} routes={routes} />;
}

export default App;
