import React from "react";

import CryingLight from "../../images/cryinglight.png";

const PageNotFound = (props) => {
  return (
    <React.Fragment>
      <div className="center d-flex flex-row">
        <div style={{ marginRight: "50px" }}>
          <img src={CryingLight} style={{ height: "150px", width: "auto" }}/>
        </div>
        <div>
          <h1>Aww.. Don't Cry</h1>
          <h5>It's just a 404 error!</h5>

          <br />
          <p>What you're looking for might have been misplaced or removed.</p>
          <p>
            You should probably return back to the <a href="/map">Homepage</a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageNotFound;