import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Divider } from "rsuite";
import { toast } from "react-toastify";
import { Form, Row, Col } from "react-bootstrap";
import { Button, ButtonGroup, MenuItem, TextField } from "@mui/material"; //Added ButtonGroup here

import useFault from "hook/useFault";
import useAxiosInstance from "hook/useAxiosInstance";

import Header from "../Shared/Header";
import ModalOverlay from "../Shared/ModalOverlay";

import FaultRow from "./FaultRow";
import FaultColumn from "./FaultColumn";
import EnhancedTable from "Component/Shared/EnhancedTable";
import { CLEAR_SELECTED } from "state/notification/notificationSlice";
import { updateNotification } from "state/notification/notificationActions";

import "./Fault.css";

const path = ["Fault Logs"];

const Fault = () => {
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const { selectedFloor } = useSelector((state) => state.entity);
  const selectedNotification = useSelector(
    (state) => state.notification.selectedNotification
  );

  const [eventFault, setEventFault] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [formEventType, setFormEventType] = useState("");
  const [clearFaultDesc, setClearFaultDesc] = useState("");
  const [clearFaultModalOpen, setClearFaultModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedResolvedButton, setSelectedResolvedButton] = useState("");

  // Custom fault hooks that fetch Faults data from database
  const { faultList, isLoading, clearFault } = useFault(selectedFloor);
  const [filteredFaultList, setFilteredFaultList] = useState(faultList);
  const options = [...new Set(faultList.map((fault) => fault.eventType))];

  useEffect(() => {
    setFilteredFaultList(faultList);
  }, [faultList]);

  // Perform data filteration based on filtering criteria onChange
  useEffect(() => {
    const filterCriteria = {
      clearFaultStatus: selectedResolvedButton,
      eventType: formEventType,
    };

    try {
      setFilteredFaultList(
        faultList.filter(
          (fault) =>
            fault.clearFaultStatus.includes(filterCriteria.clearFaultStatus) &&
            fault.eventType.includes(filterCriteria.eventType)
        )
      );
    } catch (error) {
      console.error("Error filtering fault list:", error);
    }
  }, [selectedResolvedButton, formEventType]);

  // if user is navigated to fault page on notification item click
  useEffect(() => {
    if (
      selectedNotification &&
      Object.entries(selectedNotification).length !== 0
    ) {
      axiosInstance
        .get(
          `${process.env.REACT_APP_API_SMARTLIGHT}/api/eventlog/fault/${selectedNotification.faultId}`
        )
        .then((response) => {
          handleTableRowClick(response?.data);
          dispatch(CLEAR_SELECTED());
        })
        .catch((err) => {});
    }
  }, [selectedNotification]);

  const handleTableRowClick = (rowData) => {
    setEventFault(rowData);
    setOpenModal(true);
  };

  const handleOpenClearFaultModalOpen = () => {
    setClearFaultModalOpen(true);
    setOpenModal(false);
  };

  const handleCloseClearFaultModal = () => {
    setClearFaultModalOpen(false);
    setOpenModal(false);
  };

  const handleResolvedButtonClick = (buttonName) => {
    buttonName == selectedResolvedButton
      ? setSelectedResolvedButton("")
      : setSelectedResolvedButton(buttonName);
  };

  const handleEventChange = (e) => {
    e.target.value === ""
      ? setFormEventType("")
      : setFormEventType(e.target.value);
  };

  // function to handle validation and modal for clearing faults
  const clearFaultConfirm = () => {
    if (!clearFaultDesc || clearFaultDesc === "") {
      toast.error(
        "Fault description cannot be empty. Please enter a valid description."
      );
      return;
    }
    setConfirmationModalOpen(true);
  };

  // function to confirm clear fault and trigger API endpoint
  const submitClearFault = async () => {
    const fault = clearFault(eventFault?.faultId, clearFaultDesc);
    if (fault) {
      setEventFault(fault);
      dispatch(updateNotification(eventFault?.faultId));
      handleCloseClearFaultModal();
    }
    setConfirmationModalOpen(false);
  };

  return (
    <React.Fragment>
      <ModalOverlay
        header="Confirm Submit Clear Fault"
        isOpen={confirmationModalOpen}
        closeModal={() => setConfirmationModalOpen(false)}
        displayUpdate={true}
        leftBtnAction={submitClearFault}
        leftBtn="Clear Fault"
        color="red"
      >
        <div>
          <p>
            Are you sure you wish to clear fault for <strong>Block: </strong>{" "}
            <strong style={{ color: "red" }}>{eventFault.blockName}</strong> and{" "}
            <strong>Sensor Id: </strong>{" "}
            <strong style={{ color: "red" }}>{eventFault.sensorId}</strong>?
          </p>
        </div>
      </ModalOverlay>

      <ModalOverlay
        header="Clear Fault"
        isOpen={clearFaultModalOpen}
        closeModal={handleCloseClearFaultModal}
        displayUpdate={true}
        leftBtnAction={clearFaultConfirm}
        leftBtn="Submit"
        color="green"
      >
        <Form id="clearFaultLog" className="form-margin-width">
          <Row>
            <Form.Group as={Col} controlId="clearFaultDescription">
              <Form.Label>
                <strong>Description</strong>
              </Form.Label>
              <Form.Control
                type="textarea"
                name="clearFaultDescription"
                className="form-input-width"
                onChange={(e) => setClearFaultDesc(e.target.value)}
              />
            </Form.Group>
          </Row>
        </Form>
      </ModalOverlay>

      <ModalOverlay
        header="View Fault Details"
        isOpen={openModal}
        size="md"
        closeModal={() => setOpenModal(false)}
        displayUpdate={eventFault.clearFaultLogEntity !== null ? false : true}
        leftBtnAction={handleOpenClearFaultModalOpen}
        leftBtn="Clear Fault"
        color="red"
      >
        <div className="fault-modal_container">
          <Form>
            <Form.Group
              controlId="formName"
              className="d-flex flex-row justify-content-between"
              style={{ paddingTop: "10px" }}
            >
              <Form.Label className="w-50 flex-nowrap">Light Name: </Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={eventFault.lightDisplayName}
              />
            </Form.Group>
            <Form.Group
              controlId="form.Name"
              className="d-flex flex-row justify-content-between"
              style={{ paddingTop: "10px" }}
            >
              <Form.Label className="w-50 flex-nowrap">Event Type: </Form.Label>
              <Form.Control type="text" readOnly value={eventFault.eventType} />
            </Form.Group>
            <Form.Group
              controlId="formName"
              className="d-flex flex-row justify-content-between"
              style={{ paddingTop: "10px" }}
            >
              <Form.Label className="w-50 flex-nowrap">Sensor Id: </Form.Label>
              <Form.Control type="text" readOnly value={eventFault.sensorId} />
            </Form.Group>
            <Form.Group
              controlId="form.Name "
              className="d-flex flex-row justify-content-between"
              style={{ paddingTop: "10px" }}
            >
              <Form.Label className="w-50 flex-nowrap">
                Report Date Time:{" "}
              </Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={eventFault.reportDateTime}
              />
            </Form.Group>
            <Form.Group
              controlId="form.Name"
              className="d-flex flex-row justify-content-between"
              style={{ paddingTop: "10px" }}
            >
              <Form.Label className="w-50 flex-nowrap">
                Sensor Status:{" "}
              </Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={eventFault.sensorStatus}
              />
            </Form.Group>
            <Form.Group
              controlId="form.Name"
              className="d-flex flex-row justify-content-between"
              style={{ paddingTop: "10px" }}
            >
              <Form.Label className="w-50 flex-nowrap">Severity: </Form.Label>
              <Form.Control type="text" readOnly value={eventFault.severity} />
            </Form.Group>
            <Form.Group
              controlId="form.Name"
              className="d-flex flex-row justify-content-between"
              style={{ paddingTop: "10px" }}
            >
              <Form.Label className="w-50 flex-nowrap">
                Description:{" "}
              </Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={eventFault.description}
              />
            </Form.Group>
            <Form.Group
              controlId="form.Name"
              className="d-flex flex-row justify-content-between"
              style={{ paddingTop: "10px" }}
            >
              <Form.Label className="w-50 flex-nowrap">
                Fault Status:{" "}
              </Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={eventFault.faultStatus}
              />
            </Form.Group>
            <Form.Group
              controlId="form.Name"
              className="d-flex flex-row justify-content-between"
              style={{ paddingTop: "10px" }}
            >
              <Form.Label className="w-50 flex-nowrap">
                Current Status of Fault:{" "}
              </Form.Label>
              <Form.Control
                type="text"
                readOnly
                style={{
                  color:
                    eventFault.clearFaultStatus === "Cleared"
                      ? "green"
                      : eventFault.clearFaultStatus === "Pending"
                      ? "red"
                      : "black",
                }}
                value={eventFault.clearFaultStatus}
              />
            </Form.Group>
          </Form>
          {eventFault && eventFault.clearFaultLogEntity && (
            <div>
              <Divider />

              <h6 className="d-flex justify-content-lg-start mb-2">
                Clear Fault Log Details
              </h6>
              <Form>
                <Form.Group
                  controlId="form.Name"
                  className="d-flex flex-row justify-content-between"
                  style={{ paddingTop: "10px" }}
                >
                  <Form.Label className="w-50 flex-nowrap">
                    Description:{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={eventFault.clearFaultLogEntity.description}
                  />
                </Form.Group>
                <Form.Group
                  controlId="form.Name"
                  className="d-flex flex-row justify-content-between"
                  style={{ paddingTop: "10px" }}
                >
                  <Form.Label className="w-50 flex-nowrap">
                    Cleared By:{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={eventFault.clearFaultLogEntity.clearedByUsername}
                  />
                </Form.Group>
                <Form.Group
                  controlId="form.Name"
                  className="d-flex flex-row justify-content-between"
                  style={{ paddingTop: "10px" }}
                >
                  <Form.Label className="w-50 flex-nowrap">
                    Date Cleared:{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={eventFault.clearedTimestamp}
                  />
                </Form.Group>
              </Form>
            </div>
          )}
        </div>
      </ModalOverlay>

      <Header title="Fault Log" path={path} className="dashboard">
        <div className="dashboard-light-status_container-content">
          <EnhancedTable
            data={filteredFaultList}
            rowCells={FaultRow}
            headCells={FaultColumn}
            loading={isLoading}
            onRowClick={(event) => {
              const selectedRow = event.currentTarget;
              const rowData = JSON.parse(
                selectedRow.getAttribute("data-custom")
              );
              setOpenModal(true);
              setEventFault(rowData);
            }}
          >
            <div
              className="d-flex me-4 pt-1 w-100"
              style={{ minWidth: "12em" }}
            >
              <TextField
                select
                variant="outlined"
                style={{ width: "100%" }}
                size="small"
                label="Fault Type"
                value={formEventType}
                onChange={handleEventChange}
              >
                <MenuItem value="">--Select Fault--</MenuItem>
                {options.map((option, index) => (
                  <MenuItem key={index} value={option} className="px-4">
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              className="pt-1 d-flex"
              style={{ width: "100%", marginRight: "12px" }}
            >
              <ButtonGroup style={{ width: "100%" }}>
                <Button
                  size="small"
                  className="w-100 px-4"
                  onClick={() => handleResolvedButtonClick("Pending")}
                  variant={
                    selectedResolvedButton === "Pending"
                      ? "contained"
                      : "outlined"
                  }
                  color={
                    selectedResolvedButton === "Pending" ? "warning" : "info"
                  }
                >
                  Pending
                </Button>
                <Button
                  size="small"
                  className="w-100 px-4"
                  variant={
                    selectedResolvedButton === "Cleared"
                      ? "contained"
                      : "outlined"
                  }
                  color={
                    selectedResolvedButton === "Cleared" ? "success" : "primary"
                  }
                  onClick={() => handleResolvedButtonClick("Cleared")}
                >
                  Cleared
                </Button>
              </ButtonGroup>
            </div>
          </EnhancedTable>
        </div>
      </Header>
    </React.Fragment>
  );
};

export default Fault;
